﻿import moment, { type Moment } from 'moment-timezone'
import ErrorHelper from '@/exports/error'
import type { IDateTimeSettings, IActivityListItem, IScheduledPayment } from '@/models/interfaces'
import {
  RequestStatus,
  ScheduledPaymentStatus,
  TransferStatus,
  ActivityType
} from './MTConstants'

export default {
  checkApiError(result: any, source = '', handleError = true) {
    if (!result?.data?.success) {
      if (handleError) {
        ErrorHelper.handleError(new Error(result?.data?.errorMessage), source)
      }
      return true
    }

    return false
  },
  getDateTimeDisplay(
    utcDate: Date | string | undefined,
    dateTimeSettings: IDateTimeSettings
  ) {
    if (utcDate == undefined) {
      return moment
        .utc()
        .tz(dateTimeSettings.timeZoneNameForMomentJs)
        .format(dateTimeSettings.shortDateFormat.toUpperCase() + ' @ h:mm A')
    }

    return moment
      .utc(utcDate)
      .tz(dateTimeSettings.timeZoneNameForMomentJs)
      .format(dateTimeSettings.shortDateFormat.toUpperCase() + ' @ h:mm A')
  },
  getDateDisplay(
    utcDate: Date | string | undefined,
    dateTimeSettings: IDateTimeSettings,
    convertToTimeZone: boolean
  ) {
    if (convertToTimeZone) {
      return moment(utcDate)
        .tz(dateTimeSettings.timeZoneNameForMomentJs)
        .format(dateTimeSettings.shortDateFormat.toUpperCase())
    }

    return moment
      .utc(utcDate)
      .format(dateTimeSettings.shortDateFormat.toUpperCase())
  },
  getPaymentStatusString(paymentStatus: number) {
    switch (paymentStatus) {
      case TransferStatus.PENDING:
        return 'Pending '
      case TransferStatus.PROCESSED:
        return 'Completed '
      case TransferStatus.FAILED:
        return 'Failed '
      case TransferStatus.CANCELLED:
        return 'Canceled '
      default:
        return ''
    }
  },
  getRequestStatusString(requestStatus: number) {
    switch (requestStatus) {
      case RequestStatus.REQUESTED:
        return 'Requested '
      case RequestStatus.ACCEPTED:
        return 'Accepted '
      case RequestStatus.DECLINED:
        return 'Declined '
      case RequestStatus.CANCELED:
        return 'Canceled '
    }
  },
  getScheduledPaymentStatusString(scheduledPaymentStatus: number) {
    switch (scheduledPaymentStatus) {
      case ScheduledPaymentStatus.ENABLED:
        return 'Scheduled '
      case ScheduledPaymentStatus.DISABLED:
        return 'Paused '
      case ScheduledPaymentStatus.CANCELED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED:
        return 'Canceled '
    }
  },
  getSortByDate(parent: any, child: any, childPrecedence: string) {
    return child
      ? child[childPrecedence]
      : parent.lastUpdatedWhen
      ? parent.lastUpdatedWhen
      : parent.createdWhen
  },
  getRelatedRequestActivityTitle(
    requestedByUser: boolean,
    requestStatus: number,
    coParentFullName: string
  ) {
    return requestStatus == RequestStatus.DECLINED
      ? requestedByUser
        ? 'Declined by ' + coParentFullName
        : 'Declined by you'
      : requestStatus == RequestStatus.CANCELED
      ? requestedByUser
        ? 'Canceled by you'
        : 'Canceled by ' + coParentFullName
      : ''
  },
  getRelatedScheduledPaymentActivityTitle(
    scheduledByUser: boolean,
    status: number,
    repeats: boolean,
    coParentFullName: string
  ) {
    switch (status) {
      case ScheduledPaymentStatus.CANCELED:
        return repeats
          ? scheduledByUser
            ? 'Canceled by you'
            : 'Canceled by ' + coParentFullName
          : scheduledByUser
          ? 'Canceled by you'
          : 'Canceled by ' + coParentFullName
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_FAILED_PAYMENT:
        return 'System canceled'
      default:
        return ''
    }
  },
  getRelatedScheduledPaymentActivityDetails(
    scheduledByUser: boolean,
    status: number
  ) {
    switch (status) {
      case ScheduledPaymentStatus.ENABLED:
      case ScheduledPaymentStatus.CANCELED:
        return ''
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER:
        return (
          'Canceled due to an issue with ' +
          (scheduledByUser ? 'your' : "your co-parent's") +
          ' account'
        )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED:
        return (
          'Canceled due to an issue with ' +
          (scheduledByUser ? "your co-parent's" : 'your') +
          ' account'
        )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE:
        return (
          'Canceled due to an issue with ' +
          (scheduledByUser ? 'your' : "your co-parent's") +
          ' bank account'
        )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED:
        return (
          'Canceled due to an issue with ' +
          (scheduledByUser ? "your co-parent's" : 'your') +
          ' bank account'
        )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_FAILED_PAYMENT:
        return 'Canceled due to a previous failed payment'
      default:
        return ''
    }
  },
  getPaymentTitle(
    paidByUser: boolean,
    paymentStatus: number,
    coParentFullName: string
  ) {
    if (paymentStatus == TransferStatus.CANCELLED) {
      return 'Payment Canceled'
    }
    return (
      this.getPaymentStatusString(paymentStatus) +
      (paidByUser ? 'to ' : 'from ') +
      coParentFullName
    )
  },
  getScheduledPaymentTitle(
    paidByUser: boolean,
    repeat: boolean,
    coParentFullName: string
  ) {
    return (
      (repeat ? 'Recurring monthly ' : 'Scheduled ') +
      (paidByUser ? 'to ' : 'from ') +
      coParentFullName
    )
  },
  getScheduledPaymentSubTitle(
    repeat: boolean,
    utcDate: Date | string,
    dateTimeSettings: IDateTimeSettings
  ) {
    const scheduledWhen = moment.utc(utcDate)
    const scheduledWhenDisplay = scheduledWhen.format(
      dateTimeSettings.shortDateFormat.toUpperCase()
    )
    const scheduledWhenDayOfMonth = scheduledWhen.format('Do')

    return repeat
      ? 'Repeats monthly on the ' +
          scheduledWhenDayOfMonth +
          ', starting ' +
          scheduledWhenDisplay
      : 'Scheduled for ' + scheduledWhenDisplay
  },
  getBasicRequest(
    request: any,
    requestedByUser: boolean,
    userInitials: string,
    coParentInitials: string,
    coParentFullName: string,
    dateTimeSettings: IDateTimeSettings
  ) {
    const title = requestedByUser
      ? 'Request to ' + coParentFullName
      : 'Request from ' + coParentFullName

    const SendingInitials = requestedByUser ? userInitials : coParentInitials

    return {
      title: title,
      relatedActivityTitle: this.getRelatedRequestActivityTitle(
        requestedByUser,
        request?.status || 0,
        coParentFullName
      ),
      amount: request.amount,
      description: request.description,
      descriptionInitials: SendingInitials,
      viewedWhen: request.viewedWhen ?? null,
      createdWhen: request.createdWhen,
      sending: requestedByUser,
      type: ActivityType.REQUEST
    }
  },
  formatMoney(amount: string | number | undefined) {
    if (!amount) return ''
    if (typeof amount == 'string') {
      return Number.parseFloat(amount).toFixed(2)
    } else {
      return amount
    }
  },
  // to cast payments, requests, and scheduled payments to activity list items with setting the type
  toActivityItem(activity: any, type: number) {
    // todo lulu add parameter checks
    const activityItem = activity as IActivityListItem
    activityItem.type = type
    return activityItem
  },
  getHasUpcomingPayment(
    scheduledPayment: IActivityListItem | null | undefined
  ) {
    if (!scheduledPayment) return false

    if (scheduledPayment.status != ScheduledPaymentStatus.ENABLED) {
      return false
    }

    const now = moment()
    let scheduledWithin48Hours =
      moment(scheduledPayment.scheduledWhen) > now &&
      moment(scheduledPayment.scheduledWhen) <= moment(now).add(2, 'days')

    if (scheduledWithin48Hours) return true

    if (
      scheduledPayment.repeat &&
      (!scheduledPayment.lastProcessedWhen ||
        moment(scheduledPayment.lastProcessedWhen).date() != now.date())
    ) {
      const daysInThisMonth = moment().daysInMonth()
      const nextScheduledDayForThisMonth =
        moment(scheduledPayment.scheduledWhen).day() > daysInThisMonth
          ? daysInThisMonth
          : moment(scheduledPayment.scheduledWhen).day()
      const nextScheduledDay = moment(now).day(nextScheduledDayForThisMonth)

      scheduledWithin48Hours =
        nextScheduledDay >= moment(scheduledPayment.scheduledWhen) &&
        nextScheduledDay > now &&
        nextScheduledDay <= moment(now).add(2, 'days')

      if (scheduledWithin48Hours) return true
    }

    return false
  }
}
