﻿import { defineStore } from 'pinia'
import ErrorHelper from '@/exports/error'
import type { IJournalState } from '@/models/stores/journal'
import helpers from '../exports/helper'
import { useCommonStore } from './CommonStore'
import httpClient from '@/httpClient'
import {
  JournalEntry,
  type IJournalAttachment,
  type IJournalEntry
} from '../models/models'
import type { IPagedSearchPayload } from '../models/interfaces'

const previewLength = 250

const initialState = (): IJournalState => ({
  entries: [
    new JournalEntry({ itemID: 0, message: '&nbsp;' }),
    new JournalEntry({ itemID: 0, message: '&nbsp;' }),
    new JournalEntry({ itemID: 0, message: '&nbsp;' })
  ],
  count: 0,
  selectedEntry: new JournalEntry({ itemID: 0, message: '' }),
  selectedEntryAttachments: [],
  loadMoreItems: false,
  callConfirmDelete: false,
  searchValue: '',
  currentPage: 1,
  entryPosition: 0
})

const paths: string[] = []

export const useJournalStore = defineStore('journal', {
  state: initialState,
  getters: {
    isEditing: (state) => {
      return state.selectedEntry.itemID && state.selectedEntry.itemID > 0
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof IJournalState)[])
      )
    },
    async deleteAttachment(id: number) {
      try {
        const url = `/web/api/Journal/DeleteJournalAttachment?id=${id}`

        const response = await httpClient.get(encodeURI(url))

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return response.data.success
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteAttachment')
      }
    },
    async deleteJournalEntry(id: number) {
      try {
        const url = `/web/api/Journal/DeleteJournalEntry?id=${id}`

        const response = await httpClient.get(encodeURI(url), {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return response.data.success
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteAttachment')
      }
    },
    async getJournalAttachment(id: number) {
      try {
        const url = `/web/api/Journal/GetJournalEntry?id=${id}`

        const response = await httpClient.get(encodeURI(url), {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return response.data.success
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteAttachment')
      }
    },
    async fetchJournalEntryAttachments(id: number) {
      try {
        const url = `/web/api/Journal/GetJouralAttachments?id=${id}`

        const response = await httpClient.get(encodeURI(url), {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.setSelectedEntryAttachments(response.data.value)
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchJournalEntryAttachments')
      }
    },
    async fetchJournalEntries(payload: IPagedSearchPayload) {
      try {
        const url = `/web/api/Journal/GetJournalEntries?page=${payload.page}&searchTerm=${encodeURIComponent(payload.searchTerm)}&previewLength=${previewLength}`

        const response = await httpClient.get(encodeURI(url), {
          headers: {
            'api-version': '3'
          }
        })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
        const _entries =
          payload.page == 1
            ? response.data.value.entries
            : [...this.entries, ...response.data.value.entries]

        this.entries = _entries
        this.count = response.data.value.count
        this.loadMoreItems = _entries.length < this.count
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchJournalEntries')
      }
    },
    async saveEntry(payload: FormData): Promise<boolean> {
      const abortController = new AbortController()
      const url = this.isEditing
        ? '/web/api/Journal/EditJournalEntry'
        : '/web/api/Journal/CreateJournalEntry'
      try {
        const common = useCommonStore()
        common.abortController = abortController

        const response = await httpClient.post(url, payload, {
          headers: {
            'api-version': '2',
            'Content-Type': 'multipart/form-data'
          },
          signal: abortController.signal
        })

        if (!response.data.success) {
          ErrorHelper.handleError(
            response.data,
            'createJournalEntry',
            response.data.errorCode > 0,
            response.data.errorMessage
          )
        }
        return response.data.success as boolean
      } catch (e) {
        if (e == 'Canceled') return false
        ErrorHelper.handleError(e, 'saveEntry')
        return false
      }
    },
    async getJournalEntry(id: number): Promise<void> {
      const url = `/web/api/Journal/GetJournalEntry?id=${id}`
      try {
        const response = await httpClient.get(encodeURI(url))
        if (!response.data.success) throw new Error(response.data.errorMessage)
        this.setSelectedEntry(response.data.value)
      } catch (e) {
        ErrorHelper.handleError(e, 'getJournalEntry')
      }
    },
    setSelectedEntry(entry: IJournalEntry) {
      this.selectedEntry = entry
    },
    setSelectedEntryAttachments(attachments: IJournalAttachment[]) {
      this.selectedEntryAttachments = attachments
    },
    setCallConfirmDelete(val: boolean) {
      this.callConfirmDelete = val
    },
    setSearchValue(searchVal: string) {
      this.searchValue = searchVal
    },
    setEntryPageAndPositon(page: number, position: number) {
      this.currentPage = page
      this.entryPosition = position
    }
  }
})
