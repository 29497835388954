export default Object.freeze({
  reportPageState: {
    list: 0,
    calculating: 1,
    activeCart: 2,
    shippingForm: 3,
    creditCardForm: 4,
    paymentSent: 5,
    paymentRecieved: 6,
    noRecordsSelected: 7
  },
  reportType: {
    Standard: 1,
    Calendar: 2,
    Journal: 3,
    Call: 4,
    InfoLibrary: 6,
    Payments: 7,
    IPReprt: 8
  },
  recordOrderStatus: {
    Pending: 1,
    Paid: 2,
    Shipped: 3
  },
  pdfAccessType: {
    NONE: 1,
    ONEDAY_BY_PRINT: 2,
    ONEDAY_BY_PURCHASE: 3,
    UNLIMITED: 4,
    UNLIMITED_PREMIUM_MEMBERSHIP: 5
  },
  viewTypes: {
    month: 0,
    week: 1,
    day: 2,
    graveyard: 3,
    form: 4
  },
  viewTypeNames: {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    graveyard: 'Deleted'
  },
  duplicateSubscriptionError: {
    None: 0,
    BraintreeBraintree: 1,
    BraintreeApple: 2,
    AppleApple: 3
  },
  subscriptionTypes: {
    None: 0,
    Braintree: 1,
    Apple: 2,
    AppleRenewing: 3,
    Stripe: 4,
    PlayStore: 5,
    Promotional: 6
  },
  subscriptionStatus: {
    None: 0,
    Active: 1,
    Canceled: 2
  },
  subscriptionTiers: {
    Free: 0,
    Standard: 1,
    Premium: 2
  },
  braintreePlanId: {
    PremiumYear: 'TP-Premium-Year',
    PremiumYearAndroid: 'TP-Premium-Year-Android'
  },
  calendarViewTypes: {
    month: 0,
    week: 1,
    day: 2,
    graveyard: 3
  },
  infoLibraryFieldTypes: {
    NAME: 0,
    PHONE: 1,
    EMAIL: 2,
    ADDRESS: 3,
    CITY: 4,
    STATE: 5,
    ZIP: 6
  },
  pageType: {
    list: 0,
    form: 1
  },
  adFormat: {
    auto: 'auto',
    rectangle: 'rectangle',
    vertical: 'vertical',
    horizontal: 'horizontal'
  },
  adTest: {
    on: 'on',
    off: 'off'
  },
  UPGRADE_BANNER_COOLDOWN_IN_DAYS: 30,
  thumbableTypes: [
    'jpg',
    'jpeg',
    'heic',
    'gif',
    'png',
    'tif',
    'tiff',
    'webp',
    'svg',
    'bmp'
  ],
  displayableThumbableTypes: ['jpg', 'jpeg', 'gif', 'png', 'webp', 'bmp'],
  vaultThumbnailSizes: [100, 300, 600],
  vaultUploadStatus: {
    queued: 'queued',
    converting: 'converting',
    uploading: 'uploading',
    paused: 'paused',
    completed: 'completed',
    failed: 'failed'
  },
  VAULT_FILETYPES: {
    folder: 'folder',
    video: 'queuvideoed',
    audio: 'audio',
    microsoft: 'microsoft',
    pdf: 'pdf',
    image: 'image',
    unknown: 'unknown'
  },
  PREVIEW_LIMIT_IN_BYTES: 5242880,
  vaultDownloadStatus: {
    downloading: 'downloading',
    stopped: 'stopped',
    completed: 'completed',
    failed: 'failed'
  },
  VAULT_RECENT_FOLDER_CUTOFF_IN_DAYS: 60,
  CALL_TYPE_ENUM: {
    audio: 0,
    video: 1,
    voicemail: 2
  },
  VIDEO_CALL_STATUS_ENUM: {
    WaitingRoom: 0,
    WaitingRoomAbandoned: 1,
    WaitingRoomTimedOut: 2,
    CoparentAccepted: 3,
    CoparentRejected: 4,
    MidCall: 5,
    CreatorDisconnected: 6,
    CoparentDisconnected: 7,
    CreatorCompleted: 8,
    CoparentCompleted: 9,
    BalanceEmptyCompleted: 10,
    Failed: 11,
    WebhookCompleted: 12,
    Reconnecting: 13,
    Connecting: 14,
    PreCheck: 15
  },
  VIDEO_CALL_ACTION_ENUM: {
    VideoCallCreated: 0,
    VideoCallAbandoned: 1,
    WaitingRoomTimeout: 2,
    VideoCallAccepted: 3,
    VideoCallJoined: 4,
    GatewayRegisterComplete: 5,
    FMAbandoned: 6,
    FMTimeout: 7,
    FMError: 8,
    UpstreamStarted: 9,
    DownstreamStarted: 10,
    AudioMuted: 11,
    AudioUnmuted: 12,
    VideoDisabled: 13,
    VideoEnabled: 14,
    FlipToFrontCamera: 15,
    FlipToBackCamera: 16,
    CompleteCall: 17,
    LeavingCall: 18,
    Disconnected: 19,
    VoicemailCreated: 20,
    CallStatusUpdated: 21,
    WebhookEvent: 22,
    DownstreamFailed: 23,
    UpstreamFailed: 24,
    CoparentReconnected: 25,
    Reconnecting: 26,
    LeavingFromDisconnect: 27,
    FunctionSetStartTime: 28,
    FunctionSetEndTime: 29,
    FunctionSetStatus: 30,
    FunctionSetDuration: 31
  },
  MINUTES_PER_DAY: 1440,
  CAN_CALL_STATUS: {
    videoAllowed: 'videoAllowed',
    phoneAllowed: 'phoneAllowed',
    notPremium: 'notPremium',
    noMinutes: 'noMinutes',
    coParentPhoneDisabled: 'coParentPhoneDisabled',
    coParentVideoDisabled: 'coParentVideoDisabled',
    coParentTierFree: 'coParentTierFree', 
    phoneCallingDisabled: 'phoneCallingDisabled',
    videoCallingDisabled: 'videoCallingDisabled',
  },
  swatchColorValues: {
    blue: 'cerulean',
    navy: 'oceanblue',
    green: 'grassgreen',
    yellow: 'honey',
    orange: 'tangerine',
    pink: 'rose',
    purple: 'violet',
    gray: 'slategray'
  },
  callNotifStatus: {
    ignored: 'ignored',
    received: 'received',
    accepted: 'accepted',
    declined: 'declined'
  }
})
