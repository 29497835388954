<template>
  <div class="download-container">
    <div>
      <header
        class="flex align-middle align-justify bg-gray-2 p-1"
        :class="{ collapsed: !state.displayDownloadList }"
      >
        <h1 class="font-size-1">{{ getDownloadHeaderText }}</h1>
        <menu class="flex align-middle gap-1 fit-content">
          <button
            type="button"
            class="btn tertiary icon-size-2"
            @click="toggleDownloadList"
          >
            <font-awesome-icon
              icon="fa-light fa-chevron-down"
              class="icon-size-2"
              :class="{ expand: state.displayDownloadList }"
            />
          </button>
          <button
            type="button"
            class="btn tertiary icon-size-2"
            @click="cancelAllDownloads"
          >
            <font-awesome-icon icon="fa-light fa-xmark" class="icon-size-2" />
          </button>
        </menu>
      </header>
      <ul v-show="state.displayDownloadList" class="download-list">
        <li v-for="download in downloads" :key="download.id">
          <DownloadListItem :download="download" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, reactive } from 'vue'
import DownloadListItem from './DownloadListItem.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { useI18n } from 'vue-i18n'
import { DownloadStatus } from '@/models/enums'
const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { downloads } = storeToRefs(commonStore)
const { cancelAllDownloads } = commonStore

const state = reactive({
  displayDownloadList: true
})

function toggleDownloadList() {
  state.displayDownloadList = !state.displayDownloadList
}

const allDownloadsCompleted = computed(
  () => !downloads.value.some((d) => d.status != DownloadStatus.Completed)
)

const getDownloadHeaderText = computed(() => {
  if (downloads.value.length > 1) {
    return allDownloadsCompleted.value
      ? t('downloads.list.headerMultipleCompleted', {
          count: downloads.value.length
        })
      : t('downloads.list.headerMultiple', { count: downloads.value.length })
  }

  return allDownloadsCompleted.value
    ? t('downloads.list.headerSingleCompleted')
    : t('downloads.list.headerSingle')
})
</script>
<style scoped lang="scss">
.download-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 25rem;
  height: fit-content;
  border: 2px solid var(--surface-3);
  border-radius: var(--radius-3);
  background-color: var(--surface-1);
  flex: 0 1 auto;

  div:first-child {
    width: 100%;
  }

  header {
    border-top-left-radius: var(--radius-3);
    border-top-right-radius: var(--radius-3);
  }
}

.download-list {
  max-height: 50vh;
  overflow: auto;
  margin-block-start: var(--size-000);

  li {
    padding-block: var(--size-0);
    padding-inline: var(--size-1);
    border-radius: var(--radius-2);
    transition: background-color 0.25s ease-in-out;

    &:hover {
      cursor: default;
      background-color: var(--surface-2);

      &:last-child {
        border-bottom-left-radius: var(--radius-3);
        border-bottom-right-radius: var(--radius-3);
      }
    }
  }
}

.collapsed {
  border-radius: var(--radius-3);
}
.expand {
  transform: rotate(180deg);
}
</style>
