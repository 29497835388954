﻿import { defineStore } from 'pinia'
import ErrorHelper from '@/exports/error'
import helpers from '@/exports/helper'
import calHelpers from '../pages/calendar/calendarHelpers'
import { useCommonStore } from './CommonStore'
import httpClient from '@/httpClient'
import type {
  ICalDay,
  ICalendarEntry,
  IFetchCalendarByDatesPayload,
  IFetchDeletedListPayload,
  IHandleEntriesPayloads
} from '@/models/interfaces'
import { CalendarEntry } from '@/models/interfaces'
import type { ICalendarState } from '@/models/stores/calendar'
import moment, { type Moment } from 'moment-timezone'

import type {
  ICancelOccurrenceWebRequest,
  ISetEventColorRequest
} from '@/models/models'
import constants from '@/exports/constants'

const initialState = (): ICalendarState => ({
  currentDay: moment() as Moment,
  viewType: constants.viewTypes.month,
  viewTypeName: constants.viewTypeNames.month,
  unseenEvents: [] as ICalendarEntry[],
  calendarDays: [] as ICalDay[],
  dayEvents: [] as ICalendarEntry[],
  weekIndex: 0,
  entry: {
    itemID: 0,
    subject: '',
    notes: '',
    startTime: moment(),
    endTime: moment(),
    isRecurring: false,
    isAllDay: false,
    isNew: false,
    color: 'default-color',
    rRule: '',
    dtStart: null,
    until: null,
    ownerID: 0,
    parentItemID: null,
    lastEdited: null,
    lastViewed: null,
    createdWhen: null,
    isCanceled: false
  } as ICalendarEntry,
  placeholderEntries: [
    new CalendarEntry({
      subject: 'placeholder',
      isAllDay: true,
      color: 'no-color'
    }),
    new CalendarEntry({
      subject: 'placeholder',
      isAllDay: true,
      color: 'no-color'
    }),
    new CalendarEntry({
      subject: 'placeholder',
      isAllDay: true,
      color: 'no-color'
    })
  ] as ICalendarEntry[],
  numberOfWeeks: 0,
  showEventDetails: false,
  showEditDialog: false,
  showCancelDialog: false,
  showDeleteConfirmation: false,
  showColorPicker: false,
  occurrenceDay: null,
  startTimeInUtc: moment().startOf('month').day('Sunday'),
  endTimeInUtc: moment().endOf('month').day('Saturday'),
  forceRefresh: false,
  selectedColorFromPicker: 'default-color',
  isFetchingAPI: false,
  enableSkeleton: true,
  newDeletedEvents: false
})

const paths: string[] = []

export const useCalendarStore = defineStore('calendar', {
  state: initialState,
  getters: {
    getCurrentDayAsFormattedString(state) {
      return state.currentDay?.format('YYYY-MM-DD')
    },
    getRecurringEventText(state) {
      let eventText = 'This event'
      if (state.entry.isAllDay) {
        eventText = 'This all day event'
      }
      if (calHelpers.isEventMultiDay(state.entry as ICalendarEntry)) {
        eventText = 'This multi-day event'
      }
      eventText += ` will repeat ${calHelpers.getRRuleAsText(
        state.entry.rRule,
        this.entry.startTime as Moment
      )}`
      return eventText
    },
    getDaysForMobileMonthView(state): ICalDay[] {
      const _days: ICalDay[] = state.calendarDays.filter((day) =>
        calHelpers.isDayInMonth(day.day, state.currentDay)
      )
      return _days
    },
    isMultiDay: () => {
      return (entry: ICalendarEntry): boolean => calHelpers.isMultiDay(entry)
    },
    getNumberOfUnseenEvents(state) {
      return state.unseenEvents.length
    },
    monthHasEvent(): boolean {
      // dont check events if not pulled in yet
      if (this.calendarDays.length == 0) {
        return true
      }
      const _days = this.getDaysForMobileMonthView

      return _days.findIndex((d) => d.events.length > 0) >= 0
    },
    isDayView(state): boolean {
      return state.viewType == constants.viewTypes.day
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof ICalendarState)[])
      )
    },
    async setLoadingOnCommon(payload: boolean): Promise<void> {
      const common = useCommonStore()
      common.loading = payload
    },
    async getUserTimezone(): Promise<string> {
      const commonStore = useCommonStore()
      return (
        commonStore.getDateTimeSettings.timeZoneNameForMomentJs ||
        moment.tz.guess()
      )
    },
    async saveEntry(
      entry: ICalendarEntry,
      editingSeries: boolean
    ): Promise<string> {
      const _entry = {
        ...entry,
        startTime: entry.startTime.clone().toDate(),
        endTime: entry.endTime.clone().toDate(),
        dtStart: entry.startTime.toDate(),
        until: entry.until
          ? entry.until.clone().toDate()
          : moment().add(100, 'y').toDate(),
        createdInTimeZone: await this.getUserTimezone(),
        occurenceDateTime:
          entry.isRecurring && !editingSeries
            ? this.occurrenceDay?.toDate()
            : moment().toDate()
      }

      let url = '/web/api/Calendar/CreateEntry'

      if (entry.itemID == 0) {
        url = '/web/api/Calendar/CreateEntry'
      } else if (entry.itemID != 0 && !_entry.isRecurring) {
        url = '/web/api/Calendar/EditEntry'
      } else if (_entry.isRecurring && editingSeries) {
        url = '/web/api/Calendar/EditSeries'
      } else if (_entry.isRecurring && !editingSeries) {
        url = '/web/api/Calendar/EditOccurrence'
      }

      try {
        const response = await httpClient.post(url, _entry)

        if (response.data.success) {
          this.forceRefresh = true
          return 'success'
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'saveEntry')
        return 'error'
      }
    },
    async updateEventColor(payload: ISetEventColorRequest): Promise<string> {
      const url = '/web/api/Calendar/UpdateEventColor'

      try {
        const response = await httpClient.post(url, payload)

        if (response.data.success) {
          return response.data.value.color
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'updateEventColor')
        return 'error'
      }
    },
    async fetchCalendarByDates(
      payload: IFetchCalendarByDatesPayload
    ): Promise<void> {
      try {
        if (this.enableSkeleton) {
          this.isFetchingAPI = true
        } else {
          this.isFetchingAPI = false
        }

        const url = `/web/api/Calendar/GetEntries?StartTimeInUtc=${payload.startDate}&EndTimeInUtc=${payload.endDate}`

        const response = await httpClient.get(encodeURI(url))

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        switch (payload.viewType) {
          case constants.viewTypes.month:
            await this.handleEntries({
              startDate: payload.startDate,
              endDate: payload.endDate,
              events: response.data.value.events
            })
            break
          case constants.viewTypes.week:
            await this.handleEntriesForWeek({
              startDate: payload.startDate,
              endDate: payload.endDate,
              events: response.data.value.events
            })
            break
          case constants.viewTypes.day:
            await this.handleEntriesForDay({
              day: payload.startDate,
              events: response.data.value.events
            })
            break
        }
        if (this.viewType == constants.viewTypes.week) {
          this.weekIndex = this.getWeekIndexOfCurrentDay()
        }
        if (this.viewType == constants.viewTypes.day) {
          const calDate = this.calendarDays.find((calDay) => {
            return moment(calDay.day, 'YYYY-MM-DD').isSame(
              this.currentDay as Moment,
              'day'
            )
          })
          this.dayEvents = calDate?.events || []
        }
        this.startTimeInUtc = moment(payload.startDate)
        this.endTimeInUtc = moment(payload.endDate)
        this.forceRefresh = false
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCalendarByDates')
      } finally {
        this.isFetchingAPI = false
        this.setLoadingOnCommon(false)
      }
    },
    getWeekIndexOfCurrentDay(): number {
      const dayIndex = this.calendarDays.findIndex((calDay) =>
        moment(calDay.day, 'YYYY-MM-DD').isSame(
          this.currentDay as Moment,
          'day'
        )
      )
      return dayIndex == -1 ? -1 : Math.round(dayIndex / 7)
    },
    async getDayOrFetch(payload: IFetchCalendarByDatesPayload): Promise<void> {
      // check with state.calendarDays to get day events.
      // if the date does not exist fetch from API
      const _startDate = moment(payload.startDate, 'YYYY-MM-DD HH:mm:ss')
      const calDate = this.calendarDays.find((calDay) => {
        return moment(calDay.day, 'YYYY-MM-DD').isSame(_startDate, 'day')
      })

      if (calDate && !this.forceRefresh) {
        this.dayEvents = calDate.events
        this.setLoadingOnCommon(false)
      } else {
        // get the whole month worth of events
        const startDateUtc = _startDate.clone().startOf('month').day('Sunday')
        const endDateUtc = _startDate.clone().endOf('month').day('Saturday')
        this.fetchCalendarByDates({
          startDate: startDateUtc.utc().format('YYYY-MM-DD HH:mm:ss'),
          endDate: endDateUtc.utc().format('YYYY-MM-DD HH:mm:ss'),
          viewType: constants.viewTypes.month
        })
      }
    },
    async getWeekOrFetch(payload: IFetchCalendarByDatesPayload): Promise<void> {
      if (this.weekIndex != this.getWeekIndexOfCurrentDay()) {
        // if we have the week already, don't fetch it again
        this.weekIndex = this.getWeekIndexOfCurrentDay()
      }
      if (
        this.weekIndex == -1 ||
        this.weekIndex > this.numberOfWeeks - 1 ||
        this.forceRefresh
      ) {
        // if we don't have the week, fetch it
        this.fetchCalendarByDates({
          startDate: payload.startDate,
          endDate: payload.endDate,
          viewType: constants.viewTypes.month
        })
      } else {
        this.setLoadingOnCommon(false)
      }
    },
    async fetchDeletedList(
      payload: IFetchDeletedListPayload
    ): Promise<ICalendarEntry[]> {
      try {
        this.isFetchingAPI = true
        const url = '/web/api/Calendar/GetDeletedList'
        const params = {
          PageNumber: payload.pageNumber,
          PageSize: payload.pageSize
        }

        const response = await httpClient.get(url, { params: params })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        const events = await this.handleDeletedEntries({
          events: response.data.value.events
        })

        return events
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchDeletedList')
      } finally {
        this.setLoadingOnCommon(false)
        this.isFetchingAPI = false
      }

      return []
    },
    async handleDeletedEntries(
      payload: IHandleEntriesPayloads
    ): Promise<ICalendarEntry[]> {
      // find events for the current day
      const timezone = await this.getUserTimezone()
      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      return _events
      //commit('setDeletedEvents', _events)
    },
    async handleEntriesForWeek(payload: IHandleEntriesPayloads): Promise<void> {
      // find events for the current day
      const timezone = await this.getUserTimezone()
      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      this.dayEvents = calHelpers.getEventsForDate(
        this.currentDay.format('YYYY-MM-DD HH:mm:ss'),
        _events
      )
      await this.handleEntries(payload)
    },
    async handleEntriesForDay(payload: IHandleEntriesPayloads): Promise<void> {
      // convert entries to user's timezone
      // and create new events for multiday events
      const timezone = await this.getUserTimezone()
      // do a quick filter to make sure there are not any rouge recurring events
      const ev = payload.events.filter((ev) =>
        calHelpers.isEventInDay(
          ev,
          payload.day || moment().format('YYYY-MM-DD HH:mm:ss'),
          timezone
        )
      )

      const _events = calHelpers.convertEventDatesToUsersLocalTime(ev, timezone)
      this.dayEvents = _events
    },
    async handleEntries(payload: IHandleEntriesPayloads): Promise<void> {
      // convert entries to user's timezone
      // and create new events for multiday events
      const timezone = await this.getUserTimezone()
      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      // create calendar days with events
      const calStartDate = moment
        .utc(payload.startDate, 'YYYY-MM-DD HH:mm:ss')
        .tz(timezone)
      const calEndDate = moment
        .utc(payload.endDate, 'YYYY-MM-DD HH:mm:ss')
        .tz(timezone)

      const tempDay = calStartDate.clone()
      const calendarDays = []
      while (tempDay.isSameOrBefore(calEndDate, 'd')) {
        const calDay = {
          day: tempDay.format('YYYY-MM-DD'),
          events: calHelpers.getEventsForDate(
            tempDay.format('YYYY-MM-DD'),
            _events
          )
        }
        calendarDays.push(calDay)
        tempDay.add(1, 'd')
      }
      this.calendarDays = calendarDays

      const numWeeks = (calEndDate.diff(calStartDate, 'days') + 1) / 7
      this.numberOfWeeks = numWeeks
    },
    async fetchNewEventsData(): Promise<void> {
      try {
        // if (this.enableSkeleton) {
        //   this.isFetchingAPI = true
        // } else {
        //   this.isFetchingAPI = false
        // }
        this.isFetchingAPI = true
        const url = '/web/api/Calendar/GetNewList'
        const response = await httpClient.get(url)
        if (response?.status !== 200)
          throw new Error(response?.data?.errorMessage)

        this.handleNewEntries({
          events: response.data.value.events
        })
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchNewEventsData')
      } finally {
        this.isFetchingAPI = false
        this.setLoadingOnCommon(false)
      }
    },
    async handleNewEntries(payload: IHandleEntriesPayloads): Promise<void> {
      // convert entries to user's timezone
      // and create new events for multiday events
      const timezone = await this.getUserTimezone()

      const _events = calHelpers.convertEventDatesToUsersLocalTime(
        payload.events,
        timezone
      )
      this.unseenEvents = _events
    },
    async fetchEntry(itemId: number): Promise<ICalendarEntry | undefined> {
      try {
        const timezone = await this.getUserTimezone()
        const url = '/web/api/Calendar/GetEntry?itemId=' + itemId
        const response = await httpClient.get(url)
        if (response.status !== 200) throw new Error(response.data.errorMessage)
        const _entry: ICalendarEntry = response.data.value.event

        const entry: ICalendarEntry = {
          ..._entry,
          startTime: _entry.isAllDay
            ? moment.utc(_entry.startTime)
            : moment.utc(_entry.startTime).tz(timezone),
          endTime: _entry.isAllDay
            ? moment.utc(_entry.endTime)
            : moment.utc(_entry.endTime).tz(timezone),
          dtStart: _entry.dtStart
            ? moment.utc(_entry.dtStart).tz(timezone)
            : null,
          until: _entry.until ? moment.utc(_entry.until).tz(timezone) : null,
          lastEdited: _entry.lastEdited
            ? moment.utc(_entry.lastEdited).tz(timezone)
            : null,
          lastViewed: _entry.lastViewed
            ? moment.utc(_entry.lastViewed).tz(timezone)
            : null,
          createdWhen: _entry.createdWhen
            ? moment.utc(_entry.createdWhen).tz(timezone)
            : null
        }

        return entry
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchEntry')
      } finally {
        this.setLoadingOnCommon(false)
      }
    },
    async getNewEntry(itemId: number): Promise<ICalendarEntry | undefined> {
      try {
        const url = '/web/api/Calendar/GetEntry?itemId=' + itemId
        const response = await httpClient.get(url)
        if (response.status !== 200) throw new Error(response.data.errorMessage)
        const _entry: ICalendarEntry = response.data.value.event

        const timezone = await this.getUserTimezone()
        const entry: ICalendarEntry = {
          ..._entry,
          startTime: _entry.isAllDay
            ? moment.utc(_entry.startTime)
            : moment.utc(_entry.startTime).tz(timezone),
          endTime: _entry.isAllDay
            ? moment.utc(_entry.endTime)
            : moment.utc(_entry.endTime).tz(timezone),
          dtStart: _entry.dtStart
            ? moment.utc(_entry.dtStart).tz(timezone)
            : null,
          until: _entry.until ? moment.utc(_entry.until).tz(timezone) : null,
          lastEdited: _entry.lastEdited
            ? moment.utc(_entry.lastEdited).tz(timezone)
            : null,
          lastViewed: _entry.lastViewed
            ? moment.utc(_entry.lastViewed).tz(timezone)
            : null,
          createdWhen: _entry.createdWhen
            ? moment.utc(_entry.createdWhen).tz(timezone)
            : null,
          isNew: true
        }
        return entry
      } catch (e) {
        ErrorHelper.handleError(e, 'getNewEntry')
      }
    },
    async deleteEntry(eventId: number): Promise<string> {
      //this.enableSkeleton = false;
      const url = '/web/api/Calendar/DeleteEntry?itemId=' + eventId

      try {
        const response = await httpClient.get(url)

        if (response.data.success) {
          return 'success'
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteEntry')
        this.setLoadingOnCommon(false)
        return 'error'
      }
    },
    async cancelOccurrence(
      payload: ICancelOccurrenceWebRequest
    ): Promise<string> {
      const url = '/web/api/Calendar/CancelOccurrence'

      try {
        const response = await httpClient.post(url, payload)

        if (response.data.success) {
          return 'success'
        } else {
          throw new Error(response.data.errorMessage)
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'cancelOccurrence')
        this.setLoadingOnCommon(false)
        return 'error'
      }
    },
    removeFromUnseenEvents(eventId: number): void {
      const _ue: ICalendarEntry[] = Object.assign(this.unseenEvents)
      //find event from id
      const _eventIndex = _ue.findIndex((e) => e.itemID == eventId)
      if (_eventIndex > -1) {
        _ue.splice(_eventIndex, 1)
        this.unseenEvents = _ue
      }
    },
    async handleCalendarNotifications(
      type: number,
      itemId?: number
    ): Promise<void> {
      //type: 0 - new event; 1 - altered event; 2 - deleted event

      // get unseen events, get badge counts, if on calendar, fetchCalendarByDates
      const common = useCommonStore()
      await common.fetchBadgeCounts()

      switch (type) {
        case 0:
        case 1:
          await this.fetchNewEventsData()
          break
        case 2:
          if (
            window.location.pathname.toLocaleLowerCase().includes('calendar')
          ) {
            await this.fetchCalendarByDates({
              startDate: this.startTimeInUtc
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: this.endTimeInUtc.utc().format('YYYY-MM-DD HH:mm:ss'),
              viewType: this.viewType
            })

            const deletedUnseenEventIndex = this.unseenEvents
              .map((ue) => ue.itemID)
              .indexOf(itemId ?? -1)

            if (deletedUnseenEventIndex) {
              this.unseenEvents = this.unseenEvents.filter(
                (ue, index) => index != deletedUnseenEventIndex
              )
            }

            this.newDeletedEvents = true
          }
          break
      }
    },
    setEntry(val: ICalendarEntry): void {
      this.entry = val
    },
    setEntryStartTime(startTime: Moment): void {
      this.entry.startTime = startTime
    },
    setEntryEndTime(endTime: Moment): void {
      this.entry.endTime = endTime
    },
    setEntryUntil(until: Moment): void {
      this.entry.until = until
    },
    setShowEventDetails(value: boolean): void {
      this.showEventDetails = value
    },
    setShowEditDialog(value: boolean): void {
      this.showEditDialog = value
    },
    setOccurrenceDay(value: Moment): void {
      this.occurrenceDay = value
    },
    setShowCancelDialog(value: boolean): void {
      this.showCancelDialog = value
    },
    setShowDeleteConfirmation(value: boolean): void {
      this.showDeleteConfirmation = value
    },
    setShowColorPicker(value: boolean): void {
      this.showColorPicker = value
    },
    setWeekIndexPlusOne(): void {
      this.weekIndex = this.weekIndex + 1
    },
    setWeekIndexMinusOne(): void {
      this.weekIndex = this.weekIndex - 1
    },
    setCurrentDay(day: Moment): void {
      this.currentDay = day
    },
    setViewType(viewType: number): void {
      this.viewType = viewType
    },
    setViewTypeName(viewTypeName: string): void {
      this.viewTypeName = viewTypeName
    },
    getDays(index: number) {
      return this.calendarDays.slice(index * 7, index * 7 + 7)
    },
    getDaysForMonth() {
      return this.calendarDays.slice(0, this.numberOfWeeks * 7)
    },
    isEditable: (event: ICalendarEntry) => {
      const commonStore = useCommonStore()
      return event == null
        ? false
        : event.ownerID == commonStore.fullUserInfo.userId
    },
    getClassForEventDisplay(entry: ICalendarEntry, day: string) {
      const commonStore = useCommonStore()
      const defaultColor =
        entry.ownerID == commonStore.fullUserInfo.userId
          ? 'cerulean'
          : 'slategray'
      let _class = entry.color.length > 0 ? entry.color : defaultColor
      if (moment(day, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
        _class += ' past-event'
      }
      if (entry.isCanceled) {
        _class = 'canceled-event'
      }
      return _class
    },
    getDateFormatted(date: string | Date | Moment | undefined | null) {
      if (!date) return ''
      const commonStore = useCommonStore()
      let response = ''
      if (date) {
        const _date = moment.isMoment(date) ? date.clone() : moment(date)
        response = `${_date.format(
          commonStore.getDateTimeSettings.shortDateFormat.toUpperCase()
        )}`
      }
      return response
    },
    getRecurringEventTextMethod(entry: ICalendarEntry, creatingEvent = false) {
      const commonStore = useCommonStore()

      let eventText = ''

      if (creatingEvent) {
        eventText = 'This event'

        if (entry.isAllDay) {
          eventText = 'This all-day event'
        }
        if (calHelpers.isEventMultiDay(entry)) {
          eventText = 'This multi-day event'
        }
        eventText += ` will repeat ${calHelpers.getRRuleAsText(
          entry.rRule,
          entry.startTime as Moment,
          commonStore.fullUserInfo.timeZoneNameForMomentJs
        )}.`
      } else {
        eventText += calHelpers
          .getRRuleAsText(
            entry.rRule,
            entry.dtStart as Moment,
            commonStore.fullUserInfo.timeZoneNameForMomentJs
          )
          .trim()

        eventText = eventText.charAt(0).toUpperCase() + eventText.slice(1)
      }

      return eventText
    },
    setColorFromPicker(color: string) {
      this.selectedColorFromPicker = color
    },
    getDayOfTheWeek(date?: string | Date | Moment | null | undefined): string {
      return moment(date).format('dddd').toLowerCase()
    },
    setNewDeletedEvents(newDeletedEvents: boolean) {
      this.newDeletedEvents = newDeletedEvents
    }
  }
})
