<template>
  <MoveResizeContainer
    :show-drag-handle="showDragHandle"
    :allow-drag="!isMobileWidth"
    :fullscreen="state.fullscreen || isMobileWidth"
    :root-style="rootStyle"
  >
    <template #dragHandle>
      <div v-if="showDragHandle" class="handle">
        <span class="title">{{ title }}</span>

        <div class="buttons">
          <button
            v-if="showFullscreenButton"
            type="button"
            class="btn tertiary p-00"
            @click="state.fullscreen = !state.fullscreen"
          >
            <FontAwesomeIcon :icon="fullscreenIconString" />
          </button>

          <button
            type="button"
            class="btn tertiary p-00"
            @click="emit('close')"
          >
            <FontAwesomeIcon icon="fa-light fa-close" />
          </button>
        </div>
      </div>

      <div v-else></div>
    </template>

    <template #mainContent>
      <slot name="mainContent" />
    </template>
  </MoveResizeContainer>
</template>
<script setup lang="ts">
import MoveResizeContainer from '@/layouts/components/MoveResizeContainer.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, reactive, toRefs } from 'vue'

const commonStore = useCommonStore()
const { isMobileWidth } = storeToRefs(commonStore)

const props = withDefaults(
  defineProps<{
    title?: string
    showFullscreenButton: boolean
    rootStyle?: string
    showDragHandle?: boolean
  }>(),
  {
    showFullscreenButton: false
  }
)
const { title, showFullscreenButton, rootStyle, showDragHandle } = toRefs(props)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const state = reactive<{
  fullscreen: boolean
}>({
  fullscreen: false
})

const fullscreenIconString = computed(
  () => `fa-light ${state.fullscreen ? 'fa-compress' : 'fa-expand'}`
)
</script>
<style scoped>
.handle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: var(--size-000) var(--size-00);
  align-items: center;
}

.title {
  word-wrap: break-word;
  line-height: 1;
}

.buttons {
  display: flex;
  width: fit-content;
  flex: 0 0 auto;
}
</style>
