﻿import constants from '@/exports/constants'
export default {
  //https://stackoverflow.com/questions/4994201/is-object-empty
  isNullEmpty(obj: any) {
    // null and undefined are "empty"
    if (obj == null) return true

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false

    //Array
    if (obj.length === 0) return true

    if (typeof obj === 'number') return false

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true

    const hasOwnProperty = Object.prototype.hasOwnProperty

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
      if (hasOwnProperty.call(obj, key)) return false
    }

    return true
  },
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  },
  getEmptyRegex() {
    return /^ *$/
  },
  addMoney(val1: number, val2: number) {
    const total = val1 + val2
    const _total = total.toFixed(2)
    return parseFloat(_total)
  },
  toCamel(o: any) {
    let newO: any, origKey, newKey: string, value
    if (o instanceof Array) {
      return o.map((value) => {
        if (typeof value === 'object') {
          value = this.toCamel(value)
        }
        return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        // eslint-disable-next-line no-prototype-builtins
        if (o.hasOwnProperty(origKey)) {
          newKey = (
            origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
          ).toString()
          value = o[origKey]
          if (
            value instanceof Array ||
            (value !== null && value?.constructor === Object)
          ) {
            value = this.toCamel(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  },
  toHumanReadableFileSize(size: number): string {
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (
      +(size / Math.pow(1024, i)).toFixed(2) * 1 +
      ' ' +
      ['B', 'KB', 'MB', 'GB'][i]
    )
  },
  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  },
  getExtensionFromFileName(fileName: string) {
    const split = fileName.split(/\.(?=[^.]+$)/)
    return split[1] ?? ''
  },
  secondsToTimestamp(timeInSeconds: number, showHoursAlways?: boolean) {
    const hours = Math.floor(timeInSeconds / 3600)
    //minutes = leftover seconds after dividing hours / 60
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    //seconds = leftover after dividing minutes / 60
    const seconds = Math.floor((timeInSeconds % 3600) % 60)

    const formattedHours = hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const formattedMinutes = minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const formattedSeconds = seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })

    return `${
      (hours || showHoursAlways) ? `${formattedHours}:` : ''
    }${formattedMinutes}:${formattedSeconds}`
  },
  getFileTypeDescription(mimeType: string): string {
    const mimeTypeMap: Record<string, string> = {
      'application/pdf': constants.VAULT_FILETYPES.pdf,
      'application/msword': constants.VAULT_FILETYPES.microsoft,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        constants.VAULT_FILETYPES.microsoft,
      'application/vnd.ms-excel': constants.VAULT_FILETYPES.microsoft,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        constants.VAULT_FILETYPES.microsoft,
      'application/vnd.ms-powerpoint': constants.VAULT_FILETYPES.microsoft,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        constants.VAULT_FILETYPES.microsoft,
      'text/plain': constants.VAULT_FILETYPES.microsoft,
      'text/html': constants.VAULT_FILETYPES.unknown,
      'image/jpeg': constants.VAULT_FILETYPES.image,
      'image/png': constants.VAULT_FILETYPES.image,
      'image/gif': constants.VAULT_FILETYPES.image,
      'image/webp': constants.VAULT_FILETYPES.image,
      'image/svg+xml': constants.VAULT_FILETYPES.image,
      'image/heic': constants.VAULT_FILETYPES.image,
      'image/heic-sequence': constants.VAULT_FILETYPES.image,
      'image/tiff': constants.VAULT_FILETYPES.image,
      'image/bmp': constants.VAULT_FILETYPES.image,
      'application/zip': constants.VAULT_FILETYPES.unknown,
      'application/x-rar-compressed': constants.VAULT_FILETYPES.unknown,
      'video/mp4': constants.VAULT_FILETYPES.video,
      'audio/mpeg': constants.VAULT_FILETYPES.audio
    }

    return mimeTypeMap[mimeType] || constants.VAULT_FILETYPES.unknown
  },
  omit<T, K extends (keyof T)[]>(obj: T, keys: K) {
    for (const key of keys) {
      delete obj[key]
    }

    return obj as Omit<T, K[number]>
  }
}
