<template>
  <div
    class="upgrade-plan relative"
    v-if="!subscriptionT2 && layoutOptions.showUpgradeBanner"
  >
    <button class="btn circle dialog-close close-button" @click="closeBanner()">
      <font-awesome-icon icon="fa-light fa-xmark" class="white" />
      <span class="sr-only">close</span>
    </button>
    <p v-html="getBannerText"></p>
    <slot></slot>
    <button
      :disabled="!canClickManageSubscription"
      class="btn secondary light-green"
      @click="manageSubscription()"
    >
      Upgrade
    </button>
  </div>
</template>
<script lang="ts" setup>
import constants from '@/exports/constants'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { computed, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CreateCustomerPortalSessionRequest } from '@/models/models'
import { br } from '@/plugins/trackerPlugin'
import type {
  ITrackingTrackFunction,
  UpgradeFeatureName
} from '@/models/interfaces'
import helper from '@/exports/helper'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global'
})
const track = inject<ITrackingTrackFunction>(
  '$trackingTrack'
) as ITrackingTrackFunction

const router = useRouter()
const route = useRoute()
const accountSettingsStore = useAccountSettingsStore()
const {
  getHasSubscriptionIssue,
  subscriptionInfo,
  isAppleSubscription,
  isAndroidSubscription,
  subscriptionT0,
  subscriptionT1,
  subscriptionT2
} = storeToRefs(accountSettingsStore)
const { createCustomerPortalSession, setPaywallPreviousPage } =
  accountSettingsStore

const commonStore = useCommonStore()
const { loading, layoutOptions } = storeToRefs(commonStore)
const { setUpgradeCTA } = commonStore

const props = defineProps({
  forewordMessage: {
    type: String,
    required: false
  },
  messageTier0: {
    type: String,
    required: true
  },
  messageTier1: {
    type: String,
    required: false
  }
})

const getBannerText = computed(() => {
  let _text = subscriptionT0
    ? t('banners.upgradeBannerFreeText')
    : t('banners.upgradeBannerStandardPlan')
  const _highlightWords = t('banners.highlightWords')
  const _highlightWordsArray = _highlightWords.split(',')

  if (subscriptionT0) {
    _text = `${props.forewordMessage} ${t('banners.upgradeBannerFreeText')} ${
      props.messageTier0
    }`
  }
  if (subscriptionT1 && props.messageTier1) {
    _text = `${props.forewordMessage} ${t(
      'banners.upgradeBannerStandardPlan'
    )} ${props.messageTier1}`
  }

  for (const text of _highlightWordsArray) {
    const regex = new RegExp(helper.escapeRegExp(text.trim()), 'gi')
    const replacement = `<strong>$&</strong>`
    _text = _text.replace(regex, replacement)
  }

  return _text
})
const canManageSubscription = computed(() => {
  return (
    (!getHasSubscriptionIssue.value ||
      subscriptionInfo.value.duplicateSubscriptionError ==
        constants.duplicateSubscriptionError.BraintreeApple) &&
    !loading.value
  )
})
const canClickManageSubscription = computed(() => {
  return (
    (canManageSubscription.value ||
      isAppleSubscription.value ||
      isAndroidSubscription.value ||
      getHasSubscriptionIssue.value) &&
    !loading.value
  )
})

const featureName = computed(() => {
  return route.matched[route.matched.length - 2].path.slice(1)
})

async function manageSubscription() {
  if (canManageSubscription.value && subscriptionT0) {
    await routeToPaywall()
  } else {
    const payloadPortal = new CreateCustomerPortalSessionRequest({
      returnUrl: `${import.meta.env.VITE_APP_BASE_URL}accountSettings/success`,
      priceId: '',
      planLevel: '',
      upgrade: false
    })
    const session = await createCustomerPortalSession(payloadPortal)
    if (session?.sessionUrl) {
      window.location.href = session.sessionUrl
    } else {
      // no session url, send them to the account step 1 page
      await routeToPaywall()
    }
  }
}

async function routeToPaywall() {
  await setPaywallPreviousPage({ name: route.name?.toString() })
  const featureName = route.meta.trackingName as string
  track(br.eventTypes.appAction, {
    feature: featureName,
    name: br.appActionEventNames.CTAUpgrade
  })
  router.push({ name: 'accountTypeStep1' })
}

async function closeBanner() {
  setUpgradeCTA(
    featureName.value as UpgradeFeatureName,
    true,
    moment.utc().format()
  )
}
</script>
<style lang="scss" scoped>
.upgrade-plan {
  background: var(--brand-gradient-3);
  border-radius: var(--radius-6);
  display: flex;
  flex-wrap: wrap;
  padding: var(--size-1);
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-inline: 1rem;
  line-height: normal;

  @media (min-width: 48em) {
    gap: 5rem;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
    margin-inline: 0;
    padding-inline: var(--size-8);
  }

  :deep(strong) {
    color: white !important;
  }

  p {
    color: white;
    margin: 0;
    margin-top: 1.5rem;
    @media (min-width: 48em) {
      margin: 0;
    }
  }

  button {
    flex: none;
    width: 40px;
    height: 40px;
  }

  svg {
    width: auto;
  }

  button:not(:active):focus-visible {
    outline: 2px solid white !important;
  }

  button:hover {
    border-color: var(--white);
    color: var(--white);
  }
}

.close-button {
  position: absolute;
  top: 0.25rem;
  @media (min-width: 48em) {
    top: 0.25rem;
    left: 1rem;
  }
}
</style>
