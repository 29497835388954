<template>
  <!-- List Page Header -->
  <div
    class="stack size-1"
    :class="{
      'mobile-background': props.showMobileBackground && isMobileWidth
    }"
    v-if="
      showUpgradeBanner ||
      (showAddNewButton && !showingNoCount && isDesktopWidth)
    "
  >
    <UpgradeBanner
      v-if="showUpgradeBanner"
      :foreword-message="layoutOptions.upgradeBannerForewordMessage"
      :message-tier0="layoutOptions.upgradeBannerMessageTier0 ?? ''"
      :message-tier1="layoutOptions.upgradeBannerMessageTier1"
    ></UpgradeBanner>

    <!--Add New -->
    <add-new-button
      v-if="showAddNewButton && !showingNoCount && isDesktopWidth"
      class="ml-auto"
      :icon-class="getIconClass()"
    ></add-new-button>
  </div>
</template>
<script setup lang="ts">
import AddNewButton from '@/components/library/AddNewButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import UpgradeBanner from '@/components/library/UpgradeBanner.vue'
import { computed } from 'vue'

const props = defineProps({
  headerText: String,
  showUpgradeBanner: {
    type: Boolean,
    default: false
  },
  showAddNewButton: {
    type: Boolean,
    default: true
  },
  showMobileBackground: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()
const commonStore = useCommonStore()
const { isMobileWidth, isDesktopWidth, showingNoCount, layoutOptions } =
  storeToRefs(commonStore)

const isMessages = computed(() => {
  return route.name?.toString() == 'messages'
})

function getIconClass() {
  if (isMessages.value && isMobileWidth.value) {
    return 'fa-light fa-pen-to-square icon icon-pen'
  } else if (isDesktopWidth.value) {
    return 'fa-light fa-plus-large icon icon-plus'
  } else {
    return 'fa-light fa-regular fa-square-plus icon icon-plus'
  }
}
</script>
<style lang="scss" scoped>
.upgrade-plan {
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
}

.mobile-background {
  background: var(--surface-1);
}
</style>
