<template>
  <VideoCallingMoveResizeContainer
    v-if="showVideoCall"
    :title="title"
    :show-fullscreen-button="!isMobileWidth"
    :show-drag-handle="!isMobileWidth"
    @close="endCallOrVoicemail"
    class="video-call-player"
    root-style="--player-width: 60%; --player-height: 720px;"
  >
    <template #mainContent>
      <div class="video-section-main overflow-x relative">
        <div
          v-if="!isMidCall"
          class="pill"
          v-skeleton="{
            loading: state.loading,
            minWidth: 25,
            maxWidth: 25
          }"
        >
          <font-awesome-icon
            icon="fa-light fa-circle-exclamation"
            class="icon-size-1"
          />
          {{ t('calling.videoCall.premiumUserInfo') }}
        </div>

        <!-- status text -->
        <div v-if="showConnecting" class="connecting-text">
          {{
            t('calling.videoCall.connectingTo', {
              coparentName: fullUserInfo.coparentFirstName
            })
          }}
          <div class="ml-1">
            <Loading :loading="true" />
          </div>
        </div>

        <!-- error text -->
        <div
          v-if="
            (state.deviceError || state.errorMsg.length > 0) && !state.loading
          "
          class="error-text"
          :class="[
            { 'is-minimized': !cameraControlsOpen },
            { 'show-connecting-error': showConnecting }
          ]"
        >
          <font-awesome-icon
            icon="fa-light fa-circle-exclamation"
            class="icon-size-2"
          />
          <div>
            <device-error
              :mediaError="state.deviceError"
              show-title
              @retry="retryDeviceError"
            ></device-error>
            <div v-if="state.errorMsg.length > 0">
              {{ state.errorMsg }}
            </div>
          </div>
        </div>
        <div class="remote-video" ref="remoteVideoDiv">
          <!-- local video -->
          <div
            class="local-video"
            style="align-self: center"
            ref="localVideoDiv"
          >
            <div
              v-if="!showErrorMsg && !state.loading"
              class="remote-audio"
              :class="{
                muted: state.remoteAudioMuted,
                unmuted: !state.remoteAudioMuted
              }"
            >
              <network-quality-bar
                :quality="state.localNetworkQuality"
              ></network-quality-bar>
            </div>
          </div>
          <!-- /local video -->
        </div>
        <!-- Voicemail prompt -->
        <div v-if="state.showVoiceMailPrompt" class="stack voicemail-prompt">
          <span class="font-medium">
            {{
              videoCallDeclined
                ? t('calling.videoCall.callDeclinedText')
                : t('calling.videoCall.noResponseText')
            }}
          </span>
          <br />
          {{ t('calling.videoCall.noResponseQuestion') }}
          <div class="flex gap-1">
            <button class="btn secondary" @click="endCall()">
              {{ t('calling.videoCall.endCall') }}
            </button>
            <button class="btn primary font-medium" @click="startVoicemail()">
              {{ t('calling.videoCall.noResponseCta') }}
            </button>
          </div>
        </div>
        <!-- Time remaining for call -->
        <div
          v-if="showElapsedTime"
          class="in-call-alerts-div"
          :class="{ 'is-minimized': !cameraControlsOpen }"
        >
          <div
            v-if="state.remoteAudioMuted && !state.isVoiceMail"
            class="call-alert"
          >
            <font-awesome-icon
              icon="fa-light fa-microphone-slash"
              class="icon-size-1"
            />
            <span>
              {{
                t('calling.videoCall.coparentDevice.micMuted', {
                  coparentName: fullUserInfo.coparentFirstName
                })
              }}
            </span>
          </div>
          <div
            v-if="!state.remoteVideoOn && !state.isVoiceMail"
            class="call-alert"
          >
            <font-awesome-icon
              icon="fa-light fa-video-slash"
              class="icon-size-1"
            />
            <span>
              {{
                t('calling.videoCall.coparentDevice.cameraDisabled', {
                  coparentName: fullUserInfo.coparentFirstName
                })
              }}
            </span>
          </div>
          <div v-if="showRemainingTime" class="call-alert">
            <font-awesome-icon
              icon="fa-light fa-circle-exclamation"
              class="icon-size-1"
            />
            <span>
              {{ state.formattedTimeRemaining }}
            </span>
          </div>
          <div class="call-alert">
            <!-- todo: find icon -->
            <font-awesome-icon
              icon="fa-light fa-arrows-rotate"
              class="icon-size-1"
            />
            <span>
              {{ state.formattedTimeElapsed }}
            </span>
          </div>
        </div>
        <!-- Control bar -->
        <!-- v-if="showControls" -->
        <div
          :class="{ 'is-minimized': !cameraControlsOpen }"
          class="camera-controls"
          v-skeleton="{
            loading: state.loading,
            minWidth: 25,
            maxWidth: 25
          }"
        >
          <div class="relative flex flex-column w-100 items-center">
            <button @click="toggleCameraControls" class="camera-control-toggle">
              <font-awesome-icon
                icon="fa-light fa-chevron-down"
                class="white icon-size-1"
              />
              <span class="sr-only">minimize</span>
            </button>

            <div class="controls-div">
              <!-- Camera -->
              <div class="flex align-center controls-relative gap-1">
                <Popper
                  class="info"
                  placement="top"
                  :arrow="true"
                  :show="state.showVideoPopper"
                >
                  <button
                    class="video-control"
                    v-if="state.localVideoOn"
                    @click="turnLocalVideoOff()"
                    ref="videoToggle"
                  >
                    <font-awesome-icon
                      icon="fa-light fa-video"
                      class="icon-size-3"
                    />
                    <span class="sr-only">Camera off</span>
                  </button>
                  <template #content>
                    <div
                      class="popper"
                      v-on-click-outside="onClickOutsideVideoToggle"
                    >
                      <span>{{
                        t('calling.videoCall.toggleCameraWarning')
                      }}</span>
                    </div>
                  </template>
                </Popper>
                <button
                  class="video-control"
                  v-if="!state.localVideoOn"
                  @click="turnLocalVideoOn()"
                >
                  <font-awesome-icon
                    icon="fa-light fa-video-slash"
                    class="icon-size-3"
                  />
                  <span class="sr-only">Camera on</span>
                </button>

                <button
                  v-if="!isMobileDevice"
                  @click="toggleVideoOptions"
                  class="camera-control-toggle"
                  ref="videoToggle"
                  v-on-click-outside="onClickOutsideVideoToggle"
                >
                  <font-awesome-icon
                    icon="fa-light fa-chevron-up"
                    class="white icon-size-1"
                  />
                  <span class="sr-only">minimize</span>
                </button>
                <ul class="dropdown-control-options" v-if="videoOptionsOpen">
                  <li class="dropdown-control-label">
                    {{ t('calling.videoCall.videoDevice.selectCamera') }}
                  </li>
                  <li
                    v-for="camera in getUniqueDevices(cameras)"
                    :key="camera.deviceId"
                    :class="{
                      'is-selected': camera.deviceId == videoInputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectVideoSource(camera.deviceId)"
                    >
                      <font-awesome-icon
                        v-if="camera.deviceId == videoInputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ camera.label || 'Unnamed Device' }}
                    </button>
                  </li>
                  <!-- <li class="dropdown-control-divider"></li> -->
                  <li>
                    <button type="button" @click="openDevicesModal('video')">
                      {{ t('calling.videoCall.videoDevice.settings') }}
                    </button>
                  </li>
                  <li
                    v-if="showVideoCallDiagnosticsOption"
                    class="video-diagnostics"
                  >
                    <button type="button" @click="startVideoDiagnostics">
                      <span>Video Diagnostics</span>
                      <font-awesome-icon
                        icon="fa-light fa-arrow-right-long"
                        class="icon-size-1 arrow"
                      />
                    </button>
                  </li>
                  <!-- Blur background button - uncomment when ready to add to the ui -->
                  <!-- 
                  <li
                    :class="{
                      'is-selected': state.isBlurred
                    }"
                  >
                    <button type="button" @click="toggleBlur">
                      <font-awesome-icon
                        v-if="state.isBlurred"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ t('calling.videoCall.videoDevice.blur') }}
                    </button>
                  </li> -->
                  <!-- EndBlur background button - uncomment when ready to add to the ui -->
                </ul>
              </div>
              <!-- /Camera -->

              <!-- Mute -->
              <div class="flex align-center controls-relative gap-1">
                <Popper
                  class="info"
                  placement="top"
                  :arrow="true"
                  :show="state.showMutePopper"
                >
                  <button
                    class="video-control"
                    v-if="!state.localAudioMuted"
                    @click="muteLocalAudio()"
                  >
                    <font-awesome-icon
                      icon="fa-light fa-microphone"
                      class="icon-size-3"
                    />
                    <span class="sr-only">Mute</span>
                  </button>
                  <template #content>
                    <div
                      class="popper"
                      v-on-click-outside="onClickOutsideAudioToggle"
                    >
                      <span>
                        {{ t('calling.videoCall.toggleMicWarning') }}
                      </span>
                    </div>
                  </template>
                </Popper>
                <button
                  class="video-control"
                  v-if="state.localAudioMuted"
                  @click="unMuteLocalAudio()"
                  ref="audioToggle"
                >
                  <font-awesome-icon
                    icon="fa-light fa-microphone-slash"
                    class="icon-size-3"
                  />
                  <span class="sr-only">UnMute</span>
                </button>

                <button
                  v-if="!isMobileDevice"
                  @click="toggleAudioOptions"
                  class="camera-control-toggle"
                  ref="audioToggle"
                >
                  <font-awesome-icon
                    icon="fa-light fa-chevron-up"
                    class="white icon-size-1"
                  />
                  <span class="sr-only">minimize</span>
                </button>

                <ul class="dropdown-control-options" v-if="audioOptionsOpen">
                  <li class="dropdown-control-label">
                    {{ t('calling.videoCall.audioDevice.selectMic') }}
                  </li>
                  <li
                    v-for="mics in getUniqueDevices(microphones)"
                    :key="mics.deviceId"
                    :class="{
                      'is-selected': mics.deviceId == audioInputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectAudioSource(mics.deviceId)"
                    >
                      <font-awesome-icon
                        v-if="mics.deviceId == audioInputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ mics.label || 'Unnamed Device' }}
                    </button>
                  </li>
                  <li class="dropdown-control-divider"></li>
                  <li class="dropdown-control-label mt-000">
                    {{ t('calling.videoCall.audioDevice.selectSpeaker') }}
                  </li>
                  <!-- default audio output == empty sink id -->
                  <li
                    :class="{
                      'is-selected': '' == audioOutputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectAudioOutput('')"
                    >
                      <font-awesome-icon
                        v-if="'' == audioOutputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ t('calling.videoCall.audioDevice.default') }}
                    </button>
                  </li>
                  <li
                    v-for="speaker in getUniqueDevices(speakers)"
                    :key="speaker.deviceId"
                    :class="{
                      'is-selected': speaker.deviceId == audioOutputId
                    }"
                  >
                    <button
                      class="ellipsis"
                      type="button"
                      @click="selectAudioOutput(speaker.deviceId)"
                    >
                      <font-awesome-icon
                        v-if="speaker.deviceId == audioOutputId"
                        icon="fa-light fa-circle-check"
                        class="icon-size-1 medium-green"
                      />
                      {{ speaker.label || 'Unnamed Device' }}
                    </button>
                  </li>
                  <li>
                    <button type="button" @click="openDevicesModal('audio')">
                      {{ t('calling.videoCall.audioDevice.settings') }}
                    </button>
                  </li>
                  <li
                    v-if="showVideoCallDiagnosticsOption"
                    class="video-diagnostics"
                  >
                    <button type="button" @click="startVideoDiagnostics">
                      <span>Video Diagnostics</span>
                      <font-awesome-icon
                        icon="fa-light fa-arrow-right-long"
                        class="icon-size-1 arrow"
                      />
                    </button>
                  </li>
                </ul>
              </div>

              <!-- Flip cameras -->
              <button
                v-if="isMobileDevice"
                class="video-control"
                @click="swapCameras()"
              >
                <font-awesome-icon
                  icon="fa-light fa-camera-rotate"
                  class="icon-size-3"
                />
                <span class="sr-only">Flip cameras</span>
              </button>
              <!-- /Mute -->

              <!-- End Call -->
              <button
                class="cancel"
                v-if="state.isVoiceMail"
                @click="endVoiceMail()"
              >
                <font-awesome-icon
                  id="chevron"
                  icon="fa-light fa-xmark"
                  class="icon-size-4"
                />
                <span class="sr-only">End Voicemail</span>
              </button>

              <button
                class="cancel"
                v-if="!state.isVoiceMail"
                @click="endCall()"
              >
                <font-awesome-icon
                  id="chevron"
                  icon="fa-light fa-xmark"
                  class="icon-size-4"
                />
                <span class="sr-only">End Call</span>
              </button>
              <!-- /End Call -->
            </div>
          </div>
        </div>

        <!-- Start / cancel -->
        <div v-if="showSendCancel" class="send-cancel">
          <button
            class="btn send"
            v-if="!state.loading && !showConnecting && !state.deviceError"
            @click="startVideoCall()"
            v-skeleton="state.loading"
          >
            <Loading :loading="state.attemptingCall" />
            <font-awesome-icon icon="fa-light fa-video" />
            <span class="sr-only">Start call</span>
          </button>
        </div>
      </div>
    </template>
  </VideoCallingMoveResizeContainer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCallingStore } from '@/stores/CallingStore'
import { useEventBus, useIntervalFn, useTimeoutFn } from '@vueuse/core'
import { watch, computed, ref, type Ref, reactive, nextTick, inject } from 'vue'
import { useCommonStore } from '@/stores/CommonStore'
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  connect,
  LocalVideoTrack,
  LocalAudioTrack,
  Room,
  RemoteParticipant,
  RemoteTrackPublication,
  type RemoteTrack,
  type ConnectOptions,
  type RemoteVideoTrack,
  Logger,
  TwilioError
} from 'twilio-video'
import constants from '@/exports/constants'
import moment, { type Moment } from 'moment'
import { useModals } from '@/composables/useModal/useModal'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import videoSettings from './components/VideoSettings.vue'
import audioSettings from './components/AudioSettings.vue'
import { useDevicesList } from '@vueuse/core'
import helper from '@/exports/helper'
import Loading from '@/components/library/Loading.vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import {
  Pipeline,
  GaussianBlurBackgroundProcessor
} from '@twilio/video-processors'
import NetworkQualityBar from './components/NetworkQualityBar.vue'
import { vOnClickOutside } from '@vueuse/components'
import type { OnClickOutsideOptions } from '@vueuse/core'
import {
  type MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check'
import DeviceError from './components/DeviceErorr.vue'
import { useI18n } from 'vue-i18n'
import VideoCallingMoveResizeContainer from './components/VideoCallingMoveResizeContainer.vue'
import type { IVideoCallNotification } from '@/models/interfaces'
import ErrorHelper from '@/exports/error'
import { br } from '@/plugins/trackerPlugin'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const track: any = inject('$trackingTrack')
const { t } = useI18n({ useScope: 'global' })

const accountSettingsStore = useAccountSettingsStore()
const { callingBalance, showAddMinutesSuccessToast } =
  storeToRefs(accountSettingsStore)
const { fetchCallingBalance, setShowAddMinutesSuccessToast } =
  accountSettingsStore
const commonStore = useCommonStore()
const { fullUserInfo, isMobileDevice, isMobileWidth } = storeToRefs(commonStore)
const { setToast } = commonStore
const callingStore = useCallingStore()
const {
  createRoom,
  joinRoom,
  completeRoom,
  updateCall,
  getActiveVideoCallByItemID,
  setShowVideoCall,
  updateVideoCallStatus,
  logAction,
  setActiveCall,
  setMediatId,
  setVideoCallThreadId,
  fetchCallingItems,
  setCoparentDecliningCall,
  getActiveVideoCall,
  updateVoicemailStatus,
  setShowCallingSetup,
  setIntroVideoDiagnostics
} = callingStore
const {
  showVideoCall,
  roomResult,
  isActiveCallIncoming,
  activeCall,
  videoInputId,
  audioInputId,
  audioOutputId,
  videoCallThreadId,
  videoCallDeclined
} = storeToRefs(callingStore)

const videoOptions = isMobileDevice.value
  ? { height: 480, frameRate: 24, width: 640 }
  : { height: 720, frameRate: 24, width: 1280 }

const FACING_MODES = {
  user: 'user',
  environment: 'environment'
}
const { createSlot, closeModal, generateModal, HTMLtoComponent } = useModals()

const WAITING_ROOM_TIMEOUT = 90 * 1000 // in millisends
const logger = Logger.getLogger('twilio-video')
logger.setLevel('error')

const {
  videoInputs: cameras,
  audioInputs: microphones,
  audioOutputs: speakers,
  ensurePermissions
} = useDevicesList()

// un-comment to add blur filter ack in
// const blurBackground = new GaussianBlurBackgroundProcessor({
//   assetsPath: '/twilio',
//   pipeline: Pipeline.WebGL2,
//   debounce: true
// })

interface NetworkQualityStats {
  // Define the structure of the network quality stats if known, otherwise use any
  [key: string]: any
}

interface IVideoCallingState {
  videoCallStatus: null | number
  audioLevel: number
  remoteAudioMuted: boolean
  remoteVideoOn: boolean
  facingMode: string
  isVoiceMail: boolean
  showVoiceMailPrompt: boolean
  localAudioMuted: boolean
  localVideoOn: boolean
  callTimeRemaining: number
  formattedTimeRemaining: string
  formattedTimeElapsed: string
  errorMsg: string
  errorType: 'video' | 'audio' | 'connection' | ''
  deviceError: MediaPermissionsError | null
  loading: boolean
  showMutePopper: boolean
  showVideoPopper: boolean
  blurProcessor: GaussianBlurBackgroundProcessor | null
  isBlurred: boolean
  localNetworkQuality: number | null
  remoteNetworkQuality: number | null
  attemptingCall: boolean
  notificationExpiresWhen: Moment | null
}

const initialState = (): IVideoCallingState => ({
  videoCallStatus: constants.VIDEO_CALL_STATUS_ENUM.PreCheck,
  audioLevel: 0,
  remoteAudioMuted: false,
  remoteVideoOn: false,
  facingMode: FACING_MODES.user,
  isVoiceMail: false,
  showVoiceMailPrompt: false,
  localAudioMuted: false,
  localVideoOn: true,
  callTimeRemaining: 0,
  formattedTimeRemaining: '',
  formattedTimeElapsed: '',
  errorMsg: '',
  errorType: '',
  deviceError: null,
  loading: false,
  showMutePopper: false,
  showVideoPopper: false,
  blurProcessor: null,
  isBlurred: false,
  localNetworkQuality: null,
  remoteNetworkQuality: null,
  attemptingCall: false,
  notificationExpiresWhen: null
})

const state = reactive<IVideoCallingState>(initialState())

function resetState(): void {
  const newState = initialState()
  Object.keys(newState).forEach((key) => {
    // Use as keyof State to ensure type safety when accessing properties
    const k = key as keyof IVideoCallingState
    ;(state[k] as any) = newState[k]
  })
}

let localVideoTrack: null | void | LocalVideoTrack = null
let localAudioTrack: null | void | LocalAudioTrack = null
let room: null | void | Room = null
const localVideoDiv = ref() as Ref<HTMLElement>
const remoteVideoDiv = ref() as Ref<HTMLElement>

let callTimerId: number | null = null

const route = useRoute()

watch(showVideoCall, (newValue) => {
  if (newValue === true) {
    cameraControlsOpen.value = true
    init()
  } else {
    cleanUpAndClose()
  }
})

watch(videoCallDeclined, (newValue, oldValue) => {
  if (newValue != oldValue && newValue === true) {
    handleCallDeclinedOrMissed(true)
    //reset the videoCallDeclined store value to false
    // setCoparentDecliningCall()
  }
})

watch(
  showAddMinutesSuccessToast,
  async (newValue) => {
    if (newValue === true && showVideoCall.value) {
      await fetchCallingBalance()
      getLocalMedia()
      setShowAddMinutesSuccessToast(false)
      useTimeoutFn(
        () =>
          setToast({
            showToast: true,
            text: t('calling.minutes.minutesToast'),
            type: 'success',
            showCloseButton: true
          }),
        1500
      )
    }
  },
  {
    immediate: true
  }
)

const callNotif = useEventBus<IVideoCallNotification>('callNotification')

// Computed

const isMidCall = computed(() => {
  return state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.MidCall
})

const showVideoCallDiagnosticsOption = computed(() => {
  return (
    state.videoCallStatus === constants.VIDEO_CALL_STATUS_ENUM.PreCheck &&
    !isMobileWidth.value &&
    !isMobileDevice.value
  )
})

const showConnecting = computed(() => {
  return (
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom ||
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.Connecting
  )
})

const showSendCancel = computed(() => {
  return (
    !isActiveCallIncoming.value &&
    (state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom ||
      state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.PreCheck)
  )
})

const showRemainingTime = computed(
  () => state.formattedTimeRemaining.length > 0
)

const showElapsedTime = computed(() => state.formattedTimeElapsed.length > 0)

const showErrorMsg = computed(() => {
  return state.errorMsg.length > 0
})

const title = computed(() =>
  activeCall.value
    ? state.isVoiceMail
      ? t('calling.item.details.recordingModalTitle.voicemail.to', {
          coparentName: fullUserInfo.value.coparentFullName
        })
      : t('calling.item.details.recordingModalTitle.videoCall.to', {
          coparentName: fullUserInfo.value.coparentFullName
        })
    : ''
)

function getUniqueDevices(devices: MediaDeviceInfo[]) {
  const unique = devices.filter(
    (obj, index) =>
      devices.findIndex((item) => item.deviceId === obj.deviceId) === index
  )
  return unique
}

async function callNotificationListener(
  requestNotification: IVideoCallNotification
) {
  if (requestNotification.status == constants.callNotifStatus.received) {
    callNotificationReceivedSender(requestNotification.expiresWhen) // only from tp layout when sender receives their own notification
  } else if (
    requestNotification.status === constants.callNotifStatus.accepted &&
    state.isVoiceMail
  ) {
    // console.log('Joining video call while recording VM')
    await endVoiceMail()
    // reopen
    await nextTick()
    await getActiveVideoCall()
    setShowVideoCall(true)
  } else if (
    requestNotification.status === constants.callNotifStatus.accepted &&
    isActiveCallIncoming.value
  ) {
    if (
      state.videoCallStatus === constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom &&
      roomResult.value?.itemID
    ) {
      // console.log('Joining video call while in waiting room')
      // activeCall can be updated when a notification comes in
      // roomresult is from createroom in while in waitingroom
      await updateCall({
        itemID: roomResult.value.itemID,
        callType: constants.CALL_TYPE_ENUM.video,
        newStatus: constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomAbandoned,
        action: constants.VIDEO_CALL_ACTION_ENUM.VideoCallAbandoned,
        actionData: 'VideoCallAbandoned'
      })
      disconnectCall()
      cleanUpAndClose()
      // reopen
      await nextTick()
      await getActiveVideoCall()
      setShowVideoCall(true)
    } else {
      // console.log('Joining video call')
      joinVideoCall()
    }
  }
}

function stopListenForCallNotification() {
  callNotif.off(callNotificationListener)
}

function listenForCallNotification() {
  callNotif.on(callNotificationListener)
}

function handleCallDeclinedOrMissed(declined: boolean) {
  // console.log('stopping the waiting room')
  // isVoicemail is only ever set to true when a user starts a voicemail
  if (state.isVoiceMail) {
    clearWaitingRoomTimers()
    return
  }
  disconnectCall()
  stopListenForCallNotification()
  listenForCallNotification()
  clearWaitingRoomTimers()
  if (!declined) {
    state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomTimedOut
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.WaitingRoomTimeout,
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomTimedOut,
      'WaitingRoomTimeout'
    )
    callNotif.emit({
      status: constants.callNotifStatus.ignored
    })
  } else {
    state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.CoparentRejected
    // call is updated on the rejector side
  }

  state.showVoiceMailPrompt = true
}

// Timers
const {
  pause: pauseWaitingRoom,
  resume: resumeWaitingRoom,
  isActive: waitingRoomActive
} = useIntervalFn(
  () => {
    if (
      state.notificationExpiresWhen &&
      state.notificationExpiresWhen.isBefore(moment.utc())
    ) {
      handleCallDeclinedOrMissed(false) // timer is cleard in this
    }
  },
  1000,
  {
    immediate: false,
    immediateCallback: true
  }
)

const {
  start: startWaitingRoomNotificationTimer,
  stop: stopWaitingRoomNotificationTimer
} = useTimeoutFn(
  () => {
    // if it notification never gets sent out, this should run
    handleCallDeclinedOrMissed(false)
  },
  WAITING_ROOM_TIMEOUT,
  {
    immediate: false
  }
)

function callNotificationReceivedSender(expiresWhen?: Moment) {
  // clear 'just in case' timedout after received notif
  startWaitingRoomNotificationTimer()

  if (expiresWhen) {
    state.notificationExpiresWhen = expiresWhen
    // synchronize timer w clock -- check every second
    setTimeout(() => {
      if (!waitingRoomActive.value) {
        resumeWaitingRoom()
      }
    }, 1000 - new Date().getMilliseconds())
  }
}

function clearWaitingRoomTimers() {
  stopWaitingRoomNotificationTimer()
  pauseWaitingRoom()
}

function startCallTimer() {
  state.callTimeRemaining = getCallMaxTimeInSeconds()
  const startTime = moment()
  const endTime = moment().add(state.callTimeRemaining, 'seconds')

  callTimerId = setInterval(() => {
    const now = moment()
    const remainingSeconds = endTime.diff(now) / 1000
    const elapsedSeconds = now.diff(startTime) / 1000 // Convert milliseconds to seconds

    state.formattedTimeElapsed = helper.secondsToTimestamp(elapsedSeconds, true)

    // Display the time once the timer gets to 5 minutes remaining
    if (remainingSeconds <= 5 * 60 && !isActiveCallIncoming.value) {
      state.formattedTimeRemaining = t(
        'calling.videoCall.minutesWarning',
        Math.ceil(remainingSeconds / 60)
      )
    }

    if (remainingSeconds <= 0 && !isActiveCallIncoming.value) {
      state.isVoiceMail ? endVoiceMail() : endCall()
    }
  }, 1000) as unknown as number
}

// Currently not using visibilty changes, but may need
function onElementVisibility(state: any) {
  if (state == 'true' || state == true) {
    // console.log('state: ' + state)
  } else {
    // console.log('else state: ' + state)
  }
}

async function init() {
  listenForCallNotification()
  // console.log('isActiveCallIncoming.value = ' + isActiveCallIncoming.value)
  // check if call is incoming
  if (isActiveCallIncoming.value == true) {
    // console.log('init - joining call')
    joinVideoCall()
  } else {
    state.loading = true
    // console.log('init - about to start call')
    getLocalMedia()
  }
}

function cleanUpAndClose(closeOnly?: boolean) {
  setMediatId('audioinput', '')
  setMediatId('audiooutput', '')
  setMediatId('videoinput', '')

  removeLocalVideo()
  clearWaitingRoomTimers()
  if (callTimerId) {
    clearInterval(callTimerId)
    callTimerId = null
  }
  if (activeCall.value && route.name == 'calling' && !closeOnly) {
    fetchCallingItems()
    fetchCallingBalance()
  }
  if (room) {
    if (room.state != 'disconnected') {
      room.disconnect()
    }
    room = null
  }
  resetState()
  setActiveCall(null)
  setShowVideoCall(false)
  setVideoCallThreadId(null)
  stopListenForCallNotification() // stop listening when video call modal is closed
  document.onvisibilitychange = null
  // console.log('All clean')
}

// Local media calls
async function getLocalMedia() {
  // reset error
  state.deviceError = null
  // get local video
  await requestMediaPermissions()
    .then(async () => {
      await getLocalVideo()
      await getLocalAudio()
      await ensurePermissions()
      state.loading = false
    })
    .catch((err: MediaPermissionsError) => {
      state.deviceError = err
      const { type } = err
      // if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
      //   // console.log(
      //   //  'browser does not have permission to access camera or microphone'
      //   //)
      // } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
      //   // console.log('user didnt allow app to access camera or microphone')
      // } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
      //   //console.log(
      //   //  'camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video) (mostly Windows specific problem)'
      //   //)
      // } else {
      //   //console.log('not all error types are handled by this library')
      // }
      state.loading = false
    })
}

async function getLocalVideo() {
  if (localVideoTrack) {
    stopDetachUnpublishTrack(localVideoTrack)
  }

  localVideoTrack = await createLocalVideoTrack({
    facingMode: 'user',
    deviceId: videoInputId.value.length > 0 ? videoInputId.value : undefined,
    name: videoInputId.value.length > 0 ? videoInputId.value : undefined
  }).catch(handleVideoError)

  if (localVideoTrack) {
    setMediatId(
      'videoinput',
      localVideoTrack.mediaStreamTrack.getSettings().deviceId ??
        localVideoTrack.name
    )
    attachVideoTrackToDOM()
  }
}

async function retryDeviceError() {
  if (state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.Connecting) {
    await retryWhileConnecting()
  } else {
    await getLocalMedia()
  }
}
// un-comment to add blur filter ack in
// function checkWebGLErrors(gl: WebGL2RenderingContext) {
//   const error = gl.getError()
//   if (error !== gl.NO_ERROR) {
//     console.error('WebGL error:', error)
//   } else {
//     console.log('No WebGL errors.')
//   }
// }

async function getLocalAudio() {
  // stopping, unpublishing, detaching any tracks currently attached
  if (localAudioTrack) {
    stopDetachUnpublishTrack(localAudioTrack)
  }

  localAudioTrack = await createLocalAudioTrack({
    noiseSuppression: false,
    echoCancellation: false,
    deviceId: audioInputId.value.length > 0 ? audioInputId.value : undefined,
    name: audioInputId.value.length > 0 ? audioInputId.value : undefined
  }).catch(handleAudioError)
  if (localAudioTrack) {
    // console.log(
    //   'set localAudio from getLocalAudio: ' +
    //     localAudioTrack.mediaStreamTrack.getSettings().deviceId
    // )
    setMediatId(
      'audioinput',
      localAudioTrack.mediaStreamTrack.getSettings().deviceId ??
        localAudioTrack.name
    )
  }
}

function setAudioOutput() {
  // get twilio video elements to set the speaker device id
  // Safari does not support this functionality
  const audioElements = document.querySelectorAll('audio')
  // console.log(`Audio elements found ${audioElements[0]}`)
  if (audioElements) {
    audioElements.forEach((audioElement) => {
      if (
        typeof audioElement.setSinkId === 'function' &&
        audioOutputId.value.length > 0
      ) {
        audioElement
          .setSinkId(audioOutputId.value)
          .then(() => {
            // console.log(`Audio output device changed to ${audioOutputId.value}`)
          })
          .catch((error) =>
            console.error('Error changing audio output device:', error)
          )
      } else {
        console.error('Browser does not support setSinkId:')
      }
    })
  }
}

function stopDetachUnpublishTrack(
  localTrack: LocalVideoTrack | LocalAudioTrack
) {
  if (room) {
    room.localParticipant.unpublishTrack(localTrack)
  }
  localTrack?.stop()
  localTrack?.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
  })
}

function attachVideoTrackToDOM() {
  if (localVideoTrack) {
    const existingVideoElement = document.getElementById(
      'local-video'
    ) as HTMLVideoElement
    if (existingVideoElement) {
      // If a video element exists, we want to replace its source with the new track
      // First, detach any existing track from the existing video element
      existingVideoElement.srcObject = null
      // Reattach the track, which effectively replaces the previous one
      localVideoTrack.attach(existingVideoElement)
    } else {
      const trackElement = localVideoTrack.attach()
      trackElement.id = 'local-video' // Use track SID for unique identification
      trackElement.classList.add('local-video-video')
      localVideoDiv.value.appendChild(trackElement)
    }
  } else {
    // console.log('Video Track Not Found: Unable to Attach.')
  }
}

function openDevicesModal(kind: string) {
  if (kind == 'video') {
    videoOptionsOpen.value = false
  } else {
    audioOptionsOpen.value = false
  }

  const el = generateModal({
    slot: {
      content: createSlot(
        'content',
        kind == 'video' ? videoSettings : audioSettings,
        {
          initialBlur: state.isBlurred,
          onUpdateTrack: (
            newTrack: LocalVideoTrack | LocalAudioTrack | null
            // un-comment to add blur filter ack in
            // isBlurred?: boolean
          ) => {
            if (kind == 'video') {
              if (newTrack) {
                // stop, detach, unpublish current
                if (localVideoTrack) {
                  stopDetachUnpublishTrack(localVideoTrack)
                }

                localVideoTrack = newTrack as LocalVideoTrack
                attachVideoTrackToDOM()
                publishLocalVideoTrack()
              }
              // un-comment to add blur filter ack in
              // if (isBlurred) {
              //   blurVideo()
              // } else if (!newTrack) {
              //   unBlurVideo()
              // }
            } else {
              // console.log('Updating Input')
              if (newTrack) {
                // stop, detach, unpublish current audio
                if (localAudioTrack) {
                  stopDetachUnpublishTrack(localAudioTrack)
                }

                localAudioTrack = newTrack as LocalAudioTrack
                publishLocalVideoTrack()
              }
            }
            closeModal(el)
          },
          onUpdateAudioOutput: () => {
            // console.log('Updating SinkId..')
            if (
              state.videoCallStatus != constants.VIDEO_CALL_STATUS_ENUM.PreCheck
            ) {
              // setting sink id to blank means default
              setAudioOutput()
            }
          },
          onCancel: () => {
            // console.log('onClose from selectMedia')
            closeModal(el)
          }
        }
      ).content
    },
    config: {
      showHeader: false,
      showFooter: false,
      showCloseButton: false,
      closeOnOutsideClick: false
    }
  })
}

function startVideoDiagnostics() {
  setShowCallingSetup(true)
  setIntroVideoDiagnostics(true)
}

// start of call / voicemail
async function startVoicemail() {
  setCoparentDecliningCall()
  stopListenForCallNotification() // IDK why we have to call this again but we do, so I'm unregistering it just in case
  listenForCallNotification()
  state.showVoiceMailPrompt = false
  state.isVoiceMail = true

  if (callTimerId) {
    clearInterval(callTimerId)
  }
  startCallTimer()

  // if 0 or null, set to undefined
  await createRoom({
    threadID: videoCallThreadId.value ? videoCallThreadId.value : undefined,
    type: constants.CALL_TYPE_ENUM.voicemail
  })

  if (roomResult.value?.itemID) {
    // connect to video call with media
    if (roomResult.value?.accessToken && localAudioTrack && localVideoTrack) {
      connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        tracks: [localAudioTrack, localVideoTrack]
      })

      // update status to midcall
      state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.MidCall

      updateVoicemailStatus({
        voicemailId: roomResult.value?.itemID,
        newStatus: constants.VIDEO_CALL_STATUS_ENUM.MidCall
      })
    }
    if (localVideoTrack) {
      localVideoDiv.value.remove()
      remoteVideoDiv.value.appendChild(localVideoTrack.attach())
    }
  } else {
    cleanUpAndClose(true) // close but dont fetch anything
  }
}

async function joinVideoCall() {
  setCoparentDecliningCall()
  stopListenForCallNotification()
  clearWaitingRoomTimers()
  state.showVoiceMailPrompt = false

  state.loading = true
  logActionLocal(
    constants.VIDEO_CALL_ACTION_ENUM.VideoCallAccepted,
    'VideoCallAccepted'
  )
  state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.Connecting
  // console.log('joinVideoCall - calling getLocalMedia')
  await getLocalMedia()
  // make sure we have an active call
  if (activeCall.value && state.deviceError == null) {
    // console.log('Active call: ' + activeCall.value, state.deviceError)
    // get access token
    await joinRoom({
      joinVideoCallItemID: activeCall.value?.itemID
    })

    // console.log('roomAccess: ' + roomResult.value?.accessToken)

    if (roomResult.value?.accessToken && localVideoTrack && localAudioTrack) {
      await connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        video: videoOptions,
        tracks: [],
        // tracks: [localVideoTrack, localAudioTrack],
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
      })
      publishLocalVideoTrack()
      // console.log('joinVideoCall - connected to rrom')
    }
    if (roomResult.value?.errorMessage) {
      state.errorType = 'connection'
      state.errorMsg = roomResult.value?.errorMessage
      // console.log('Error connecting call: ' + roomResult.value?.errorMessage)
      if (
        roomResult.value.errorCode === 2 ||
        roomResult.value.errorCode === 3
      ) {
        handleJoinRoomError(roomResult.value?.errorMessage)
      } else {
        cleanUpAndClose()
        ErrorHelper.handleError(roomResult.value?.errorMessage, 'joinRoom')
      }
    }
  }
}

// assumes joinVideoCall was called once
async function retryWhileConnecting() {
  await getLocalMedia()
  if (state.deviceError == null && activeCall.value) {
    await joinRoom({
      joinVideoCallItemID: activeCall.value?.itemID
    })

    // console.log('roomAccess2: ' + roomResult.value?.accessToken)

    if (roomResult.value?.accessToken && localVideoTrack && localAudioTrack) {
      await connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        video: videoOptions,
        tracks: [localVideoTrack, localAudioTrack],
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
      })

      // console.log('joinVideoCall2 - connected to rrom')
    }
    if (roomResult.value?.errorMessage) {
      state.errorType = 'connection'
      state.errorMsg = roomResult.value?.errorMessage
      // console.log('Error connecting call2: ' + roomResult.value?.errorMessage)
      if (
        roomResult.value.errorCode === 2 ||
        roomResult.value.errorCode === 3
      ) {
        handleJoinRoomError(roomResult.value?.errorMessage)
      } else {
        cleanUpAndClose()
        ErrorHelper.handleError(roomResult.value?.errorMessage, 'joinRoom')
      }
    }
  }
}

async function startVideoCall() {
  state.attemptingCall = true

  if (state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom)
    return

  // console.log('startVideoCall - starting video call')
  state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom

  // either wait for notification to come or after it does come, wait for it to expire
  startWaitingRoomNotificationTimer()

  // create the video call. Stores the roomResult when completed
  // if threadid = 0 or null, set to undefined
  await createRoom({
    threadID: videoCallThreadId.value ? videoCallThreadId.value : undefined,
    type: constants.CALL_TYPE_ENUM.video
  })

  //get active call
  if (roomResult.value?.itemID) {
    getActiveVideoCallByItemID(roomResult.value?.itemID) // set active video call to the one user just created

    // connect to video call without media
    if (roomResult.value?.accessToken) {
      connectToRoom(roomResult.value?.accessToken, {
        name: roomResult.value?.roomName,
        audio: true,
        maxAudioBitrate: 16000,
        video: videoOptions,
        tracks: [],
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
      })
    }

    // console.log('startVideoCall - create room and conencted to it')
  } else {
    cleanUpAndClose(true) // close but dont fetch anything
  }

  state.attemptingCall = false
}

async function connectToRoom(
  token: string,
  connectOptions: ConnectOptions | undefined
) {
  // console.log('connecting to room')
  try {
    room = await connect(token, connectOptions)

    // console.log('connected to room: ' + room)
  } catch (error) {
    // Handle media error here.
    // console.log('error connecting to room: ' + error)
    handleError(error)
  }

  // if callee, caller should be in the room already
  room?.participants.forEach((participant) => {
    remoteCallerConnected(participant)
    // console.log('participant in room: ' + participant)
  })

  // if caller, listen for callee to connect to room
  room?.on('participantConnected', (participant) => {
    // console.log('on participant connected in room: ' + participant)
    remoteCallerConnected(participant)
  })

  // Handle a disconnected RemoteParticipant.
  room?.on('participantDisconnected', () => {
    // console.log('on participant disconnected from room: ')
    disconnectRemoteCaller()
  })

  room?.on('disconnected', (room, error: TwilioError) => {
    if (error) {
      logActionLocal(
        constants.VIDEO_CALL_ACTION_ENUM.LeavingFromDisconnect,
        'LeavingFromDisconnect'
      )
      if (
        error.code === 20104 ||
        error.code === 53000 ||
        error.code === 53204
      ) {
        return
      }
      if (error.code === 53118) {
        // TwilioError CompleteRoom - https://www.twilio.com/docs/api/errors/53118
        // console.log('Disconnect from call with Android?')
        disconnectRemoteCaller() // No need to completeRoom
      } else {
        state.errorType = 'video'
        state.errorMsg = 'There is an issue with your connection.'
        cleanUpAndClose(true)
        ErrorHelper.handleError(error, 'videoCallDisconnect')
      }
    }
  })
  room?.on('reconnected', () => {
    // console.log('participant reconnected ')
    state.errorType = ''
    state.errorMsg = ''
  })
  room?.on('reconnecting', () => {
    // console.log('participant reconnecting ')
    state.errorType = 'connection'
    state.errorMsg = 'Your call is reconnecting.'
  })

  room?.localParticipant.setNetworkQualityConfiguration({
    local: 2,
    remote: 1
  })

  room?.localParticipant.on(
    'networkQualityLevelChanged',
    setLocalNetworkQualityStats
  )

  if (isMobileDevice.value) {
    // console.log('isMobileDevice: ' + isMobileDevice)
    // TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
    // In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
    window.onpagehide = () => {
      room?.disconnect()
    }

    // On mobile browsers, use "visibilitychange" event to determine when
    // the app is backgrounded or foregrounded.
    document.onvisibilitychange = async () => {
      if (document.visibilityState === 'hidden') {
        // console.log('onvisibilitychange: hidden')
        // When the app is backgrounded, your app can no longer capture
        // video frames. So, stop and unpublish the LocalVideoTrack.
        localVideoTrack?.stop()
        if (localVideoTrack) {
          room?.localParticipant.unpublishTrack(localVideoTrack)
        }
      } else if (showVideoCall.value) {
        // When the app is foregrounded, your app can now continue to
        // capture video frames. So, publish a new LocalVideoTrack.

        // todo: need to test on mobile
        // console.log('onvisibilitychange: shown')
        localVideoTrack = await createLocalVideoTrack({
          facingMode: 'user',
          deviceId:
            videoInputId.value.length > 0 ? videoInputId.value : undefined
        })
        await room?.localParticipant.publishTrack(localVideoTrack)
        attachVideoTrackToDOM()
      }
    }
  }
}

function retryConnection() {
  setTimeout(() => {
    // console.log('Retrying connection...')
    if (roomResult.value?.accessToken) {
      connectToRoom(roomResult.value?.accessToken ?? '', {
        name: roomResult.value?.roomName,
        audio: true,
        maxAudioBitrate: 16000,
        video: videoOptions,
        tracks: [],
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        }
      })
    }
  }, 5000) // retry after 5 seconds
}

function remoteCallerConnected(participant: RemoteParticipant) {
  clearWaitingRoomTimers()
  startCallTimer()

  // if caller, callee should already have tracks published
  participant.tracks.forEach((publication: RemoteTrackPublication) => {
    // console.log('remote participant track ' + publication)
    if (publication.isSubscribed) {
      attachCoParentTrack(publication.track)
    }
  })

  // If callee, the caller's tracks have not been published yet so listen for them
  participant.on('trackSubscribed', (track) => {
    // console.log('remote participant track is subscribed: ' + track)
    attachCoParentTrack(track)
    // console.log('remote participant track is attached ')
  })

  participant.on('trackUnsubscribed', (track) => {
    // console.log('track unsubscribed: ' + track)
    if (track.kind == 'video') {
      turnRemoteVideoOff(track)
    }
  })

  participant.on('networkQualityLevelChanged', setRemoteNetworkQualityStats)

  // publish the local tracks once the co-parent has connected
  if (!isActiveCallIncoming.value) {
    publishLocalVideoTrack()
  }
  // both parties are connected, so change the status to MidCall and start timer
  state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.MidCall
  // if caller update the call status and start timer
  if (!isActiveCallIncoming.value == true && activeCall.value) {
    updateVideoCallStatus({
      videoCallId: activeCall.value.itemID,
      newStatus: constants.VIDEO_CALL_STATUS_ENUM.MidCall
    })
  }
}

async function publishLocalVideoTrack() {
  // console.log('publishing local tracks')
  if (localAudioTrack && localVideoTrack) {
    // when updating audio source while muted, stay muted but don't log
    if (state.localAudioMuted) {
      localAudioTrack.disable()
    }

    // when updating video source while disabled, stay disabled but don't log
    if (!state.localVideoOn) {
      localVideoTrack.disable()
    }

    room?.localParticipant.publishTrack(localAudioTrack)
    room?.localParticipant.publishTrack(localVideoTrack)
    room?.localParticipant.setNetworkQualityConfiguration({
      local: 2,
      remote: 1
    })
    room?.localParticipant.on(
      'networkQualityLevelChanged',
      setLocalNetworkQualityStats
    )
  } else {
    handleVideoError()
  }
}

function attachCoParentTrack(track: RemoteTrack | null) {
  // for sure no more notifications will come
  stopListenForCallNotification()

  // console.log('Attaching coparent track:', track)

  if (!track) {
    // console.log('Track is null, skipping.')
    return
  }

  if (track.kind == 'video') {
    state.localVideoOn = true
    const existingVideoElement = document.getElementById(
      'remote-video'
    ) as HTMLVideoElement
    if (existingVideoElement) {
      // If a video element exists, we want to replace its source with the new track
      // First, detach any existing track from the existing video element
      existingVideoElement.srcObject = null
      // Reattach the track, which effectively replaces the previous one
      track.attach(existingVideoElement)
    } else {
      const trackElement = track.attach()
      trackElement.id = 'remote-video' // Use track SID for unique identification
      trackElement.classList.add('remote-video-video')
      remoteVideoDiv.value.appendChild(trackElement)
    }

    // fix the local video to the upper right-hand corner
    state.remoteVideoOn = track.isEnabled
    localVideoDiv.value.classList.add('local-fixed')
  }

  if (track.kind == 'audio') {
    remoteVideoDiv.value
      .appendChild(track.attach())
      .classList.add('remote-video-video')
    // console.log('Attached track:', track.kind)
    // check if track is initially muted/unmuted
    state.remoteAudioMuted = !track.isEnabled
  }
  // set speaker device Id to video element that was just attached
  setAudioOutput()

  track.on('disabled', function () {
    if (track.kind === 'video') {
      // console.log('turning off video')
      turnRemoteVideoOff(track)
    } else {
      muteRemoteAudio()
    }
  })
  track.on('enabled', function () {
    if (track.kind === 'video') {
      turnRemoteVideoOn(track)
    } else {
      unMuteRemoteAudio()
    }
  })
}

function setLocalNetworkQualityStats(
  networkQualityLevel: number | null,
  networkQualityStats?: NetworkQualityStats | null
): void {
  // Print in console the networkQualityLevel using bars
  state.localNetworkQuality = networkQualityLevel
  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    // console.log('Network Quality statistics:', networkQualityStats)
  }
}

function setRemoteNetworkQualityStats(
  networkQualityLevel: number | null,
  networkQualityStats?: NetworkQualityStats | null
): void {
  state.remoteNetworkQuality = networkQualityLevel
  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    // console.log('Network Quality statistics:', networkQualityStats)
  }
}

// User controls
const videoToggle = ref<HTMLElement>()
const onClickOutsideVideoToggle: [() => void, OnClickOutsideOptions] = [
  () => {
    state.showVideoPopper = state.showVideoPopper
      ? !state.showVideoPopper
      : state.showVideoPopper
    videoOptionsOpen.value = videoOptionsOpen.value
      ? !videoOptionsOpen.value
      : videoOptionsOpen.value
  },
  { ignore: [videoToggle] }
]

const audioToggle = ref<HTMLElement>()
const onClickOutsideAudioToggle: [() => void, OnClickOutsideOptions] = [
  () => {
    state.showMutePopper = state.showMutePopper
      ? !state.showMutePopper
      : state.showMutePopper
    audioOptionsOpen.value = audioOptionsOpen.value
      ? !audioOptionsOpen.value
      : audioOptionsOpen.value
  },
  { ignore: [audioToggle] }
]

function muteLocalAudio() {
  if (room) {
    room?.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable()
      state.localAudioMuted = true
    })
    logActionLocal(constants.VIDEO_CALL_ACTION_ENUM.AudioMuted, 'AudioMuted')
  } else {
    state.showMutePopper = !state.showMutePopper
  }
}

function unMuteLocalAudio() {
  room?.localParticipant.audioTracks.forEach((publication) => {
    publication.track.enable()
    state.localAudioMuted = false
  })
  logActionLocal(constants.VIDEO_CALL_ACTION_ENUM.AudioUnmuted, 'AudioUnmuted')
}

async function turnLocalVideoOn() {
  room?.localParticipant.videoTracks.forEach((publication) => {
    publication.track.enable()
    state.localVideoOn = true
  })
  logActionLocal(constants.VIDEO_CALL_ACTION_ENUM.VideoEnabled, 'VideoEnabled')
}

async function turnLocalVideoOff() {
  if (room) {
    room?.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable()
      state.localVideoOn = false
    })
    logActionLocal(
      constants.VIDEO_CALL_ACTION_ENUM.VideoDisabled,
      'VideoDisabled'
    )
  } else {
    state.showVideoPopper = !state.showVideoPopper
  }
}

function turnRemoteVideoOn(track: RemoteVideoTrack) {
  // console.log('turning remote video on:')
  const attachedElement = track.attach()
  if (attachedElement instanceof HTMLElement) {
    remoteVideoDiv.value
      .appendChild(attachedElement)
      .classList.add('remote-video-video')
  }
  state.remoteVideoOn = true
}

function turnRemoteVideoOff(track: RemoteVideoTrack) {
  // console.log('turning remote video off:')
  track.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
    element.remove()
  })
  state.remoteVideoOn = false
}

function swapCameras() {
  if (localVideoTrack) {
    // Switch to the back facing camera or back to user camera.
    state.facingMode =
      state.facingMode == FACING_MODES.user
        ? FACING_MODES.environment
        : FACING_MODES.user
    localVideoTrack.restart({
      facingMode: state.facingMode
    })
    logActionLocal(
      state.facingMode == FACING_MODES.environment
        ? constants.VIDEO_CALL_ACTION_ENUM.FlipToBackCamera
        : constants.VIDEO_CALL_ACTION_ENUM.FlipToFrontCamera,
      state.facingMode == FACING_MODES.environment
        ? 'FlipToBackCamera'
        : 'FlipToFrontCamera'
    )
  }
}

function muteRemoteAudio() {
  // show muted icon
  // console.log('remote user muted')
  state.remoteAudioMuted = true
}

function unMuteRemoteAudio() {
  // show unmuted icon
  // console.log('remote user unmuted')
  state.remoteAudioMuted = false
}

function disconnectRemoteCaller() {
  // Remove the Participant's media container.
  remoteVideoDiv.value.remove()
  cleanUpAndClose()
}

function removeLocalVideo() {
  // Remove the local user's media container.
  localVideoTrack?.stop()
  localAudioTrack?.stop()
  localVideoTrack?.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
    element.remove()
  })
  localAudioTrack?.detach().forEach((element: HTMLMediaElement) => {
    element.srcObject = null
    element.remove()
  })
}

//End call
function disconnectCall() {
  if (room) {
    room.disconnect()
  }
}

function endCallOrVoicemail() {
  if (state.isVoiceMail) {
    endVoiceMail()
  } else {
    endCall()
  }
}

async function endCall() {
  if (
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.WaitingRoom ||
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.PreCheck ||
    state.videoCallStatus ==
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomTimedOut ||
    state.videoCallStatus ==
      constants.VIDEO_CALL_STATUS_ENUM.CoparentRejected ||
    state.videoCallStatus == constants.VIDEO_CALL_STATUS_ENUM.Connecting ||
    state.errorMsg == 'Video Call already in progress.'
  ) {
    cancelCall()
    return
  }

  track(br.eventTypes.appAction, {
    feature: br.appActionFeature.calling,
    name: br.appActionEventNames.videoCompleted
  })

  removeLocalVideo()
  disconnectCall()
  state.videoCallStatus = constants.VIDEO_CALL_ACTION_ENUM.CompleteCall
  if (activeCall.value?.itemID) {
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.CompleteCall,
      isActiveCallIncoming.value
        ? constants.VIDEO_CALL_STATUS_ENUM.CoparentCompleted
        : constants.VIDEO_CALL_STATUS_ENUM.CreatorCompleted,
      'CompleteCall'
    )

    await completeRoom({
      type: constants.CALL_TYPE_ENUM.video,
      itemID: activeCall.value?.itemID
    })
  }

  disconnectCall()
  cleanUpAndClose()
}

async function endVoiceMail() {
  removeLocalVideo()
  disconnectCall()
  if (roomResult.value?.itemID) {
    state.videoCallStatus = constants.VIDEO_CALL_ACTION_ENUM.VoicemailCreated

    await completeRoom({
      type: constants.CALL_TYPE_ENUM.voicemail,
      itemID: roomResult.value?.itemID
    })

    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.CompleteCall,
      constants.VIDEO_CALL_STATUS_ENUM.CreatorCompleted,
      'CompleteCall'
    )

    logActionLocal(
      constants.VIDEO_CALL_ACTION_ENUM.VoicemailCreated,
      'VoicemailCreated'
    )
  }

  cleanUpAndClose()
}

function cancelCall() {
  if (
    room &&
    activeCall.value &&
    activeCall.value?.itemID &&
    state.videoCallStatus !=
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomTimedOut
  ) {
    room.disconnect()
    state.videoCallStatus =
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomAbandoned
    updateCallLocal(
      constants.VIDEO_CALL_ACTION_ENUM.VideoCallAbandoned,
      constants.VIDEO_CALL_STATUS_ENUM.WaitingRoomAbandoned,
      'VideoCallAbandoned'
    )
    disconnectCall()
    cleanUpAndClose()
  } else if (room) {
    // WaitingRoomTimedOut
    disconnectCall()
    cleanUpAndClose()
  } else {
    // closing while connnecting
    // console.log('closing while connecting')
    cleanUpAndClose()
  }
}

// Error handling
function handleError(error: any) {
  // Handle connection error here.
  /// console.log('handleError: ' + error)
  state.videoCallStatus = constants.VIDEO_CALL_STATUS_ENUM.PreCheck
  clearWaitingRoomTimers()
  state.errorMsg =
    'There is an issue connecting your call. Please check your connection, refresh, and try again.'
  state.errorType = 'video'
}

function handleVideoError() {
  state.errorMsg = 'There is an issue with your camera.'
  state.errorType = 'video'
}

function handleAudioError(error: any) {
  // console.log('Twilio audio error: ' + error)
  state.errorType = 'audio'
}

function handleJoinRoomError(errorMessage: string) {
  cleanUpAndClose()
  const callEndedString = t('calling.videoCall.endedModal.content')
  const callEnded = `<span class="place-center">${
    roomResult.value?.errorCode === 3 ? callEndedString : errorMessage
  }</span>`
  generateModal({
    default: {
      headerText: t('calling.videoCall.endedModal.header'),
      footerButtonLabel: 'Okay'
    },
    slot: {
      content: createSlot('content', HTMLtoComponent(callEnded)).content
    },
    config: {
      showHeader: true,
      showBody: true,
      showFooter: true,
      addContentPadding: true,
      closeOnConfirm: true,
      showCloseButton: true
    }
  })
}

// helper functions
function updateCallLocal(
  action: number,
  newStatus: number,
  actionData: string
) {
  if (state.isVoiceMail && roomResult.value?.itemID) {
    updateCall({
      itemID: roomResult.value.itemID,
      callType: constants.CALL_TYPE_ENUM.voicemail,
      newStatus: newStatus,
      action: action,
      actionData: actionData
    })
  } else if (activeCall.value) {
    updateCall({
      itemID: activeCall.value.itemID,
      callType: constants.CALL_TYPE_ENUM.video,
      newStatus: newStatus,
      action: action,
      actionData: actionData
    })
  }
}

// function updateVideoCallStatusLocal(payload: IUpdateVideoCallStatusRequest) {
//   UpdateVideoCallStatus(payload)
// }

function logActionLocal(action: number, data: string) {
  if (state.isVoiceMail && roomResult.value?.itemID) {
    logAction({
      itemID: roomResult.value.itemID,
      callType: constants.CALL_TYPE_ENUM.voicemail,
      action: action,
      data: data
    })
  } else if (room && activeCall.value) {
    logAction({
      itemID: activeCall.value?.itemID,
      callType: constants.CALL_TYPE_ENUM.video,
      action: action,
      data: data
    })
  }
}

function getCallMaxTimeInSeconds(): number {
  const twoHoursInSeconds = 120 * 60
  const callingBalanceInSeconds: number =
    callingBalance.value == null ? 0 : callingBalance.value * 60
  return callingBalanceInSeconds < twoHoursInSeconds
    ? callingBalanceInSeconds
    : twoHoursInSeconds
}

async function selectVideoSource(input: string) {
  if (input == videoInputId.value) return

  setMediatId('videoinput', input)
  await getLocalVideo()
  publishLocalVideoTrack()
  state.isBlurred = false
}

async function selectAudioSource(input: string) {
  if (input == audioInputId.value) return

  setMediatId('audioinput', input)
  await getLocalAudio()
  publishLocalVideoTrack()
}

async function selectAudioOutput(input: string) {
  if (input == audioOutputId.value) return
  // console.log('output')
  setMediatId('audiooutput', input)
  // const audioElement = new Audio()
  // await audioElement.setSinkId(input)
  setAudioOutput()
}

// un-comment to add blur filter back into app
// function isWebGL2Supported() {
//   try {
//     const canvas = document.createElement('canvas')
//     const gl =
//       canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2')
//     if (!gl) {
//       console.error('WebGL2 context not created.')
//     } else {
//       // console.log('WebGL2 context created successfully.')
//     }
//     return !!gl
//   } catch (e) {
//     console.error('Error checking WebGL2 support:', e)
//     return false
//   }
// }

// async function toggleBlur() {
//   if (localVideoTrack) {
//     if (state.isBlurred) {
//       unBlurVideo()
//     } else {
//       blurVideo()
//     }
//   }
// }

// async function blurVideo() {
//   if (isWebGL2Supported()) {
//     // Create a WebGL2 context explicitly
//     const canvas = document.createElement('canvas')
//     const gl = canvas.getContext('webgl2')
//     if (!gl) {
//       console.error('Failed to create WebGL2 context.')
//       return
//     }
//     console.log('WebGL2 context created:', gl)

//     checkWebGLErrors(gl)

//     await blurBackground.loadModel().then(() => {
//       localVideoTrack?.addProcessor(blurBackground, {
//         inputFrameBufferType: 'video',
//         outputFrameBufferContextType: 'webgl2'
//       })
//       state.isBlurred = true
//     })
//     checkWebGLErrors(gl)
//   }
// }

// function unBlurVideo() {
//   if (localVideoTrack) {
//     localVideoTrack.removeProcessor(blurBackground)
//     state.isBlurred = false
//   }
// }

const cameraControlsOpen = ref(true)
const toggleCameraControls = () => {
  cameraControlsOpen.value = !cameraControlsOpen.value
}

const videoOptionsOpen = ref(false)
const toggleVideoOptions = () => {
  videoOptionsOpen.value = !videoOptionsOpen.value
  audioOptionsOpen.value = false
  state.showVideoPopper = false
}

const audioOptionsOpen = ref(false)
const toggleAudioOptions = () => {
  audioOptionsOpen.value = !audioOptionsOpen.value
  videoOptionsOpen.value = false
  state.showMutePopper = false
}
</script>

<style scoped lang="scss">
.info {
  > :deep(.popper) {
    width: 132px;
    text-wrap: pretty;
  }
}

.controls-relative {
  position: static;

  @media (width >= 48em) {
    position: relative;
  }
}

.btn.send,
.btn.cancel {
  aspect-ratio: 1;
  min-width: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  color: white;
}

.btn.start {
  background-color: var(--medium-green);
}

.intro-text {
  max-width: 45ch;
  text-align: center;
  line-height: 1.2;
  order: 2;
}
.check-settings {
  width: fit-content;
  margin: 0.5rem auto 0;
  align-items: center;
  padding: 0.75rem;
  border-radius: 3rem;
  font-size: var(--font-size-1);
  max-width: 45ch;
  text-align: center;
}
.check-settings button {
  white-space: none;
  width: fit-content;
  background-color: transparent;
  border-radius: 2.625rem;
  padding: 0;
  color: var(--text-1);
  text-decoration: none;
  color: var(--brand);
  box-shadow: none !important;
  padding: 0;
  border: none;
}

.video-call-player {
  --player-width: 60%;
  --player-height: 720px;
}

.remote-video {
  width: 100%;
  height: 100%;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.remote-video :deep(video) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
:deep(.remote-video-video) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
  transform: translate(-50%, -50%); /* Center it */
}
.local-video {
  z-index: 2000;
  display: grid;
  gap: 1.5rem;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: black;

  @media (width >= 48em) {
    width: 100%;
    height: 100%;
  }
}
.local-video.local-fixed {
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  width: 8em;
  height: 10em;
  border-radius: 0.75rem;
  overflow: clip;
}

.remote-audio {
  position: absolute;
  bottom: 0;
  left: 0.5rem;
  z-index: 100;
  // background-color: var(--surface-2);
  width: 2.1875rem;
  height: 1.5rem;
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, -0.5rem, 0);
  transition: transform 0.2s ease-in-out;
}

@media (width <= 48em) {
  .remote-audio {
    transform: translate3d(0, -7rem, 0);
  }
  .is-minimized .remote-audio {
    transform: translate3d(0, -3rem, 0);
  }
}

:where(svg.muted, svg.unmuted) {
  display: none;
}
.remote-audio.unmuted svg.unmuted {
  display: inline-block;
}
.remote-audio.muted svg.muted {
  display: inline-block;
  color: #f24e4e;
}

:deep(.local-video-video) {
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
  background-color: var(--surface-2);
  margin-left: auto;
  margin-right: auto;
  // transform: scaleX(-1);

  @media (width >= 48em) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

:is(button.send, button.cancel) {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  border: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:is(button.send, button.cancel) .label {
  position: absolute;
  bottom: -1.75rem;
}

:is(button.send, button.cancel) svg {
  width: 25px;
  height: 25px;
}

button.send {
  background-color: var(--brand-6);
}
button.cancel {
  background-color: var(--surface-attention);
  color: white;
}

.camera-control-toggle {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 0.5rem;
}

.camera-controls.is-minimized {
  transform: translate3d(0, 4.5rem, 0);
}
.camera-controls.is-minimized .camera-control-toggle svg {
  rotate: 180deg;
}

.video-control {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #747776;
  color: white;
}

.send-cancel {
  display: flex;
  width: fit-content;
  gap: 2rem;
  z-index: 2005;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  transform: translate3d(0, -8rem, 0);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;
  inset-inline: 0;
  transition: transform 0.2s ease-in-out;
}

.is-minimized .send-cancel {
  transform: translate3d(0, -4rem, 0);
}

// .is-minimized :deep(.calling-modal-meta) { transform: translate3d(0,-9rem,0);  }

.camera-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.125s ease-in-out;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0.25rem 0.5rem 1rem;
  z-index: 5000;
  border-radius: 1.5rem 1.5rem 0 0;

  align-items: center;
  background-color: rgba(32, 30, 30, 0.9);

  @media (width >= 48em) {
    padding: 0.25rem 2.5rem 1rem;
    width: fit-content;
  }
}

.controls-div {
  display: flex;
  gap: 1.5rem;

  @media (width >= 48em) {
    gap: 2rem;
  }
}

.dropdown-control-options {
  position: absolute;
  bottom: calc(100% + 1rem);
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--surface-1);
  padding: 1rem 0;
  border-radius: 0.75rem;
  box-shadow: var(--shadow-2);
  max-width: 90%;
  width: 100%;

  @media (width >= 48em) {
    max-width: 20rem;
    left: Calc(100% - 3rem);
    right: unset;
    width: unset;
  }

  li {
    position: relative;
  }

  .is-selected button {
    background-color: var(--surface-2);
  }

  button {
    background-color: var(--surface-1);
    border: none;
    padding: 0.25rem 2rem;
    white-space: nowrap;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--surface-2);
    }
  }

  svg {
    position: absolute;
    left: 0.7rem;
    top: 0.45rem;
  }
}

.video-diagnostics {
  border-top: var(--border-gray);

  svg {
    position: auto;
    left: 10.5rem;
    top: 0.5rem;
  }
}

.dropdown-control-label {
  padding: 0.125rem 1rem;
  white-space: nowrap;
  font-variation-settings: 'wght' 500;
}

// .dropdown-control-divider {
//   margin-inline: 1rem;
//   border: 1px solid var(--surface-3);
// }

li.dropdown-control-divider:not(:first-child) {
  margin-top: 0.75rem;
}

.voicemail-prompt {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: unset;
  flex-wrap: wrap;
  text-align: center;
  margin-inline: auto;
  z-index: 25000;
  position: absolute;
  transform: translate3d(0, -18rem, 0);
  background-color: var(--surface-1);
  padding: 1.5rem;
  border-radius: 0.75rem;
  left: 0;
  right: 0;
  width: fit-content;
  transition: all 0.2s var(--ease-1);
  bottom: 0;
  @media (width <= 48em) {
    bottom: -18rem;
    margin-inline: 0;
    width: 100%;
    border-radius: 0.75rem 0.75rem 0 0;
  }
}

.is-minimized .voicemail-prompt {
  transform: translate3d(0, -13rem, 0);
}

.in-call-alerts-div {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transform: translate3d(0px, -7rem, 0);
  transition: transform 0.2s ease-in-out;
}
.in-call-alerts-div.is-minimized {
  transform: translate3d(0, -2.5rem, 0);
}
.call-alert {
  display: inline-flex;
  align-items: center;
  background-color: var(--surface-1);
  border-radius: 3rem;
  padding: 0 1em;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-start;
  margin: auto auto;
  width: fit-content;
  color: var(--text-2);
}
.call-alert span {
  flex-shrink: 0;
}
.draggable {
  justify-content: center;
  align-items: center;
  cursor: move; /* Gives a visual cue that the element is movable */
}
.connecting-text {
  display: flex;
  justify-content: center;
  padding: var(--space-3xs) var(--space-md) var(--space-3xs) var(--space-xs);
  margin-bottom: 0.5rem;
  background: var(--brand-2);
  color: var(--brand-9);
  z-index: 2500;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  border-radius: 3rem;
  font-variation-settings: 'wght' 500;
}

.error-text {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  align-items: center;
  color: var(--red-7);
  background-color: #f8e9ed;
  width: fit-content;
  margin-inline: auto;
  margin-top: 4rem;
  position: absolute;
  z-index: 2500;
  inset-inline: 0;
  border-radius: var(--radius-2);
  max-width: calc(100% - 2rem);
  @media (width >= 48em) {
    max-width: calc(100% - 4rem);
  }
}

.show-connecting-error {
  margin-top: 7rem;
}
.video-section-main {
  height: calc(100%);
}

.place-center {
  display: grid;
  place-content: center;
  margin: auto;
}
.margin-auto {
  margin: auto;
  padding: 1rem 0;
}

.pill {
  padding: var(--space-3xs) var(--space-xs);
  margin-bottom: 0.5rem;
  background: var(--gray-cool-0);
  color: var(--teal-9);
  position: absolute;
  left: 0;
  right: 0;
  margin-block-start: 1rem;
  margin-inline: auto;
  z-index: 2500;
  font-variation-settings: 'wght' 500;
}

.drag-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-variation-settings: 'wght' 500;
}
</style>
