﻿import { defineStore } from 'pinia'
import constants from '@/exports/constants'
import type { IRecordsState } from '@/models/stores/records'
import type {
  IRecord,
  IRecordPurchase,
  IRequestPageCountPayload
} from '@/models/interfaces'
import helpers from '@/exports/helper'
import httpClient from '@/httpClient'
import ErrorHelper from '@/exports/error'
import { useCommonStore } from './CommonStore'
import {
  CreateSubscriptionCheckoutSessionResult,
  PurchaseReportRequest,
  type ICreateCheckoutSessionRequest,
  type IReportInfoForPurchase,
  type IPdfRecordRequest,
  type RecordOrderItem,
  type IPaymentResult
} from '@/models/models'
import moment from 'moment'
import { useAccountSettingsStore } from './AccountSettingsStore'
import storageService from '@/services/storageService'

const initialState = (): IRecordsState => ({
  recordsType: 'pdf',
  pdfAccess: 0,
  recordsInfo: {
    signedOptionCost: 10.0,
    pdf24HourAccessCost: 49.99,
    pricePerPage: 0.19,
    printedRecordBaseCostFree: 119.99,
    printedRecordBaseCostStandard: 39.99,
    printedRecordBaseCostPremium: 29.99,
    emailOfUser: '',
    salesTaxFL: 0
  },
  taxCollected: 0,
  reportPageState: constants.reportPageState.list,
  shippingAddress: {
    shippingName: '',
    shippingAddress: '',
    shippingAddressLineTwo: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: ''
  },
  records: [
    {
      key: 'messaging',
      type: constants.reportType.Standard,
      title: 'Secure Messaging',
      icon: 'messages',
      description: {
        details: [
          {
            content:
              'Complete history and detail of all messages with your co-parent',
            tier: 0
          },
          {
            content:
              'The time each message is created and viewed by you or your co-parent',
            tier: 0
          },
          {
            content:
              'All file attachment details (excluding the attachments themselves)',
            tier: 0
          }
        ]
      },
      showIncludeTranscript: false,
      isSelectedPrintedRecords: false,
      isSelectedPdfRecords: false,
      signedOption: false,
      includeTranscripts: false,
      jobId: 0,
      pageCount: 0,
      printedCost: 0,
      orderNum: 1,
      isAvailable: true,
      unavailableMessage: '',
      reportBlobName: ''
    },
    {
      key: 'calendar',
      type: constants.reportType.Calendar,
      title: 'Shared Calendar',
      icon: 'calendar',
      description: {
        details: [
          {
            content:
              'Details of every new calendar event, edit, and deletion',
            tier: 0
          },
          {
            content:
              'Exact time each new or edited event is first viewed by your co-parent',
            tier: 0
          }
        ]
      },
      showIncludeTranscript: false,
      isSelectedPrintedRecords: false,
      isSelectedPdfRecords: false,
      signedOption: false,
      includeTranscripts: false,
      jobId: 0,
      pageCount: 0,
      printedCost: 0,
      orderNum: 2,
      isAvailable: true,
      unavailableMessage: '',
      reportBlobName: ''
    },
    {
      key: 'journal',
      type: constants.reportType.Journal,
      title: 'Personal Journal',
      icon: 'journal',
      description: {
        details: [
          {
            content:
              'Details of your Journal entries (does not include deleted entries)',
            tier: 0
          },
          {
            content: 'Any edits to existing entries',
            tier: 0
          }
        ]
      },
      showIncludeTranscript: false,
      isSelectedPrintedRecords: false,
      isSelectedPdfRecords: false,
      signedOption: false,
      includeTranscripts: false,
      jobId: 0,
      pageCount: 0,
      printedCost: 0,
      orderNum: 3,
      isAvailable: true,
      unavailableMessage: '',
      reportBlobName: ''
    },
    {
      key: 'calling',
      type: constants.reportType.Call,
      title: 'Accountable Calling',

        icon: 'video-calling',

      description: {
        details: [
          {
            content:
              'Details of all calls, including start time and duration',
            tier: 0
          },
          {
            content: 'Calling transcripts',
            tier: 2
          }
        ]
      },
      showIncludeTranscript: true,
      isSelectedPrintedRecords: false,
      isSelectedPdfRecords: false,
      signedOption: false,
      includeTranscripts: false,
      jobId: 0,
      pageCount: 0,
      printedCost: 0,
      orderNum: 4,
      isAvailable: true,
      unavailableMessage: '',
      reportBlobName: ''
    },
    {
      key: 'payments',
      type: constants.reportType.Payments,
      title: 'Accountable Payments',
      icon: 'payments',
      description: {
        details: [
          {
            content: 'Details of every payment to your co-parent',
            tier: 0
          },
          {
            content: 'Timestamp of when payments were sent or received',
            tier: 0
          },
          {
            content:
              'Requested payments and when they were seen, paid, or declined',
            tier: 0
          }
        ]
      },
      showIncludeTranscript: false,
      isSelectedPrintedRecords: false,
      isSelectedPdfRecords: false,
      signedOption: false,
      includeTranscripts: false,
      jobId: 0,
      pageCount: 0,
      printedCost: 0,
      orderNum: 5,
      isAvailable: true,
      unavailableMessage: '',
      reportBlobName: ''
    },
    {
      key: 'info-library',
      type: constants.reportType.InfoLibrary,
      title: 'Info Library',
        icon: 'infolibrary',

      description: {
        details: [
          {
            content:
              'Details of your private and shared cards, including creation, edit, and deletion',
            tier: 0
          },
          {
            content:
              'Exact time each new or edited shared card is first viewed by your co-parent',
            tier: 0
          }
        ]
      },
      showIncludeTranscript: false,
      isSelectedPrintedRecords: false,
      isSelectedPdfRecords: false,
      signedOption: false,
      includeTranscripts: false,
      jobId: 0,
      pageCount: 0,
      printedCost: 0,
      orderNum: 6,
      isAvailable: true,
      unavailableMessage: '',
      reportBlobName: ''
    }
  ],
  emailForReceipt: '',
  paymentResult: {} as unknown as IPaymentResult,
  orderSummaryActive: false,
  isEmailValid: false
})

const paths: string[] = []

export const useRecordsStore = defineStore('records', {
  state: initialState,
  getters: {
    getRecords: (state) => {
      return state.records.sort(
        (a: IRecord, b: IRecord) => a.orderNum - b.orderNum
      )
    },
    isPrintedRecords: (state) => {
      return state.recordsType == 'printed'
    },
    isPdfRecords: (state) => {
      return state.recordsType == 'pdf'
    },
    isWaitingForPrintedRecordCallBack: (state) => {
      return (
        state.records.filter(
          (rec) => rec.isSelectedPrintedRecords && rec.printedCost == 0
        ).length > 0
      )
    },
    selectedPrintedRecords: (state) => {
      return state.records
        .filter((rec) => rec.isSelectedPrintedRecords)
        .sort((a, b) => a.orderNum - b.orderNum)
    },
    selectedPdfRecords: (state) => {
      return state.records
        .filter((rec) => rec.isSelectedPdfRecords)
        .sort((a, b) => a.orderNum - b.orderNum)
    },
    getSelectedRecords(state): IRecord[] {
      return state.recordsType == 'printed'
        ? state.records
            .filter((rec) => rec.isSelectedPrintedRecords)
            .sort((a, b) => a.orderNum - b.orderNum)
        : state.records
            .filter((rec) => rec.isSelectedPdfRecords)
            .sort((a, b) => a.orderNum - b.orderNum)
    },
    getSelectedRecordsForOrder(state): RecordOrderItem[] {
      const _records: IRecord[] =
        state.recordsType == 'printed'
          ? state.records
              .filter((rec) => rec.isSelectedPrintedRecords)
              .sort((a, b) => a.orderNum - b.orderNum)
          : state.records
              .filter((rec) => rec.isSelectedPdfRecords)
              .sort((a, b) => a.orderNum - b.orderNum)
      return _records.map((r: IRecord) => {
        return {
          ict: r.includeTranscripts,
          iso: r.signedOption,
          type: r.type,
          JobId: r.jobId
        } as unknown as RecordOrderItem
      })
    },
    getTotal(state): number {
      if (
        this.getSelectedRecords == null ||
        this.getSelectedRecords.length == 0
      )
        return 0
      return this.getSelectedRecords.reduce(function (
        acc: number,
        rec: IRecord
      ) {
        return helpers.addMoney(
          acc,
          rec.printedCost +
            (rec?.signedOption || 0
              ? state.recordsInfo?.signedOptionCost || 0
              : 0)
        )


      }, 0)
    },

    getPdfAccessExpiresDate: (state) => {
      const commonStore = useCommonStore()
      if (state.paymentResult.paymentCreatedWhen) {
        return commonStore.formatDate(
          moment(state.paymentResult.paymentCreatedWhen).add(1, 'day')
        )
      } else {
        return ''
      }
    },
    getBaseCost: (state) => {
      const accountSettingsStore = useAccountSettingsStore()
      let costs = state.recordsInfo.printedRecordBaseCostFree
      if (accountSettingsStore.subscriptionT1) {
        costs = state.recordsInfo.printedRecordBaseCostStandard
      }
      if (accountSettingsStore.subscriptionT2) {
        costs = state.recordsInfo.printedRecordBaseCostPremium
      }
      return costs || 0
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof IRecordsState)[])
      )
    },
    calculateTaxAmount() {
      let _tax = 0
      if (this.shippingAddress?.shippingState == 'FL') {
        const preTax = Number(
          (
            Math.abs(this.getTotal * (this.recordsInfo?.salesTaxFL || 1)) * 100
          ).toPrecision(15)
        )
        _tax =
          (Math.round(preTax) / 100) *
          Math.sign(this.getTotal * (this.recordsInfo?.salesTaxFL || 1))
      }
      this.taxCollected = _tax
    },
    async requestPageCount(payload: IRequestPageCountPayload) {
      // call api to request purchase price of report
      // api will send notification when price has been calculated
      try {
        this.reportPageState = constants.reportPageState.calculating
        const url = `/web/api/Records/RequestPageCount?Signed=${payload.signed}&IncludeTranscripts=${payload.includeTranscripts}&ReportType=${payload.reportType}`
        const response = await httpClient.get(url)
        if (!response.data.success) {
          if (response.data.errorCode > 0) {
            const err = new Error(response.data.errorMessage)
            ErrorHelper.handleError(
              err,
              'requestPageCount',
              true,
              response.data.errorMessage
            )
          } else {
            throw new Error()
          }
        }
        return response.data
      } catch (e) {
        ErrorHelper.handleError(e, 'requestPageCount')
      }
    },
    async fetchRecordsInfo() {
      try {
        const url = '/web/api/Records/GetRecordsInfo'
        const response = await httpClient.get(url)
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.recordsInfo = response.data.value
        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchRecordsInfo')
      }
    },
    async fetchPdfAccess() {
      try {
        const url = '/web/api/Records/GetPdfAccess'
        const response = await httpClient.get(url)
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.pdfAccess = response.data.value
        return response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchRecordsInfo')
      }
    },
    removeRecord(record: IRecord) {
      const _record =
        this.recordsType == 'printed'
          ? {
              ...record,
              isSelectedPrintedRecords: false,
              signedOption: record.signedOption,
              jobId: 0,
              pageCount: 0,
              printedCost: 0,
              includeTranscripts: record.includeTranscripts
            }
          : { ...record, isSelectedPdfRecords: false, signedOption: false }
      this.updateRecord(_record)
    },
    resetRecords() {
      const _records = this.records.map((rec) => {
        return {
          ...rec,
          isSelectedPrintedRecords: false,
          isSelectedPdfRecords: false,
          signedOption: false,
          includeTranscripts: false,
          jobId: 0,
          pageCount: 0,
          printedCost: 0,
          reportBlobName: ''
        }
      })
      this.records = _records
    },
    async requestPrintedReportInfo(jobId: number) {
      // call api to the jobInfo
      const url = `/web/api/Records/GetReportJobInfo?JobID=${jobId}`
      try {
        const response = await httpClient.get(url)
        if (!response.data.success) {
          if (response.data.errorCode > 0) {
            const err = new Error(response.data.errorMessage)
            ErrorHelper.handleError(
              err,
              'requestPdfRecords',
              true,
              response.data.errorMessage
            )
            return false
          } else {
            throw new Error()
          }
        }

        const _jobInfo: IReportInfoForPurchase = response.data.value

        const _record = this.records.find((rec: IRecord) => rec.jobId == jobId)
        if (_record) {
          this.updateRecord({
            ..._record,
            pageCount: _jobInfo.pageCount ?? 0,
            printedCost: _jobInfo.printedCost ?? 0,
            reportBlobName: _jobInfo.blobName ?? '',
            fetchingPages: false
          })
        }
      } catch (e) {
        // 0 = generic server error
        ErrorHelper.handleError(e, 'requestPrintedReportInfo')
        return
      }
    },
    async createCheckoutSessionForPrintedRecords(
      payload: ICreateCheckoutSessionRequest
    ) {
      try {
        const response = await httpClient.post(
          '/web/api/payments/CreateCheckoutSessionForPrintedReports',
          payload
        )

        return CreateSubscriptionCheckoutSessionResult.fromJS(response.data)
      } catch (e) {
        ErrorHelper.handleError(e, 'createCustomerPortalSession')
      }
    },
    async createCheckoutSessionForPdfAccess(
      payload: ICreateCheckoutSessionRequest
    ) {
      try {
        const response = await httpClient.post(
          '/web/api/payments/CreateCheckoutSessionForPdfAccess',
          payload
        )
        return CreateSubscriptionCheckoutSessionResult.fromJS(response.data)
      } catch (e) {
        ErrorHelper.handleError(e, 'createCustomerPortalSession')
      }
    },
    async printSuccess(sessionId: string | number) {
      try {
        const response = await httpClient.get(
          '/web/api/payments/printSuccess?session_id=' + sessionId,
          {
            headers: {
              'api-version': '2'
            }
          }
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.paymentResult = response?.data?.paymentResult
      } catch (e) {
        ErrorHelper.handleError(e, 'smsSuccess')
      }
    },
    async pdfAccessSuccess(sessionId: string | number) {
      try {
        const response = await httpClient.get(
          '/web/api/payments/PdfAccessSuccess?session_id=' + sessionId,
          {
            headers: {
              'api-version': '2'
            }
          }
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.paymentResult = response?.data?.paymentResult
        return true
      } catch (e) {
        ErrorHelper.handleError(e, 'smsSuccess')
      }
    },
    async purchaseRecords(payload: PurchaseReportRequest) {
      const url = '/web/api/Records/PurchaseRecord'
      try {
        const result = await httpClient.post(url, payload)
        if (result.data.success) {
          this.resetRecords()
        } else {
          const common = useCommonStore()
          common.setSubmitErrorMethod({
            error: true,
            message: result.data.errorMessage
          })
        }
        return result.data
      } catch (e) {
        ErrorHelper.handleError(e, 'purchaseRecords')
        return 'error'
      }
    },
    async requestPdfRecords(payload: IPdfRecordRequest) {
      const url = '/web/api/Records/RequestPdfRecord'
      try {
        const result = await httpClient.post(url, payload)
        if (result.data.success) {
          this.paymentResult = {}
          return true
        } else {
          const err = new Error(result.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'requestPdfRecords',
            result.data.errorCode > 0,
            result.data.errorMessage
          )
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'requestPdfRecords')
        return 'error'
      }
    },
    updateRecord(record: IRecord) {
      const _records = [
        ...this.records.filter((rec) => rec.key !== record.key),
        record
      ]
      this.records = _records
    },
    setRecordType(type: string) {
      this.recordsType = type
    },
    setEmailForReceipt(email: string) {
      this.emailForReceipt = email
    },
    setRecordsType(value: string) {
      this.recordsType = value
    },
    getCCBrandClass(brand: string | undefined) {
      if (brand == undefined) return ''
      //Can be <c>amex</c>, <c>diners</c>, <c>discover</c>, <c>jcb</c>,
      /// <c>mastercard</c>, <c>unionpay</c>, <c>visa</c>, or <c>unknown</c>.
      switch (brand) {
        case 'visa':
          return 'fa-cc-visa'
        case 'mastercard':
          return 'fa-cc-mastercard'
        case 'amex':
          return 'fa-cc-amex'
        case 'discover':
          return 'fa-cc-discover'
        default:
          return ''
      }
    },
    setRecordsInState(value: IRecord[]) {
      this.records = value
    },
    setOrderSummaryActive(value: boolean) {
      this.orderSummaryActive = value
    },
    getRecordPurchase: () => {
      const _purchase = storageService.getFromLocalStorage('purchasedRecords')
      if (_purchase) {
        return JSON.parse(_purchase) as IRecordPurchase
      } else {
        return null
      }
    },
    getPageIcon(icon: string) {
      return `fak fa-${icon}`
    }
  }
})
