<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Transition>
    <div
      v-if="state.toast?.showToast"
      class="toast-container"
      :class="getClass()"
    >
      <button
        v-show="state.toast?.showCloseButton"
        class="btn circle"
        @click="close()"
      >
        <font-awesome-icon icon="fa-light fa-xmark" class="icon-size-1" />
      </button>
      <i
        v-if="state.toast.type == 'danger'"
        class="fa-light fa-circle-exclamation icon-size-1"
      ></i>
      <span
        class="font-size-1 font-weight-2"
        :class="{ 'mx-auto': state.toast.type == 'success' || state.toast.type == 'express' }"
        >
        {{ state.toast?.text }}
      </span>
      <font-awesome-icon
          v-if="showExpressPayIcon"
            icon="fa-regular fa-circle-bolt"
            class="icon-size-1 black"
        />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { reactive, watch, type PropType, toRef, onUnmounted, computed } from 'vue'
import type { IToast } from '@/models/interfaces'
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'

const commonStore = useCommonStore()
const { toastList } = storeToRefs(commonStore)
const { removeFirstToast } = commonStore

const state = reactive({
  toast: {
    showToast: false,
    type: 'success',
    text: '',
    showCloseButton: true
  } as IToast,
  toastTimer: null as unknown as ReturnType<typeof setTimeout>
})

const unsubscribe = commonStore.$onAction(
  ({
    name, // name of the action
    after // hook after the action returns or resolves
  }) => {
    if (name == 'setToast') {
      after(() => {
        // check if toast is currently being displayed
        if (!state.toast.showToast) {
          handleToast()
        }
      })
    }
  }
)


onUnmounted(() => {
  unsubscribe()
})

const classObject = reactive({
  'bg-lt-blue': state.toast?.type == 'success',
  'bg-yellow': state.toast?.type == 'warning',
  'toast-danger': state.toast?.type == 'danger'
})

function getClass() {
  switch (state.toast?.type) {
    case 'success':
      return 'bg-lt-blue'
    case 'warning':
      return 'bg-yellow'
    case 'danger':
      return 'toast-danger'
    default:
      return 'bg-lt-blue'
  }
}

function handleToast() {
  // set local toast to display
  state.toast = toastList.value[0]
  // remove toast from store
  removeFirstToast()
  // set timer for toast
  state.toastTimer = setTimeout(() => close(), 5000)
}

function close() {
  if (state.toastTimer) {
    clearTimeout(state.toastTimer)
  }
  state.toast = {
    showToast: false,
    text: '',
    type: 'success',
    showCloseButton: true
  }
  if (toastList.value.length > 0) {
    // wait a second for toast to be removed
    setTimeout(() => handleToast(), 1000)
  }
}

const showExpressPayIcon = computed(() => {
  return state.toast?.type == 'express'
})

</script>
<style lang="scss" scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}
/* Toast Notification */
.toast-container {
  position: fixed;
  margin-top: 0.5rem;
  
  margin-left: 1rem;
  margin-right: 1rem;
  margin-inline: 1rem;
  
  padding: 1rem;
  border-radius: var(--radius-4);
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: var(--size-1);
  z-index: 1800;
  width: calc(100% - 2rem);
  color: var(--gray-12);

  @media (min-width: 48em) {
    max-width: 39.375rem;
    margin-top: 1rem;
  }
}

.toast-danger {
  border: solid var(--magenta);
  border-radius: 25px;
  background-color: var(--white);
}
</style>
