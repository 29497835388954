<template>
  <div class="container" ref="circleDiv">
    <svg
      class="overlay"
      :width="state.size"
      :height="state.size"
      :viewBox="'0 0' + state.size + ' ' + state.size"
    >
      <circle
        :r="radius"
        :cx="state.size / 2"
        :cy="state.size / 2"
        fill="transparent"
        stroke="var(--text-0)"
        :stroke-width="strokeWidth + 'px'"
      ></circle>
      <circle
        :r="radius"
        :cx="state.size / 2"
        :cy="state.size / 2"
        fill="transparent"
        :stroke="color"
        :stroke-width="strokeWidth + 'px'"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        stroke-linecap="round"
      ></circle>
    </svg>
    <div class="content place-center">
      <slot>
        {{ percent }}
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { useElementSize, useParentElement } from '@vueuse/core'

const props = defineProps({
  percent: {
    type: Number,
    default: 75
  },
  color: {
    type: String,
    default: '#28b99a'
  }
})

const state = reactive({
  size: 0
})

const circleDiv = ref<HTMLElement>()
const parentEl = useParentElement(circleDiv)
const { width: parentElWidth, height: parentElHeight } =
  useElementSize(parentEl)

watch(
  () => [parentElWidth.value, parentElHeight.value],
  async ([widthVal, heightVal]) => {
    if (circleDiv.value) {
      if (widthVal && heightVal) {
        state.size = widthVal > heightVal ? widthVal : heightVal
      } else {
        state.size = !widthVal ? heightVal : widthVal
      }
    }
  }
)

const strokeWidth = computed(() => {
  return state.size * 0.06
})

const realSize = computed(() => {
  return state.size - strokeWidth.value
})

const radius = computed(() => {
  return realSize.value / 2
})

const circumference = computed(() => {
  return 2 * Math.PI * radius.value
})

const dashOffset = computed(() => {
  return circumference.value * ((100 - props.percent) / 100)
})
</script>
<style scoped lang="scss">
svg {
  transform: rotate(-90deg);
  position: relative;
  width: v-bind(realSize) !important;
  height: v-bind(realSize) !important;
}
.container {
  display: grid;
}

svg,
.content {
  grid-area: 1 / 1;
}
</style>
