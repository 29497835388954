export default {
  decodeJwt(token: string) {
    // resulting object {AID: "Web"
    // AppleExpDate: null
    // CaseID: null
    // MfaEnabled: true
    // OtherUserID: null
    // PremiumExpDate: null
    // Role: "Free"
    // UserID: 201027
    // exp: 1628167473
    // iat: 1627562673
    // [[Prototype]]: Object
    // }
    let _tokenObj = null
    try {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      _tokenObj = JSON.parse(jsonPayload)
    } catch (error) {
      return null
    }

    return _tokenObj
  }
}
