import type { ILocalStorage } from '@/models/interfaces'
import moment from 'moment'

const inMemoryStore: any = {}

export default {
  inMemoryStore,
  isSupported() {
    try {
      const testKey = '__some_random_key'
      localStorage.setItem(testKey, testKey)
      localStorage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  },
  getFromLocalStorage(payload: string) {
    // payload {key}
    // check sessionStorage first and if not found check localStorage
    // caller should parse value if object
    if (this.isSupported()) {
      const itemStr = sessionStorage.getItem(payload)
      if (itemStr) {
        return itemStr
      }
      // if the item doesn't exist, check localStorage
      if (!itemStr) {
        const itemObj = localStorage.getItem(payload)
        if (itemObj) {
          const item = JSON.parse(itemObj)
          // compare the expires time of the item with the current time
          if (
            (item.expires && moment().isAfter(moment(item.expires))) ||
            item.value == undefined
          ) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(payload)
            return null
          } else {
            return item.value
          }
        }
        return null
      }
    } else {
      //get from inMemory
      const hasOwnProperty = Object.prototype.hasOwnProperty
      if (hasOwnProperty.call(this.inMemoryStore, payload)) {
        return this.inMemoryStore[payload]
      }
      return null
    }
  },
  removeFromLocalStorage(key: string) {
    // payload {key}
    if (this.isSupported()) {
      sessionStorage.removeItem(key)
      localStorage.removeItem(key)
    } else {
      delete this.inMemoryStore[key]
    }
  },
  saveToLocalStorage(payload: ILocalStorage) {
    // payload {key, value, local: true, expires (YYYY-MM-DD hh:mm)}
    // value should be stringified if object
    // default expiration is 1 day
    // defaults to session storage
    if (this.isSupported()) {
      if (payload.local) {
        const item = {
          value:
            typeof payload.value == 'object'
              ? JSON.stringify(payload.value)
              : payload.value,
          expires: payload.expires ? payload.expires : moment().add(1, 'd')
        }
        localStorage.setItem(payload.key, JSON.stringify(item))
      } else {
        sessionStorage.setItem(
          payload.key,
          typeof payload.value == 'object'
            ? JSON.stringify(payload.value)
            : payload.value
        )
      }
    } else {
      this.inMemoryStore[payload.key] = String(payload.value)
    }
  }
}
