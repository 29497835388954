<template>
  <div v-if="state.modalActive">
    <teleport to="body">
      <transition name="modal-animation" appear>
        <div class="modal" :class="{ blur: blurBackground }">
          <!-- Slot to replace whole modal with a component -->
          <on-click-outside
            class="modal-inner-wrapper"
            @trigger="handleClickOutside"
          >
            <slot name="modal-replacement">
              <transition name="modal-animation-inner" appear>
                <div ref="modalRef" class="modal-inner">
                  <!-- Close Button -->
                  <button
                    v-if="showCloseButton"
                    class="btn circle dialog-close"
                    @click="cancel()"
                  >
                    <font-awesome-icon
                      icon="fa-light fa-xmark"
                      style="width: 20px; height: 20px"
                    />
                    <span class="sr-only">close</span>
                  </button>

                  <!-- Title -->
                  <div
                    v-if="showHeader"
                    class="dialog-header stack"
                    :class="{ 'remove-border': !showBorderHeader }"
                  >
                    <slot name="header">
                      <h2 class="font-size-2 ellipsis header-default">
                        {{ headerText }}
                      </h2>
                    </slot>
                  </div>

                  <!-- Content -->
                  <div
                    v-if="showBody"
                    class="dialog-content"
                    :class="{ 'dialog-body': addContentPadding }"
                  >
                    <slot name="content">
                      {{ contentText }}
                    </slot>
                  </div>

                  <!-- Footer -->
                  <footer
                    v-if="showFooter"
                    class="dialog-footer"
                    :class="footerStyle"
                  >
                    <slot name="footer">
                      <button
                        @click="confirm()"
                        type="button"
                        class="btn primary"
                        :disabled="anyLoading"
                      >
                        <loading :loading="footerButtonLoading ?? false" />
                        <strong>{{ footerButtonLabel }}</strong>
                      </button>
                      <button
                        v-if="showSecondaryCTA"
                        @click="emit('secondary')"
                        type="button"
                        class="btn secondary"
                        :disabled="anyLoading"
                      >
                        <loading :loading="secondaryButtonLoading ?? false" />
                        {{ secondaryCTALabel }}
                      </button>
                      <button
                        v-if="showTertiaryCTA"
                        @click="emit('tertiary')"
                        type="button"
                        class="btn tertiary brand"
                        :disabled="anyLoading"
                      >
                        <loading :loading="tertiaryButtonLoading ?? false" />
                        {{ tertiaryCTALabel }}
                      </button>
                    </slot>
                  </footer>
                </div>
              </transition>
            </slot>
          </on-click-outside>
        </div>
      </transition>
    </teleport>
  </div>
</template>
<script lang="ts" setup>
import { OnClickOutside } from '@vueuse/components'
import { onKeyStroke } from '@vueuse/core'
import { reactive, onUnmounted, ref, toRef, type PropType, watch, computed } from 'vue'
import Loading from '@/components/library/Loading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  headerText: {
    type: String,
    default: 'Placeholder'
  },
  contentText: {
    type: String,
    default: 'Placeholder'
  },
  footerButtonLabel: {
    type: String,
    default: 'Placeholder'
  },
  footerButtonLoading: {
    type: Object as PropType<boolean>
  },
  closeOnOutsideClick: {
    type: Boolean,
    default: true
  },
  closeOnConfirm: {
    type: Boolean,
    default: true
  },
  closeOnLoadEnd: {
    type: Boolean,
    default: false
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  showBody: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: true
  },
  blurBackground: {
    type: Boolean,
    default: false
  },
  showBorderHeader: {
    type: Boolean,
    default: true
  },
  addContentPadding: {
    type: Boolean,
    default: true
  },
  footerStyle: {
    type: String,
    default: 'gap-4'
  },
  showSecondaryCTA: {
    type: Boolean,
    default: false
  },
  secondaryCTALabel: {
    type: String,
    default: 'Placeholder'
  },
  secondaryButtonLoading: {
    type: Object as PropType<boolean>
  },
  showTertiaryCTA: {
    type: Boolean,
    default: false
  },
  tertiaryCTALabel: {
    type: String,
    default: 'Placeholder'
  },
  tertiaryButtonLoading: {
    type: Object as PropType<boolean>
  }
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'confirm'): void
  (e: 'secondary'): void
  (e: 'tertiary'): void
  (e: 'cancel'): void
}>()

const footerButtonLoading = toRef(props, 'footerButtonLoading')
const secondaryButtonLoading = toRef(props, 'secondaryButtonLoading')
const tertiaryButtonLoading = toRef(props, 'tertiaryButtonLoading')

const modalRef = ref(null)

const state = reactive({
  modalActive: true,
  loaded: false
})

watch([footerButtonLoading, secondaryButtonLoading, tertiaryButtonLoading], ([primaryLoading, secondaryLoading, tertiaryLoading]) => {
  if (props.closeOnLoadEnd && (primaryLoading || secondaryLoading || tertiaryLoading)) {
    state.loaded = true
  } else if (props.closeOnLoadEnd && state.loaded && !(primaryLoading || secondaryLoading || tertiaryLoading)) {
    close()
  }
})

function handleClickOutside() {
  if (props.closeOnOutsideClick) {
    close()
  }
}

onKeyStroke('Escape', (e) => {
  e.preventDefault()
  e.stopImmediatePropagation()

  if (props.closeOnOutsideClick) {
    close()
  }
})

function cancel() {
  emit('cancel')
  close()
}

function close() {
  state.modalActive = false
  emit('close')
}

function confirm() {
  emit('confirm')
  if (props.closeOnConfirm) {
    close()
  }
}

const anyLoading = computed(() => (
  (footerButtonLoading.value ?? false) || 
  (secondaryButtonLoading.value ?? false) || 
  (tertiaryButtonLoading.value ?? false)
))
</script>
<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgb(0 0 0 / 0.55);
  z-index: 2000;

  @media (min-width: 48em) {
    justify-content: center;
    align-items: center;
  }
}

.modal-inner {
  position: relative;
  box-shadow: var(--shadow-5);
  background-color: var(--surface-1);
  max-height: 100dvh;

  display: flex;
  flex-direction: column;
  
  @media (min-width: 48em) {
    max-width: 640px;
    width: 80%;
  }
}

.dialog-header {
  padding: var(--size-1) var(--size-1);
  word-wrap: anywhere;

  @media(width >= 48em){
    padding: var(--size-1) var(--size-3);
  }
}

.remove-border {
  border: none;
}

.dialog-footer {
  padding-top: var(--size-0);

  @media (min-width: 48em) {
    padding-top: 0;
  }

  strong {
    --base-weight: 500;
  }
}
.dialog-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  inset-block-start: 1.25rem;
  inset-inline-end: 1.25rem;

  svg {
    width: 1rem;
  }
}

.blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.modal-inner-wrapper {
  display: contents;
}

.header-default {
  font-variation-settings: 'wght' 500;
}

.dialog-content {
  flex-shrink: 1;
  flex-grow: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.btn.tertiary:disabled {
  opacity: .5;
  cursor: not-allowed;
}
</style>
