﻿<template>
  <div class="no-count-root" :class="rootClass">
    <div class="icon-wrapper" :class="iconWrapperClass">
      <FontAwesomeIcon :icon="iconString" class="icon" :class="iconClass" />
    </div>

    <span class="text" :class="textClass">{{ text }}</span>

    <slot name="footer" />
  </div>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { toRefs } from 'vue'

const props = defineProps<{
  iconString: string
  text: string
  rootClass?: string
  iconClass?: string
  iconWrapperClass?: string
  textClass?: string
}>()
const { iconString, text, rootClass, iconClass, iconWrapperClass, textClass } =
  toRefs(props)
</script>
<style scoped>
.no-count-root {
  display: flex;
  flex-direction: column;
  gap: var(--size-3);
  align-items: center;

  margin-block: auto;
}

.icon-wrapper {
  width: 100% !important;
  height: 100% !important;
  max-width: 11.9375rem;
  max-height: 11.9375rem;

  aspect-ratio: 1;

  background: var(--surface-3);
  border-radius: 50%;

  padding: 2.75rem;

  display: flex;
  align-items: stretch;

  @media (width >= 48em) {
    max-width: 19.4375rem;
    max-height: 19.4375rem;

    padding: 4.25rem;
  }
}

.icon {
  color: var(--text-2);

  flex: 1;
  width: unset !important;
  height: unset !important;
}

.text {
  font-size: var(--font-size-2);
  color: var(--text-0);
  font-variation-settings: 'wght' 500;
  text-align: center;
}

:deep(.btn.primary) {
  font-variation-settings: 'wght' 500;
}
</style>
