<template>
  <div>
    <div class="form-group">
      <label>Microphone</label>
      <select style="width: 100%" v-model="selectedInputDeviceId">
        <option
          v-for="mics in microphones"
          :key="mics.deviceId"
          :value="mics.deviceId"
        >
          {{ mics.label || t('calling.videoCall.unnamedDevice') }}
        </option>
      </select>
      <!-- audio level -->
      <div>
        <span class="sr-only">audio level: {{ audioLevel }}</span>
        <ul class="audio-level" :class="audioLevelClass">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <!-- /audio level -->
    </div>
    <div class="form-group">
      <label>Speakers</label>
      <select style="width: 100%" v-model="selectedOuputDeviceId">
        <option value="">
          {{ t('calling.videoCall.audioDevice.default') }}
        </option>
        <option
          v-for="speaker in speakers"
          :key="speaker.deviceId"
          :value="speaker.deviceId"
        >
          {{ speaker.label || t('calling.videoCall.unnamedDevice') }}
        </option>
      </select>
    </div>
    <!-- <div style="height: 100px; width: 100px">
        <svg
          focusable="false"
          viewBox="0 0 100 100"
          aria-hidden="true"
          height="100"
          width="100"
          style="margin: 10px 0"
        >
          <defs>
            <clipPath id="level-indicator">
              <rect x="0" y="100" width="100" height="100" />
            </clipPath>
          </defs>
          <path
            fill="rgb(220, 220, 220)"
            d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
          ></path>
          <path
            fill="rgb(220, 220, 220)"
            d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
          ></path>
          <path
            fill="#080"
            clip-path="url(#level-indicator)"
            d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
          ></path>
          <path
            fill="#080"
            clip-path="url(#level-indicator)"
            d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
          ></path>
        </svg>
    </div> -->

    <div class="action-buttons flex-column">
      <!-- <div>
        audio level: {{ audioLevel }}
        <ul class="audio-level" :class="audioLevelClass">
        <li></li>
        <li></li>  
        <li></li>  
        <li></li>  
        <li></li>  
        <li></li>  
        <li></li>  
        <li></li>  
        <li></li>  
        <li></li>    
      </ul>
      </div> -->

      <div class="btn-group">
        <button class="btn secondary" @click="cancel()">
          {{ t('calling.videoCall.audioDevice.cancel') }}
        </button>
        <button class="btn secondary" @click="testSpeaker()">
          {{ t('calling.videoCall.audioDevice.testSpeaker') }}
        </button>
        <button class="btn primary" @click="selectAudioSource()">
          {{ t('calling.videoCall.audioDevice.apply') }}
        </button>
      </div>
    </div>

    <div v-if="errorMsg.length > 0">
      {{ errorMsg }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { useDevicesList, watchIgnorable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { createLocalAudioTrack, LocalAudioTrack } from 'twilio-video'
import callHelpers from '../callingHelpers'
import { useCallingStore } from '@/stores/CallingStore'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

const { audioInputs: microphones, audioOutputs: speakers } = useDevicesList()
const callingStore = useCallingStore()
const { setMediatId } = callingStore
const { audioInputId, audioOutputId } = storeToRefs(callingStore)

let localAudioTrack: null | void | LocalAudioTrack = null
let localAudioOutput: HTMLAudioElement | null = null
const selectedInputDeviceId = ref<string>('')
const selectedOuputDeviceId = ref<string>('')
const errorMsg = ref<string>('')
const audioLevel = ref<number>(0)

const audioLevelClass = computed(() => {
  const classes = {}
  for (let i = 1; i <= 10; i++) {
    classes[`audio-level-${i}`] = audioLevel.value === i
  }
  return classes
})

const emit = defineEmits<{
  (e: 'updateTrack', newTrack: LocalAudioTrack | null): void
  (e: 'updateAudioOutput'): void
  (e: 'cancel'): void
}>()

onMounted(async () => {
  init()
})

const { ignoreUpdates: ignoreSelectIdUpdate } = watchIgnorable(
  selectedInputDeviceId,
  (newValue) => {
    applyInputDevice(newValue)
  }
)

async function init() {
  // console.log(navigator.mediaDevices.enumerateDevices())
  const _inputId =
    audioInputId.value.length > 0
      ? audioInputId.value
      : microphones.value[0].deviceId

  ignoreSelectIdUpdate(() => {
    selectedInputDeviceId.value = _inputId
  })

  applyInputDevice(_inputId)

  selectedOuputDeviceId.value =
    audioOutputId.value.length > 0
      ? audioOutputId.value
      : speakers.value[0].deviceId ?? ''
}

function selectAudioSource() {
  if (localAudioOutput) {
    localAudioOutput.pause()
  }

  // nothing changed
  if (
    audioInputId.value == selectedInputDeviceId.value &&
    audioOutputId.value == selectedOuputDeviceId.value &&
    selectedOuputDeviceId.value.length > 0
  ) {
    cancel()
    return
  }

  // output device changed
  // setting sink id to blank means default
  if (audioOutputId.value != selectedOuputDeviceId.value) {
    setMediatId('audiooutput', selectedOuputDeviceId.value)
    emit('updateAudioOutput')
  }

  // input device changed
  if (audioInputId.value != selectedInputDeviceId.value && localAudioTrack) {
    // console.log(
    // 'setting selectedInputDeviceId from modal: ' + selectedInputDeviceId.value
    // )

    setMediatId('audioinput', selectedInputDeviceId.value)
    emit('updateTrack', localAudioTrack)
  } else {
    if (localAudioTrack) {
      localAudioTrack.stop()
      localAudioTrack?.detach().forEach((element: HTMLMediaElement) => {
        element.srcObject = null
        element.remove()
      })
      localAudioTrack = null
    }

    cancel()
  }
}

function cancel() {
  if (localAudioTrack) {
    localAudioTrack.stop()
    localAudioTrack?.detach().forEach((element: HTMLMediaElement) => {
      element.srcObject = null
      element.remove()
    })
    localAudioTrack = null
  }

  if (localAudioOutput) {
    localAudioOutput.pause()
  }
  emit('cancel')
}

async function applyInputDevice(deviceId: string) {
  // Stop the previous LocalTrack, if present, and update the reactive reference
  if (localAudioTrack) {
    localAudioTrack.stop()
    localAudioTrack?.detach().forEach((element: HTMLMediaElement) => {
      element.srcObject = null
      element.remove()
    })
    localAudioTrack = null
  }

  localAudioTrack = await createLocalAudioTrack({
    noiseSuppression: false,
    echoCancellation: false,
    name: deviceId,
    deviceId: deviceId
  }).catch(handleAudioError)
  if (localAudioTrack) {
    callHelpers.pollAudioLevel(localAudioTrack, (level) => {
      audioLevel.value = level
    })
  }
}

function displayAudioLevel() {
  // Display the audio level.
  if (localAudioTrack) {
    callHelpers.pollAudioLevel(localAudioTrack, (level) => {
      audioLevel.value = level
    })
  }
}

async function testSpeaker() {
  // Step 0.5: Stop what's currently play
  if (localAudioOutput) {
    localAudioOutput.pause()
    localAudioOutput = null
  }
  // Step 1: Create an Oscillator
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()
  const oscillator = audioContext.createOscillator()
  oscillator.type = 'sine'
  oscillator.frequency.setValueAtTime(440, audioContext.currentTime) // A tone of 440 Hz

  // Route the oscillator through a MediaStream
  const dest = audioContext.createMediaStreamDestination()
  oscillator.connect(dest)
  oscillator.start(0) // Start immediately
  oscillator.stop(audioContext.currentTime + 1) // Stop after 1 second

  // Step 2: Use the MediaStream with an Audio Element
  localAudioOutput = new Audio()
  localAudioOutput.srcObject = dest.stream

  try {
    await localAudioOutput.setSinkId(selectedOuputDeviceId.value)
    console.log(
      `Audio output device successfully set to ${selectedOuputDeviceId.value}.`
    )

    // Play the sound to test the speaker
    localAudioOutput
      .play()
      .then(() =>
        console.log('Test sound is playing through the selected speaker.')
      )
      .catch((error) => console.error('Error playing test sound:', error))
  } catch (error) {
    console.error('Error setting speaker output device:', error)
  }
  // Creating an audio element to set the sinkId for testing the speaker
  // const audioElement = new Audio()
  // if (typeof audioElement.setSinkId === 'function') {
  //   try {
  //     await audioElement.setSinkId(selectedOuputDeviceId.value)
  //     console.log('Speaker output device set successfully.')
  //     // Play a test sound if needed. For the oscillator, we're directly using the audio context.
  //   } catch (error) {
  //     console.error('Error setting speaker output device:', error)
  //   }
  // } else {
  //   console.warn('setSinkId is not supported in this browser.')
  // }
}

function createAndPlaySound() {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()
  const oscillator = audioContext.createOscillator()
  oscillator.type = 'sine'
  oscillator.frequency.setValueAtTime(440, audioContext.currentTime) // A4
  oscillator.connect(audioContext.destination)
  oscillator.start()
  oscillator.stop(audioContext.currentTime + 1) // Stop after 1 second
}

function handleVideoError() {
  errorMsg.value =
    'There is an issue with your camera. Check to see if another program has access to your camera'
}
function handleAudioError() {
  errorMsg.value =
    'There is an issue with your mic. Check to see if another program has access to your microphone'
}
</script>
<style scoped>
.audio-level {
  display: flex;
  margin-bottom: 1rem;

  li {
    flex: 1;
    width: 2rem;
    height: 0.75rem;
    background-color: var(--surface-2);

    &:first-child {
      border-radius: 3rem 0 0 3rem;
    }
    &:last-child {
      border-radius: 0 3rem 3rem 0;
    }
  }
}

.audio-level-1 li:nth-child(-n + 1) {
  background-color: var(--brand);
}
.audio-level-2 li:nth-child(-n + 2) {
  background-color: var(--brand);
}
.audio-level-5 li:nth-child(-n + 3) {
  background-color: var(--brand);
}
.audio-level-4 li:nth-child(-n + 4) {
  background-color: var(--brand);
}
.audio-level-5 li:nth-child(-n + 5) {
  background-color: var(--brand);
}
.audio-level-6 li:nth-child(-n + 6) {
  background-color: var(--brand);
}
.audio-level-7 li:nth-child(-n + 7) {
  background-color: var(--brand);
}
.audio-level-8 li:nth-child(-n + 8) {
  background-color: var(--brand);
}
.audio-level-9 li:nth-child(-n + 9) {
  background-color: var(--brand);
}
.audio-level-10 li:nth-child(-n + 10) {
  background-color: var(--brand);
}

label {
  font-size: 1rem;
}
.action-buttons {
  display: flex;
  margin: 2rem auto 0;
  gap: 0.5rem;
  width: fit-content;
}
.btn-group {
  display: flex;
  gap: 0.75rem;
}
.form-group + .form-group {
  margin-top: 0.5rem;
}
</style>
