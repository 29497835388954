// WorkerService.ts
import type {
  WorkerRequest,
  WorkerResponse,
  WorkerCallback
} from '@/models/interfaces'

class WorkerService {
  private workers: Map<string, Worker> = new Map()

  createWorker(key: string, workerPath: string) {
    if (this.workers.has(key)) {
      console.warn(`Worker with key ${key} already exists. Replacing it.`)
      this.terminateWorker(key)
    }

    const worker = new Worker(new URL(workerPath, import.meta.url), {
      type: 'module'
    })
    this.workers.set(key, worker)
  }

  postRequest(key: string, request: WorkerRequest, callback: WorkerCallback) {
    const worker = this.workers.get(key)
    if (!worker) {
      throw new Error(`Worker with key ${key} not found.`)
    }

    worker.onmessage = (event: MessageEvent<WorkerResponse>) => {
      callback(event.data)
    }

    worker.onerror = (error: ErrorEvent) => {
      callback({ success: false, error: error.message })
    }

    worker.postMessage(request)
  }

  terminateWorker(key: string) {
    const worker = this.workers.get(key)
    if (worker) {
      worker.terminate()
      this.workers.delete(key)
    }
  }

  terminateAllWorkers() {
    this.workers.forEach((worker, key) => {
      worker.terminate()
      this.workers.delete(key)
    })
  }
}

export default new WorkerService()
