<template>
  <div
    ref="inputContainer"
    class="form-group w-100 flex-column"
    :class="{
      'has-error': !!fieldErrorMessage,
      success: meta.valid,
      disabled: disabled
    }"
  >
    <label :for="name" class="font-weight-2" :class="labelClassRef">
      <strong>{{ label }}</strong>
      <small
        v-if="hint"
        class="label-hint d-block font-weight-2"
        :class="hintClassRef"
      >
        <strong>{{ hint }}</strong>
      </small>
    </label>
    <div class="flex align-justify">
      <input
        ref="input"
        class="form-control"
        :class="inputClassRef"
        v-model="value"
        :name="name"
        :id="name"
        :type="state.formFieldType"
        :maxLength="maxLength"
        :aria-describedby="ariaDescribedby"
        :placeholder="placeholder"
        :pattern="pattern"
        v-on="validationListeners"
        v-maska
        :data-maska="props.mask"
        :disabled="disabled"
      />
      <button
        v-show="
          props.type == 'password' &&
          state.formFieldType == 'password' &&
          passwordVisibilityOption
        "
        type="button"
        class="btn tertiary showBtn brand"
        style="
          margin-left: -10rem;
          margin-right: 1rem;
          margin-inline: -10rem 1rem;
        "
        @click="toggleFieldType"
      >
        Show
      </button>
      <button
        v-show="
          props.type == 'password' &&
          state.formFieldType != 'password' &&
          passwordVisibilityOption
        "
        type="button"
        class="btn tertiary showBtn brand"
        style="
          margin-left: -10rem;
          margin-right: 1rem;
          margin-inline: -10rem 1rem;
        "
        @click="toggleFieldType"
      >
        Hide
      </button>
    </div>
    <span class="error-message" v-if="errors.length > 0">{{ errors[0] }}</span>
    <span class="success-message" v-if="successMessage && meta.valid">{{
      successMessage
    }}</span>
  </div>
</template>
<script lang="ts" setup>
import { useField } from 'vee-validate'
import { onMounted, reactive, ref, toRef, watch } from 'vue'
import { useFocus } from '@vueuse/core'
import EmailHelper from '@/exports/emailHelper'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { vMaska } from 'maska'

const props = defineProps({
  modelValue: {
    type: String
  },
  type: {
    type: String,
    default: 'text'
  },
  name: {
    type: String,
    required: true
  },
  required: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: true
  },
  successMessage: {
    type: String,
    default: ''
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  placeholder: {
    type: String,
    default: null
  },
  focus: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: 200
  },
  ariaDescribedby: {
    type: String,
    default: null
  },
  hint: {
    type: String,
    default: null
  },
  pattern: {
    type: String,
    default: null
  },
  inputClass: {
    type: String,
    default: null
  },
  regExForValidation: {
    type: RegExp,
    default: null
  },
  mask: {
    type: String,
    default: null
  },
  passwordVisibilityOption: {
    type: Boolean,
    default: false
  },
  labelClass: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  hintClass: {
    type: String,
    default: null
  }
})

const emit = defineEmits<{
  (e: 'errors', error: string): void
  (e: 'update:modelValue', val: any): void
  (e: 'fullNumber', val: string): void
}>()

const input = ref()
const { focused } = useFocus(input, { initialValue: props.focus })

const state = reactive({
  internalValue: null,
  iti: null as any,
  formFieldType: ''
})

watch(
  () => props.focus,
  (value) => {
    focused.value = value
  }
)

onMounted(() => {
  if (props.type == 'tel') {
    state.iti = intlTelInput(input.value, {
      onlyCountries: ['US', 'CA', 'AU', 'GB'],
      utilsScript: import.meta.env.VITE_APP_INTL_TEL_INPUT_UTILITIES_SCRIPT,
      separateDialCode: true
    })
  }
  state.formFieldType = props.type
})

// make sure to convert the name prop to a ref to maintain its reactivity
// this way vee-validate can react to the field name changing
const nameRef = toRef(props, 'name')
const modelValueRef = toRef(props, 'modelValue')
const inputClassRef = toRef(props, 'inputClass')
const labelClassRef = toRef(props, 'labelClass')
const hintClassRef = toRef(props, 'hintClass')
const {
  value,
  setErrors,
  errors,
  errorMessage: fieldErrorMessage,
  meta,
  handleBlur,
  handleChange
} = useField(nameRef, validateFormField, {
  initialValue: modelValueRef.value,
  checkedValue: modelValueRef,
  syncVModel: true,
  validateOnValueUpdate: false
})

async function validateFormField(value: any) {
  if (props.required) {
    if (!value?.trim()) {
      return props.errorMessage ? props.errorMessage : 'This field is required.'
    }
  }
  if (props.type == 'email') {
    if (value?.match(EmailHelper.getEmailRegex()) === null) {
      return 'Please provide a valid email.'
    }
  }
  if (props.type == 'tel') {
    if (!props.required && (!value || value.length == 0)) {
      return true
    }
    if (!state.iti.isValidNumber()) {
      return getTelErrorMessage(value ?? '')
    }
  }
  if (props.regExForValidation) {
    if (!value || value?.match(props.regExForValidation) === null) {
      return 'This value is invalid.'
    }
  }
  if (props.type == 'zip') {
    if (
      value === null ||
      value?.match(/^ *$/) !== null ||
      value?.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) === null
    ) {
      return 'Please provide a valid zip code.'
    }
  }
  return true
}

const validationListeners = {
  blur: (evt: Event | undefined) => handleBlur(evt, true),
  change: handleChange,
  input: (evt: Event | undefined) =>
    handleChange(evt, !!fieldErrorMessage.value)
}

// async function validateField(value: string) {
//   if (props.type == 'tel') {
//     if (!state.iti.isValidNumber()) {
//       return getTelErrorMessage()
//     }
//   }
//   if (props.required) {
//     if (!value) {
//       return 'This field is required'
//     }
//   }
//   return true
// }

watch(value, (newValue) => {
  if (props.type == 'tel') {
    if (!state.iti.isValidNumber()) {
      setTelErrorMessage(newValue ?? '')
    }
    emit('fullNumber', state.iti.getNumber())
  } else if (props.required) {
    if (!newValue) {
      emit('errors', 'This field is required.')
    }
  }
})

function setTelErrorMessage(value: string) {
  if (state.iti == null) return
  let errorMessageText = ''

  if (value.length)
    errorMessageText =
      'The number is shorter than all valid numbers for this region.'

  switch (state.iti.getValidationError()) {
    case 0:
      errorMessageText =
        'The number length matches that of valid numbers for this region.'
      break
    case 1:
      errorMessageText = 'The number has an invalid country calling code.'
      break
    case 2:
      errorMessageText =
        'The number is shorter than all valid numbers for this region.'
      break
    case 3:
      errorMessageText =
        'The number is longer than all valid numbers for this region.'
      break
    case 4:
      errorMessageText =
        'The number length matches that of local numbers for this region only.'
      break
    case 5:
      errorMessageText =
        'The number does not match the length for valid numbers for this region.'
      break
  }

  setErrors(errorMessageText)
  emit('errors', errorMessageText)
}

function getTelErrorMessage(value: string) {
  let errorMessageText = ''

  if (value.length)
    errorMessageText =
      'The number is shorter than all valid numbers for this region.'

  switch (state.iti.getValidationError()) {
    case 0:
      errorMessageText =
        'The number length matches that of valid numbers for this region.'
      break
    case 1:
      errorMessageText = 'The number has an invalid country calling code.'
      break
    case 2:
      errorMessageText =
        'The number is shorter than all valid numbers for this region.'
      break
    case 3:
      errorMessageText =
        'The number is longer than all valid numbers for this region.'
      break
    case 4:
      errorMessageText =
        'The number length matches that of local numbers for this region only.'
      break
    case 5:
      errorMessageText =
        'The number does not match the length for valid numbers for this region.'
      break
  }

  return errorMessageText.length ? errorMessageText : true
}

function toggleFieldType() {
  state.formFieldType = state.formFieldType == 'password' ? 'text' : 'password'
}

const inputContainer = ref()

function scrollToComponent() {
  inputContainer.value.scrollIntoView()
}

defineExpose({ scrollToComponent })
</script>
<style lang="scss" scoped>
.showBtn {
  font-size: var(--font-size-00) !important;
  font-weight: var(--font-weigth-1) !important;
}
.disabled {
  opacity: 0.5;
}
.label-hint {
  color: var(--gray-5);
  margin-top: 0.5rem;
  font-size: var(--font-size-0);
  &:empty {
    display: none;
  }
}

input {
  height: 3.125rem;
}

input:-moz-placeholder {
  color: var(--gray-5);
  opacity: 1;
}

input::-moz-placeholder {
  color: var(--gray-5);
  opacity: 1;
}
input:-ms-input-placeholder {
  color: var(--gray-5);
  opacity: 1;
}
input::-webkit-input-placeholder {
  color: var(--gray-5);
  opacity: 1;
}
input::placeholder {
  color: var(--gray-5);
  opacity: 1;
}

.has-error div input {
  border-color: var(--danger);
  border-width: 1px;
  box-shadow: 0px 0px 0px 2px var(--danger);
}

:deep(.iti__selected-flag) {
  background: transparent;
  border-right: 1px solid var(--gray-4);
  border-top-left-radius: var(--radius-3);
  border-bottom-left-radius: var(--radius-3);
}

:deep(.iti__flag-container) {
  border-top-left-radius: var(--radius-3);
  border-bottom-left-radius: var(--radius-3);
}

:deep(.iti__selected-dial-code) {
  color: var(--gray-5);
}

:deep(.iti__country-name) {
  color: var(--black);
}

:deep(.iti--allow-dropdown input) {
  padding-left: 80px !important;
}

strong {
  --base-weight: 400;
}
</style>
