<template>
  <div class="flex gap-2 align-middle align-justify">
    <!-- text and icon -->
    <div class="download-item">
      <div class="flex gap-2" :class="{ 'in-progress': inProgress }">
        <font-awesome-icon
          :icon="getIconString(download.mimeType)"
          class="icon-size-3"
        />
        <span
          class="ellipsis font-size-0 font-weight-2"
          :class="{ 'line-through': download.status == DownloadStatus.Stopped }"
        >
          <strong>{{ download.fileName }}</strong>
        </span>
      </div>
      <span
        v-if="download.status == DownloadStatus.Failed"
        class="font-size-000 font-weight-0 error ml-3 download-error"
      >
        {{ t('downloads.list.item.issue') }}
      </span>
    </div>
    <!-- buttons -->
    <div class="flex gap-2 buttons">
      <button
        v-if="download.status == DownloadStatus.Completed"
        type="button"
        class="btn tertiary"
        @click="startReDownload()"
        @mouseover="state.showReDownload = true"
        @mouseleave="state.showReDownload = false"
      >
        <font-awesome-icon
          :icon="
            state.showReDownload
              ? 'fa-light fa-download'
              : 'fa-light fa-circle-check'
          "
          class="icon-size-3"
        />
        <span class="sr-only">{{ t('downloads.list.item.completed') }}</span>
      </button>
      <button
        v-if="canReDownload"
        type="button"
        class="btn tertiary"
        @click="startReDownload"
      >
        <font-awesome-icon
          icon="fa-light fa-download"
          class="ml-00 icon-size-3"
        />
        <span class="sr-only">{{ t('downloads.list.item.reDownload') }}</span>
      </button>
      <font-awesome-icon
        v-if="itemFailed"
        icon="fa-light fa-triangle-exclamation"
        class="ml-00 icon-size-3"
      />
      <button
        v-if="canBeStopped"
        type="button"
        class="btn tertiary place-center"
        @click="stop()"
      >
        <ProgressCircle :percent="getPercent" color="#8dd6e9">
          <font-awesome-icon
            icon="fa-regular fa-xmark"
            class="icon-size-1 cancel"
          />
        </ProgressCircle>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { IDownload } from '@/models/interfaces'
import { computed, reactive, toRef } from 'vue'
import ProgressCircle from '@/components/library/ProgressCircle.vue'
import { useI18n } from 'vue-i18n'
import { DownloadStatus } from '@/models/enums'
import { useCommonStore } from '@/stores/CommonStore'
const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { stopDownload, removeDownloadFromList, startDownload, reDownload } =
  commonStore

const props = defineProps<{
  download: IDownload
}>()
const download = toRef(props, 'download')

const state = reactive({
  showReDownload: false
})

function stop() {
  stopDownload(download.value.id)
}

async function startReDownload() {
  if (download.value.status == DownloadStatus.Failed) {
    // call webworker to download in background
    startDownload(download.value.url, download.value.fileName)
    // remove current
    removeDownloadFromList(download.value.id)
    return
  }

  // if stopped or completed -- dont grab the url again
  reDownload(download.value)
}

function getIconString(type?: string) {
  const faPrefix = 'fa-light'
  if (type?.split('/')[0] == 'image') return `${faPrefix} fa-image`
  if (type?.split('/')[0] == 'video') return `${faPrefix} fa-video`
  return `${faPrefix} fa-file`
}

const getPercent = computed(() => {
  return download.value.progress
})

const inProgress = computed(() => {
  return (
    download.value.status == DownloadStatus.Downloading ||
    download.value.status == DownloadStatus.Failed ||
    download.value.status == DownloadStatus.Stopped
  )
})

const itemFailed = computed(() => {
  return download.value.status == DownloadStatus.Failed
})

const canBeStopped = computed(() => {
  return download.value.status == DownloadStatus.Downloading
})

const canReDownload = computed(() => {
  return (
    download.value.status == DownloadStatus.Failed ||
    download.value.status == DownloadStatus.Stopped
  )
})
</script>
<style scoped lang="scss">
.download-item {
  min-width: 0;
}

.download-error {
  position: absolute;
}

.in-progress {
  opacity: 0.75;
}
.buttons {
  flex-shrink: 0;
}
.cancel {
  padding: 5px;
}

strong {
  --base-weight: 500;
}
</style>
