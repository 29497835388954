import { defineStore } from 'pinia'

import httpClient from '@/httpClient'
import httpClientAnonymous from '@/httpClient'
import helpers from '../exports/helper'
import ErrorHelper from '../exports/error'
import type { ISignupState } from '@/models/stores/signup'
import {
  ValidateEmailMatchResult,
  type IValidateEmailMatchRequest,
  type ICreatePendingSignupRequest,
  type IVerifyPendingSignupRequest,
  type IResendVerifyCodeRequest,
  type ICreateAccountRequest,
  CreateAccountResult,
  type IValidateMatchCodeRequest,
  ValidateMatchCodeResult,
  UpdateMatchInformationResult,
  type IUpdateMatchInformationRequest,
  EnableMfaSmsResult,
  type IEnableMfaSmsRequest,
  DisableMfaSmsResult,
  DeleteUnmatchedAccountResult,
  type IUpdateInformationRequest,
  type IUpdateCoparentInformationRequest,
  type IUpdateChildInformationRequest,
  type ISendMatchCodeRequest,
  SendMatchCodeResult,
  type ITimeZone,
  ResendVerifyCodeResult,
  VerifyPendingSignupResult,
  CreatePendingSignupResult
} from '@/models/models'

import type {
  ICreateAccountResponse,
  ICreatePendingSignupResponse,
  IResendVerifyCodeResponse,
  IVerifyPendingSignupResponse,
  IValidateMatchCodeResponse,
  IValidateEmailMatchResponse,
  IUpdateMatchInformationResponse,
  IEnableMfaSmsResponse,
  IDisableMfaSmsResponse,
  IDeleteUnmatchedAccountResponse,
  ISendMatchCodeResponse
} from '@/models/interfaces'

import { useLoginStore } from './LoginStore'

const initialState = (): ISignupState => ({
  signupGuid: null,
  verifyCode: null,
  matchCode: null,
  unmatchedAccountInfo: {},
  signupEmail: '',

  //security
  enableMfaSmsErrorMessage: null,
  showConfirmMfaSms: false,
  confirmEnableMfaSmsErrorMessage: null,
  showInviteCoparentWithMatchIdModal: false,
  inviteCoparentWithMatchIdYes: false,
  showInviteCoparentWithEmailModal: false,
  inviteCoparentWithEmailYes: false,
  showSendNotificationToCoparentModal: false,
  showMatchSuccess: false,
  matchedCoparentName: '',
  timeZones: [],
  mfaSmsPhoneNumberResult: null,
  showVerifyPhone: false,
  showVerifyEmail: false,
  verifyPhoneErrorMessage: null,
  showMatchSummary: false,
  showEditChildInfoPage: false,
  showEditCoparentInfoPage: false,
  showConfirmDeleteAccountModal: false
})

const paths: string[] = ['signupGuid', 'verifyCode', 'signupEmail']

export const useSignupStore = defineStore('signup', {
  state: initialState,
  persist: {
    paths: paths
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof ISignupState)[])
      )
    },
    async setSignupGuid(payload: string) {
      this.signupGuid = payload
    },
    async setSignupEmail(payload: string) {
      this.signupEmail = payload
    },
    async setVerifyCode(payload: string) {
      this.verifyCode = payload
    },
    async setMatchCode(payload: string) {
      this.matchCode = payload
    },
    async setShowVerifyEmail(payload: boolean) {
      this.showVerifyEmail = payload
    },
    async setShowInviteCoparentWithMatchIdModal(payload: boolean) {
      this.showInviteCoparentWithMatchIdModal = payload
    },
    async setInviteCoparentWithMatchIdYes(payload: boolean) {
      this.inviteCoparentWithMatchIdYes = payload
    },
    async setShowInviteCoparentWithEmailModal(payload: boolean) {
      this.showInviteCoparentWithEmailModal = payload
    },
    async setInviteCoparentWithEmailYes(payload: boolean) {
      this.inviteCoparentWithEmailYes = payload
    },
    async setShowMatchSuccess(payload: boolean) {
      this.showMatchSuccess = payload
    },
    async setShowConfirmMfaSms(payload: boolean) {
      this.showConfirmMfaSms = payload
    },
    async setShowSendNotificationToCoparentModal(payload: boolean) {
      this.showSendNotificationToCoparentModal = payload
    },
    async setMatchedCoparentName(payload: string) {
      this.matchedCoparentName = payload
    },
    async setShowMatchSummary(payload: boolean) {
      this.showMatchSummary = payload
    },
    async setShowEditChildInfoPage(payload: boolean) {
      this.showEditChildInfoPage = payload
    },
    async setShowEditCoparentInfoPage(payload: boolean) {
      this.showEditCoparentInfoPage = payload
    },    
    async setShowConfirmDeleteAccountModal(payload: boolean) {
      this.showConfirmDeleteAccountModal = payload
    },
    async fetchUnmatchedAccountInfo() {
      try {
        const response = await httpClient.get(
          '/web/api/Signup/GetUnmatchedAccountInfo'
        )

        if (!response?.data?.success)
          throw new Error(response.data.errorMessage)

        this.unmatchedAccountInfo = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchUnmatchedAccountInfo')
      }
    },
    async validateMatchCode(
      payload: IValidateMatchCodeRequest
    ): Promise<IValidateMatchCodeResponse> {
      let response: IValidateMatchCodeResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new ValidateMatchCodeResult()
      }

      try {
        response = (await httpClient.post('/web/api/Signup/ValidateMatchCode', payload)).data
        
        if (!response.success && response.errorCode == 0) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'createAccount')
      }
      return response
    },
    async validateEmailMatch(
      payload: IValidateEmailMatchRequest
    ): Promise<IValidateEmailMatchResponse> {
      let response: IValidateEmailMatchResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new ValidateEmailMatchResult()
      }

      try {
        response = (await httpClient.post('/web/api/Signup/ValidateEmailMatch', payload)).data
        
        if (!response.success && response.errorCode == 0) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'validateEmailMatch')
      }
      return response
    },
    async fetchTimeZones() {
      if (!this.timeZones) return

      try {
        const response = await httpClientAnonymous.get(
          '/web/api/MyAccount/GetTimeZones'
        )

        if (!response?.data?.success && response?.data?.errorCode == 0) {
          throw new Error(response.data.errorMessage)
        } else {
          this.timeZones = response.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTimeZones')
      }
    },
    async createPendingSignup(
      payload: ICreatePendingSignupRequest
    ): Promise<ICreatePendingSignupResponse> {
      let response: ICreatePendingSignupResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new CreatePendingSignupResult()
      }

      try {
        response = 
          (
            await httpClientAnonymous.post(
              '/web/api/Signup/CreatePendingSignup',
              payload
            )
          ).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'createPendingSignup')
      }
      return response
    },
    async verifyPendingSignup(
      payload: IVerifyPendingSignupRequest
    ): Promise<IVerifyPendingSignupResponse> {
      let response: IVerifyPendingSignupResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new VerifyPendingSignupResult()
      }

      try {
        response = 
          (
            await httpClientAnonymous.post(
              '/web/api/Signup/VerifyPendingSignUp',
              payload
            )
          ).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'verifyPendingSignup')
      }
      return response
    },
    async resendVerifyCode(
      payload: IResendVerifyCodeRequest
    ): Promise<IResendVerifyCodeResponse> {
      let response: IResendVerifyCodeResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new ResendVerifyCodeResult()
      }

      try {
        response = 
          (
            await httpClientAnonymous.post(
              '/web/api/Signup/ResendVerifyCode',
              payload
            )
          ).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'resendVerifyCode')
      }
      return response
    },
    async createAccount(
      payload: ICreateAccountRequest
    ): Promise<ICreateAccountResponse> {
      let response: ICreateAccountResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new CreateAccountResult()
      }

      try {
        response = (await httpClientAnonymous.post('/web/api/Signup/CreateAccount', payload)).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'createAccount')
      }
      return response
    },
    async updateMatchInformation(
      payload: IUpdateMatchInformationRequest
    ): Promise<IUpdateMatchInformationResponse> {
      let response: IUpdateMatchInformationResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new UpdateMatchInformationResult()
      }

      try {
        response = (await httpClient.post('/web/api/Signup/UpdateMatchInformation', payload)).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'updateMatchInformation')
      }
      return response
    },
    async enableMfaSms(
      payload: IEnableMfaSmsRequest
    ): Promise<IEnableMfaSmsResponse> {
      let response: IEnableMfaSmsResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new EnableMfaSmsResult()
      }

      try {
        response = 
          (await httpClient.post('/web/api/MyAccount/EnableMfaSms', payload)).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'enableMfaSms')
      }

      return response
    },
    async disableMfaSms(): Promise<boolean> {
      let response: DisableMfaSmsResult

      try {
        response = 
          (await httpClient.post('/web/api/MyAccount/DisableMfaSms')).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        } else {
          const login = useLoginStore()
          await login.setAuthTokenMethod(response.newToken || '')
          await this.fetchUnmatchedAccountInfo()
        }
        return response.success ?? false
      } catch (e) {
        ErrorHelper.handleError(e, 'disableMfaSms')
        return false
      }
    },
    async deleteUnmatchedAccount(): Promise<IDeleteUnmatchedAccountResponse> {
      let response: IDeleteUnmatchedAccountResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new DeleteUnmatchedAccountResult()
      }

      try {
        response = 
          (await httpClient.post('/web/api/Signup/DeleteUnmatchedAccount')).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'deleteUnmatchedAccount')
      }
      return response
    },
    async updateInformation(
      payload: IUpdateInformationRequest
    ): Promise<IUpdateMatchInformationResponse> {
      let response: IUpdateMatchInformationResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new UpdateMatchInformationResult()
      }

      try {
        response = 
          (await httpClient.post('/web/api/Signup/UpdateInformation', payload)).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'updateInformation')
      }
      return response
    },
    async updateCoparentInformation(
      payload: IUpdateCoparentInformationRequest
    ): Promise<IUpdateMatchInformationResponse> {
      let response: IUpdateMatchInformationResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new UpdateMatchInformationResult()
      }

      try {
        response = 
          (
            await httpClient.post(
              '/web/api/Signup/UpdateCoparentInformation',
              payload
            )
          ).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'updateCoparentInformation')
      }
      return response
    },
    async updateChildInformation(
      payload: IUpdateChildInformationRequest
    ): Promise<IUpdateMatchInformationResponse> {
      let response: IUpdateMatchInformationResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new UpdateMatchInformationResult()
      }

      try {
        response = 
          (await httpClient.post('/web/api/Signup/UpdateChildInformation', payload))
            .data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'updateChildInformation')
      }
      return response
    },
    async sendMatchCode(
      payload: ISendMatchCodeRequest
    ): Promise<ISendMatchCodeResponse> {
      let response: ISendMatchCodeResponse = {
        success: false,
        errorCode: null,
        errorMessage: null,
        value: new SendMatchCodeResult()
      }

      try {
        response = 
          (await httpClient.post('/web/api/Signup/SendMatchCode', payload)).data
        

        if (!response.success) {
          throw new Error(response.errorMessage || '')
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'sendMatchCode')
      }
      return response
    },
    getTimeZoneDictionary(): Map<string, ITimeZone> {
      const timeZoneMap = new Map<string, ITimeZone>()

      this.timeZones.forEach((t) => timeZoneMap.set(t.displayName || '', t))
      return timeZoneMap
    },
    getTimeZoneFromDisplayName(displayName: string): ITimeZone {
      return (
        this.timeZones.find((t) => t.displayName == displayName) ||
        this.timeZones[0]
      )
    }
  }
})
