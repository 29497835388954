<template>
  <div>
    <ul
      v-if="currentItemsCount != 0"
      class="hidden-sm-down conversation-header clearfix"
    >
      <li
        v-for="(header, index) in tableData.headerItems"
        :key="index"
        :class="header.headerClass"
      >
        {{ header.displayName }}
      </li>
    </ul>
    <div :class="tableContainerDivClass">
      <ol
        id="tableBody"
        class="col-sm-12 table"
        :class="[tableClass, { 'table-ram-grid': ramGridMin }]"
        :style="{
          'grid-template-columns': `repeat(auto-fill, minmax(${ramGridMin}, 1fr))`
        }"
      >
        <li
          v-for="(item, index) in itemsTop"
          :key="item"
          class="row"
          :class="[
            paddingClass,
            { 'row-hover': hasHoverStyling },
            { 'remove-padding': !hasPadding }
          ]"
          :style="[
            {
              'border-bottom': hasDividers ? '1px solid var(--surface-4)' : '0'
            },
            { width: columnWidthPercentage }
          ]"
          @click="onRowClick(item, index)"
          @dblclick.left="onRowDoubleClick(item, index)"
          @touchend="onRowTouchEnd"
          @click.right.prevent="onRowRightClick(item, index)"
          :id="`infinite-scroll-table-item-${index.toString()}`"
          ref="itemEls"
        >
          <on-long-press @trigger.prevent.stop="setLongPress(item, index)">
            <slot name="item" :item="item" />
          </on-long-press>
        </li>

        <li v-if="displayAdSense" class="row ad-container">
          <ad-sense-component
            :key="isMobileWidth ? 'mobileKey' : 'desktopKey'"
            :slotcode="getSlotCode"
            :format="isMobileWidth ? 'auto' : 'horizontal'"
            :adtest="isDev ? 'on' : 'off'"
          />
        </li>

        <li
          v-for="(item, index) in itemsBottom"
          :key="item"
          class="row"
          :class="[
            paddingClass,
            { 'row-hover': hasHoverStyling },
            { 'remove-padding': !hasPadding }
          ]"
          :style="[
            {
              'border-bottom': hasDividers ? '1px solid var(--surface-4)' : '0'
            },
            { width: columnWidthPercentage }
          ]"
          @click="onRowClick(item, index + getSplitNumber)"
          @dblclick.left="onRowDoubleClick(item, index + getSplitNumber)"
          @touchend="onRowTouchEnd"
          @click.right.prevent="onRowRightClick(item, index + getSplitNumber)"
          :id="`infinite-scroll-table-item-${(index + getSplitNumber).toString()}`"
          ref="itemEls"
        >
          <on-long-press
            @trigger.prevent.stop="setLongPress(item, index + getSplitNumber)"
          >
            <slot name="item" :item="item" />
          </on-long-press>
        </li>
        <li
          v-if="loadMoreItemsRef"
          v-observe-visibility="viewHandler"
          class="load-more-threshold"
        ></li>
        <li
          v-for="item in itemsExtra"
          :key="item"
          class="row"
          :class="[
            paddingClass,
            { 'row-hover': hasHoverStyling },
            { 'remove-padding': !hasPadding }
          ]"
          :style="[
            {
              'border-bottom': hasDividers ? '1px solid var(--surface-4)' : '0'
            },
            { width: columnWidthPercentage }
          ]"
          @click="viewHandlerClick"
        >
          <slot name="item" :item="item" />
        </li>
      </ol>
      <!-- <div
        v-if="loadMoreItems"
        v-observe-visibility="viewHandler"
        class="load-more font-weight-2"
        @click="viewHandlerClick"
      >
        <li v-for="item in itemsExtra" :key="item.toString()" class="row">
          <slot name="item" :item="item" />
        </li>
      </div> -->
      <div
        v-if="!loadMoreItemsRef && showTotal"
        class="load-more font-weight-2"
      >
        Total: {{ count }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRef, onMounted, nextTick, reactive } from 'vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import AdSenseComponent from '@/components/AdsenseComponent.vue'
import { OnLongPress } from '@vueuse/components'

const commonStore = useCommonStore()
const { isMobileWidth, isDev } = storeToRefs(commonStore)

const accountSettingsStore = useAccountSettingsStore()
const { subscriptionT0 } = storeToRefs(accountSettingsStore)

const props = withDefaults(
  defineProps<{
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData?: any
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[]
    count: number
    page: number
    rowClick?: string
    tableClass?: string
    tableContainerDivClass?: string
    includeAdsense?: boolean
    adsenseSlotCodeDesktopKey?: string
    adsenseSlotCodeMobileKey?: string
    loadMoreItems?: boolean
    hasPadding?: boolean
    paddingClass?: string
    hasDividers?: boolean
    numOfExtraItems?: number
    showTotal?: boolean
    numOfColumns?: number
    ramGridMin?: string
    hasHoverStyling?: boolean
  }>(),
  {
    tableData: () => {
      return {
        displayName: '',
        propertyName: '',
        headerClass: '',
        itemClass: '',
        rowClick: ''
      }
    },
    items: () => [],
    count: 0,
    page: 1,
    includeAdsense: false,
    adsenseSlotCodeDesktopKey: import.meta.env
      .VITE_APP_ADSENSE_SLOTCODE_MESSAGES_DESKTOP,
    adsenseSlotCodeMobileKey: import.meta.env
      .VITE_APP_ADSENSE_SLOTCODE_MESSAGES_MOBILE,
    loadMoreItems: false,
    hasPadding: true,
    hasDividers: true,
    numOfExtraItems: 7,
    showTotal: true,
    numOfColumns: 1,
    hasHoverStyling: true
  }
)

interface IInfiniteScrollTableState {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  longPressItem: any | null
  longPressIndex: number | null
}

const state = reactive<IInfiniteScrollTableState>({
  //specifically for long press event; event gets fired, item gets set
  //only emit onRowLongPress once mouseup is fired
  longPressItem: null,
  longPressIndex: null
})

//reactivity
const tableData = toRef(props, 'tableData')
const items = toRef(props, 'items')
const count = toRef(props, 'count')
//const page = toRef(props, 'page')
//const rowClick = toRef(props, 'rowClick')
const tableClass = toRef(props, 'tableClass')
const tableContainerDivClass = toRef(props, 'tableContainerDivClass')
const includeAdsense = toRef(props, 'includeAdsense')
const adsenseSlotCodeDesktopKey = toRef(props, 'adsenseSlotCodeDesktopKey')
const adsenseSlotCodeMobileKey = toRef(props, 'adsenseSlotCodeMobileKey')
const paddingClass = toRef(props, 'paddingClass')
const hasDividers = toRef(props, 'hasDividers')
const numOfColumns = toRef(props, 'numOfColumns')
const ramGridMin = toRef(props, 'ramGridMin')
const hasHoverStyling = toRef(props, 'hasHoverStyling')
const hasPadding = toRef(props, 'hasPadding')

const loadMoreItemsRef = toRef(props, 'loadMoreItems')
const showTotal = toRef(props, 'showTotal')

const emit = defineEmits<{
  (e: 'load-more'): void
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'row-click', item: any, index: number): void
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'row-double-click', item: any, index: number): void
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'row-right-click', item: any, index: number): void
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'row-long-press', item: any, index: number): void
}>()

const hoverBackground = computed(() => (hasHoverStyling.value ? '#f9f9ff' : ''))

const getSlotCode = computed(() => {
  return isMobileWidth.value
    ? adsenseSlotCodeMobileKey.value ||
        import.meta.env.VITE_APP_ADSENSE_SLOTCODE_MESSAGES_DESKTOP
    : adsenseSlotCodeDesktopKey.value ||
        import.meta.env.VITE_APP_ADSENSE_SLOTCODE_MESSAGES_MOBILE
})

const displayAdSense = computed(() => {
  return includeAdsense.value ? subscriptionT0.value : false
})

const currentItemsCount = computed(() => {
  return items.value.length
})

const itemsTop = computed(() => {
  if (currentItemsCount.value > getSplitNumber.value) {
    return items.value.slice(0, getSplitNumber.value)
  } else {
    return items.value
  }
})

const isLocalhost = computed(() => {
  const url = window.location.href
  return url.includes('localhost') ? 'on' : 'off'
})

const itemsBottom = computed(() => {
  if (currentItemsCount.value > getSplitNumber.value) {
    return items.value.slice(getSplitNumber.value, currentItemsCount.value + 1)
  } else {
    return []
  }
})

const itemsExtra = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extra = [] as any[]

  if (loadMoreItemsRef.value) {
    for (let i = 0; i < props.numOfExtraItems; ++i) {
      const extraItem = JSON.parse(JSON.stringify(items.value[0]))
      extraItem.skeletonLoading = true
      extra.push(extraItem)
    }
  }

  return extra
})

const getSplitNumber = computed(() => {
  return isMobileWidth.value ? 1 : 2
})

const styleOverrideString = computed(() => {
  return `${props.hasPadding ? '' : 'padding: 0;'}
  ${props.hasDividers ? '' : 'border-bottom: 0;'}`
})

const columnWidthPercentage = computed(
  () => `${(1 / numOfColumns.value) * 100}%`
)

onMounted(() => {
  nextTick(function () {
    // The whole view is rendered, so I can safely access or query
    // the DOM. ¯\_(ツ)_/¯
    //this.scroll()
  })
})

function viewHandler(isVisible: boolean, entry: IntersectionObserverEntry) {
  //entry.isIntersecting equaling true describes a transition into a state of intersection -- important distinction
  if (isVisible && entry.isIntersecting) {
    emit('load-more')
  }
}

//if you can click it, it's visible
function viewHandlerClick() {
  emit('load-more')
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function onRowClick(item: any, index: number) {
  emit('row-click', item, index)
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function onRowDoubleClick(item: any, index: number) {
  emit('row-double-click', item, index)
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function onRowLongPress(item: any, index: number) {
  emit('row-long-press', item, index)
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function onRowRightClick(item: any, index: number) {
  emit('row-right-click', item, index)
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function setLongPress(item: any, index: number) {
  state.longPressItem = item
  state.longPressIndex = index
}

function onRowTouchEnd() {
  if (
    state.longPressItem &&
    state.longPressIndex != null &&
    state.longPressIndex >= 0
  ) {
    onRowLongPress(state.longPressItem, state.longPressIndex)
    state.longPressItem = null
    state.longPressIndex = null

    return
  }
}
</script>
<style scoped lang="scss">
#tableBody .row {
  position: relative;
  border-bottom: var(--border);
  padding: var(--size-0) var(--size-000);

  @media (min-width: 48em) {
    gap: 3rem;
    padding: var(--size-1) var(--size-00);
    flex-direction: row;
    margin-left: unset;
    margin-right: unset;
    margin-inline: unset;
  }

  &:hover {
    // background-color: v-bind(hoverBackground);
    cursor: pointer;
  }

  &.ad-container {
    border-bottom: 0;
    padding-bottom: 0;
    background-color: transparent;
    width: 100%;

    &::after {
      background-color: inherit;
    }
  }
}

.row-hover:hover {
  // background-color: var(--surface-2);
  cursor: pointer;
}
.load-more {
  position: relative;
  padding: var(--size-1) var(--size-3);
  display: grid;
  place-content: center;
  font-variation-settings: 'wght' 500;

  @media (min-width: 48em) {
    padding-top: var(--size-3);
    padding-bottom: var(--size-3);
  }
}

.load-more-threshold {
  position: relative;
  width: 0;
  height: 0;
}
.conversation-header {
  display: none;
}

.table {
  display: flex;
  flex-wrap: wrap;

  &-ram-grid {
    display: grid;
  }
}

.remove-padding {
  padding: 0 !important;
}
</style>
