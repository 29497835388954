<template>
  <div>
    <button
      class="btn circle"
      type="button"
      style="margin-top: 5px;"
      @click="showInfoModal()"
    >
    <font-awesome-icon
        icon="fa-light fa-circle-info"
        :class="isMobileWidth ? 'icon-size-1' : 'icon-size-2'"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { useCommonStore } from '@/stores/CommonStore';
import { storeToRefs } from 'pinia';

const commonStore = useCommonStore()
const { setshowInfoModal } = commonStore
const { isMobileWidth } = storeToRefs(commonStore)

function showInfoModal() {
  setshowInfoModal(true)
}
</script>
  