<template>
  <div class="device-error" v-if="deviceError != null">
    <div v-if="showTitle">{{ deviceError.title }}</div>
    <div>{{ deviceError.text }}</div>
    <div v-if="deviceError.type == MediaPermissionsErrorType.SystemPermissionDenied">
      <I18nT
        keypath="calling.deviceErrors.systemPermissionDenied.deviceSettingsPrompt"
      >
        <a @click="openSettingsLink">{{ t('calling.deviceErrors.systemPermissionDenied.deviceSettingsCta') }}</a>
      </I18nT>
    </div>
    <button
      v-if="!infoOnly"
      type="button"
      class="btn tertiary underline mr-auto"
      @click="retryDevices"
    >
      {{ deviceError.cta }}
    </button>
  </div>
</template>
<script setup lang="ts">
import Bowser from 'bowser'
import {
  type MediaPermissionsError,
  MediaPermissionsErrorType
} from 'mic-check'
import { computed, toRef } from 'vue'
import { useI18n, I18nT } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

const props = defineProps<{
  mediaError: MediaPermissionsError | null
  showTitle: boolean
  infoOnly: boolean
}>()
const emit = defineEmits<{
  (e: 'retry', val: boolean): void
}>()

// jsut for Typing
type OSName = keyof typeof settingsDataByOS

const browser = Bowser.getParser(window.navigator.userAgent)
const browserName = browser.getBrowserName()
const osName = browser.getOSName() as OSName
// const errorType = ref('')
// const errName = props.mediaError?.name
// const errMessage = props.mediaError?.message
interface IDeviceError {
  type: MediaPermissionsErrorType
  title: string
  text: string
  cta: string
}

const mediaErorrRef = toRef(props, 'mediaError')

const errorType = computed(() => { 
if (browserName === 'Chrome') {
  if (mediaErorrRef.value?.name === 'NotAllowedError') {
    if (mediaErorrRef.value?.message === 'Permission denied by system') {
      return MediaPermissionsErrorType.SystemPermissionDenied
    } else if (mediaErorrRef.value?.message === 'Permission denied') {
      return MediaPermissionsErrorType.UserPermissionDenied
    }
  } else if (mediaErorrRef.value?.name === 'NotReadableError') {
    return MediaPermissionsErrorType.CouldNotStartVideoSource
  }
} else if (browserName === 'Safari') {
  if (mediaErorrRef.value?.name === 'NotAllowedError') {
    return MediaPermissionsErrorType.UserPermissionDenied
  }
} else if (browserName === 'Microsoft Edge') {
  if (mediaErorrRef.value?.name === 'NotAllowedError') {
    return MediaPermissionsErrorType.UserPermissionDenied
  } else if (mediaErorrRef.value?.name === 'NotReadableError') {
    return MediaPermissionsErrorType.CouldNotStartVideoSource
  }
} else if (browserName === 'Firefox') {
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
  if (mediaErorrRef.value?.name === 'NotFoundError') {
    return MediaPermissionsErrorType.SystemPermissionDenied
  } else if (mediaErorrRef.value?.name === 'NotReadableError') {
    return MediaPermissionsErrorType.SystemPermissionDenied
  } else if (mediaErorrRef.value?.name === 'NotAllowedError') {
    return MediaPermissionsErrorType.UserPermissionDenied
  } else if (mediaErorrRef.value?.name === 'AbortError') {
    return MediaPermissionsErrorType.CouldNotStartVideoSource
  }
}})

const deviceError = computed<IDeviceError | null>(() => {
  if (mediaErorrRef.value?.type == MediaPermissionsErrorType.UserPermissionDenied ||
    errorType.value == MediaPermissionsErrorType.UserPermissionDenied) {
    return {
      type: MediaPermissionsErrorType.UserPermissionDenied,
      title: t('calling.deviceErrors.userPermissionDenied.title'),
      text: t('calling.deviceErrors.userPermissionDenied.description'),
      cta: t('calling.deviceErrors.userPermissionDenied.cta')
    }
  } else if (mediaErorrRef.value?.type == MediaPermissionsErrorType.CouldNotStartVideoSource ||
    errorType.value == MediaPermissionsErrorType.CouldNotStartVideoSource) {
    return {
      type: MediaPermissionsErrorType.CouldNotStartVideoSource,
      title: t('calling.deviceErrors.couldNotStart.title'),
      text: t('calling.deviceErrors.couldNotStart.description'),
      cta: t('calling.deviceErrors.couldNotStart.cta')
    }
  } else if (mediaErorrRef.value?.type == MediaPermissionsErrorType.SystemPermissionDenied ||
    errorType.value == MediaPermissionsErrorType.SystemPermissionDenied) {
    return {
      type: MediaPermissionsErrorType.SystemPermissionDenied,
      title: t('calling.deviceErrors.systemPermissionDenied.title'),
      text: t('calling.deviceErrors.systemPermissionDenied.description'),
      cta: t('calling.deviceErrors.systemPermissionDenied.cta')
    }
  } else if (mediaErorrRef.value?.type == MediaPermissionsErrorType.Generic ||
    errorType.value == MediaPermissionsErrorType.Generic) {
    return {
      type: MediaPermissionsErrorType.Generic,
      title: t('calling.deviceErrors.generic.title'),
      text: t('calling.deviceErrors.generic.description'),
      cta: t('calling.deviceErrors.generic.cta')
    }
  } else {
    return null
  }
})

const settingsDataByOS = {
  macOS: {
    name: 'System Preferences',
    link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera'
  },
  Windows: {
    name: 'Privacy Settings',
    link: 'ms-settings:privacy-webcam'
  }
}

function openSettingsLink() {
  if (settingsDataByOS[osName]) {
    window.open(settingsDataByOS[osName].link, '_blank')
  } else {
    alert('Your operating system is not supported for this action.')
  }
}

function retryDevices() {
  emit('retry', true)
}
</script>
<style scoped lang="css">
.device-error {
  display: flex;
  flex-direction: column;
  gap: var(--size-0);
}
</style>
