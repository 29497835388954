<template>
  <div class="py-2" :class="{ 'mobile-background': isMobileWidth }">
    <StaticWelcomeComponent
      v-if="props.isModal"
      :sub-header-text="subHeaderText"
      :image-url="imageUrl"
      :image-alt="imageAlt"
      :bullet-list="bulletList"
    />

    <LayoutBox v-else class="fit-content-height">
      <StaticWelcomeComponent
        :sub-header-text="subHeaderText"
        :image-url="imageUrl"
        :image-alt="imageAlt"
        :bullet-list="bulletList"
      >
        <template #ctaButtons>
          <button
            class="btn primary mx-auto mt-4 place-center"
            type="button"
            @click="viewWelcomePage"
          >
            {{ buttonText }}
          </button>
        </template>
      </StaticWelcomeComponent>
    </LayoutBox>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import LayoutBox from '@/layouts/components/LayoutBoxComponent.vue'
import { computed } from 'vue'
import StaticWelcomeComponent from '@/components/StaticWelcomeComponent.vue'
import type { IWelcomePageBullet } from '@/models/interfaces'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { isMobileWidth, getCDNImageUrl } = storeToRefs(commonStore)
const { setWelcomePageViewed, setShowInfoButtonLayoutOption } = commonStore

const props = withDefaults(
  defineProps<{
    isModal?: boolean
  }>(),
  {
    isModal: false
  }
)

const subHeaderText = t('messages.welcome.subHeader')
const imageUrl = computed(() => `${getCDNImageUrl.value}messages-welcome.png`)
//empty string - static images are purely decorative
const imageAlt = ''
const bulletList: IWelcomePageBullet[] = [
  {
    mainText: t('messages.welcome.bullet[0].mainText'),
    secondaryText: t('messages.welcome.bullet[0].secondaryText'),
    bulletIcon: 'fa-light fa-eye'
  },
  {
    mainText: t('messages.welcome.bullet[1].mainText'),
    secondaryText: t('messages.welcome.bullet[1].secondaryText'),
    bulletIcon: 'fa-light fa-photo-film'
  },
  {
    mainText: t('messages.welcome.bullet[2].mainText'),
    secondaryText: t('messages.welcome.bullet[2].secondaryText'),
    bulletIcon: 'fa-light fa-thumbtack'
  }
]
const buttonText = t('introPages.continue')

function viewWelcomePage() {
  setWelcomePageViewed('messages', true)
  setShowInfoButtonLayoutOption(true)
}
</script>
<style scoped>
.mobile-background {
  height: 100%;
  background-color: var(--surface-1);
}
</style>
