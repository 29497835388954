<template>
  <MessagesSortFilterMenu
    v-model:do-submit="state.submit"
    v-model:clear-filters="state.reset"
    is-modal
    form-class="modal-form"
    fieldset-class="modal-fieldset"
    legend-class="modal-legend"
    input-wrapper-class="modal-input-wrapper"
    input-class="modal-input"
    label-class="modal-label"
    hr-class="modal-hr"
    header-class="modal-header"
    @submit="emit('close')"
  />

  <menu>
    <button class="btn secondary" @click="reset">
      {{ t('messages.sortAndFilter.modal.resetButton') }}
    </button>
    <button class="btn primary" @click="submit">
      {{ t('messages.sortAndFilter.modal.applyButton') }}
    </button>
  </menu>
</template>
<script setup lang="ts">
import { reactive } from 'vue'
import MessagesSortFilterMenu from './MessagesSortFilterMenu.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const state = reactive<{
  submit: boolean
  reset: boolean
}>({
  submit: false,
  reset: false
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

function submit() {
  state.submit = true
}

function reset() {
  state.reset = true
}
</script>
<style scoped>
.modal-form {
  padding-block: 1rem 1rem;
}

:deep(.modal-fieldset) {
  & > .modal-legend + .modal-input-wrapper {
    margin-block-start: var(--size-00);
  }
}

:deep(.modal-legend) {
  color: var(--text-0);
  font-size: var(--font-size-3);

  padding-inline-start: var(--size-1);
  padding-block-start: var(--size-1);
}

:deep(.modal-input) {
  margin-inline-start: 1.5rem !important;
  margin-block: auto !important;
}

:deep(.modal-label) {
  padding-inline-start: 3rem !important;
  line-height: 1.25 !important;
}

:deep(.modal-hr) {
  display: none;
}

menu {
  display: flex;
  gap: var(--size-00);
  align-items: center;
  justify-content: center;

  padding: var(--size-1) var(--size-4) var(--size-3);

  & > button {
    flex: 1;

    &.btn.primary {
      font-variation-settings: 'wght' 500;
    }
  }
}

:deep(.modal-header) {
  display: block;
  font-size: var(--font-size-0);

  margin-inline-start: var(--size-1);
  margin-block: var(--size-00);
}
</style>
