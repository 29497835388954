export const TosType = {
  DWOLLA: 3
}

export const TransferStatus = {
  PENDING: 1,
  PROCESSED: 2,
  FAILED: 3,
  CANCELLED: 4
}

export const TransferType = {
  REQUEST: 1,
  PAYMENT: 2,
  NOTSPECIFIED: 3
}

export const RequestStatus = {
  REQUESTED: 1,
  ACCEPTED: 2,
  DECLINED: 3,
  CANCELED: 4
}

export const CustomerVerificationStatus = {
  //initial state.
  PendingExternalVerification: 1,
  //happens after user adds a verified funding source.
  PendingInternalVerification: 2,
  //happens after admin review process.
  Verified: 3,
  //happens after admin review process.
  Failed: 4
}

export const ScheduledPaymentStatus = {
  ENABLED: 1,
  DISABLED: 2,
  CANCELED: 3,
  SYSTEM_CANCELED_UNVERIFIED_CUSTOMER: 4,
  SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE: 5,
  SYSTEM_CANCELED_COPARENT_UNVERIFIED: 6,
  SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED: 7,
  SYSTEM_CANCELED_FAILED_PAYMENT: 9
}

export const ActivityType = {
  REQUEST: 1,
  SCHEDULEDPAYMENT: 2,
  SINGLEPAYMENT: 3,
  REQUEST_SCHEDULEDPAYMENT: 4, // this is the request which is attached to a scheduled payment
  WALLETPAYMENT: 5
}

export const CustomerStatus = {
  UNVERIFIED: 1,
  VERIFIED: 2,
  RETRY: 3,
  DOCUMENT: 4,
  SUSPENDED: 5,
  DEACTIVATED: 6
}

export const DocumentStatus = {
  PENDING: 1,
  REVIEWED: 2
}

export const FundingSourceStatus = {
  VERIFIED: 1,
  UNVERIFIED: 2
}

export const FundingSourceType = {
  CHECKING: 1,
  SAVINGS: 2,
  WALLET: 3
}

export const FundingSourceMicroDepositStatus = {
  PENDING: 1,
  PROCESSED: 2,
  FAILED: 3,
  MAXATTEMPT: 4
}

export const FundingSourceDependencyAction = {
  NONE: 0,
  CANCEL: 1,
  UPDATE: 2
}

export const FundingSourceMode = {
  SELECTSOURCE: 1,
  MANAGE: 2,
  SELECTDESTINATION: 3
}

export const FilterOption = {
  REQUESTS: 1,
  PENDING_PAYMENTS: 2,
  SENT_PAYMENTS: 3,
  RECEIVED_PAYMENTS: 4,
  FAILED_PAYMENTS: 5,
  DATE_RANGE: 6,
  SCHEDULED_PAYMENTS: 7,
  UPCOMING_SCHEDULED_PAYMENTS: 8
}

export const ErrorType = {
  SERVER_ERROR: 0,
  VALIDATION_ERROR: 1,
  MFA_DISABLED: 2,
  INVALID_FEE: 3,
  NOT_APPROVED: 4,
  NOT_ELIGIBLE_TO_MAKE_PAYMENTS: 5,
  LINK_UPDATE_REQUIRED: 6,
  NOT_ELIGIBLE_FOR_NEXT_DAY_ACH: 7,
  INSUFFICIENT_BALANCE: 8
}

export const CreateFundingSourceErrorType = {
  SERVER_ERROR: 0,
  VALIDATION_ERROR: 1,
  DUPLICATE_ACCOUNT_ALL: 2,
  DUPLICATE_ACCOUNT_SOME: 3
}

export const MicroDepositErrorType = {
  SERVER_ERROR: 0,
  VALIDATION_ERROR: 1,
  MFA_DISABLED: 2,
  FAILED_ATTEMPT: 3,
  MAX_ATTEMPT_REACHED: 4
}

export const Customer = {
  id: 0,
  status: 1,
  externalId: ''
}

export const AddTransfer = {
  amount: 0,
  note: '',
  scheduledDate: 'Today',
  type: TransferType.NOTSPECIFIED
}

export const MoneyTransferFeeType = {
  FreeUser: 1,
  FreeUserFirstTransfer: 2,
  StandardUser: 3,
  StandardUserFirstTransfer: 4,
  PremiumUser: 5,
  FeeExceededFiftyPercentOfTransferAmount: 6
}

export const MoneyTransferClearingType = {
  Standard: 1,
  NextDay: 2
}