/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// @ts-nocheck

import type { ITrackingEvent, ITrackingIdentity } from '@/models/interfaces'
import moment from 'moment'
import type { App } from 'vue'
import httpClient from '@/httpClient'
export default {
  install: function (app: App) {
    // Currently using Bloomreach for our user tracking
    // This plug in installs the bloomreach SDK and exposes various functions for identifying and tracking user activity

    !(function (e, n, t, i, o, r) {
      function a(e) {
        if ('number' != typeof e) return e
        const n = new Date()
        return new Date(n.getTime() + 1e3 * e)
      }
      const c = 4e3,
        s = 'xnpe_async_hide'
      function p(e) {
        return e.reduce(
          function (e, n) {
            return (
              (e[n] = function () {
                // eslint-disable-next-line prefer-rest-params
                e._.push([n.toString(), arguments])
              }),
              e
            )
          },
          { _: [] }
        )
      }
      function m(e, n, t) {
        const i = t.createElement(n)
        i.src = e
        const o = t.getElementsByTagName(n)[0]
        return o.parentNode.insertBefore(i, o), i
      }
      function u(e) {
        return '[object Date]' === Object.prototype.toString.call(e)
      }
      ;(r.target = r.target || 'https://api.exponea.com'),
        (r.file_path = r.file_path || r.target + '/js/exponea.min.js'),
        (o[n] = p([
          'anonymize',
          'initialize',
          'identify',
          'getSegments',
          'update',
          'track',
          'trackLink',
          'trackEnhancedEcommerce',
          'getHtml',
          'showHtml',
          'showBanner',
          'showWebLayer',
          'ping',
          'getAbTest',
          'loadDependency',
          'getRecommendation',
          'reloadWebLayers',
          '_preInitialize'
        ])),
        (o[n].notifications = p([
          'isAvailable',
          'isSubscribed',
          'subscribe',
          'unsubscribe'
        ])),
        (o[n]['snippetVersion'] = 'v2.5.0'),
        (function (e, n, t) {
          ;(e[n]['_' + t] = {}),
            (e[n]['_' + t].nowFn = Date.now),
            (e[n]['_' + t].snippetStartTime = e[n]['_' + t].nowFn())
        })(o, n, 'performance'),
        (function (e, n, t, i, o, r) {
          e[o] = {
            sdk: e[i],
            sdkObjectName: i,
            skipExperiments: !!t.new_experiments,
            sign: t.token + '/' + (r.exec(n.cookie) || ['', 'new'])[1],
            path: t.target
          }
        })(o, e, r, n, i, RegExp('__exponea_etc__' + '=([\\w-]+)')),
        (function (e, n, t) {
          m(e.file_path, n, t)
        })(r, t, e),
        (function (e, n, t, i, o, r, p) {
          if (e.new_experiments) {
            !0 === e.new_experiments && (e.new_experiments = {})
            let l,
              f = e.new_experiments.hide_class || s,
              _ = e.new_experiments.timeout || c,
              d = encodeURIComponent(r.location.href.split('#')[0])
            e.cookies &&
              e.cookies.expires &&
              ('number' == typeof e.cookies.expires || u(e.cookies.expires)
                ? (l = a(e.cookies.expires))
                : e.cookies.expires.tracking &&
                  ('number' == typeof e.cookies.expires.tracking ||
                    u(e.cookies.expires.tracking)) &&
                  (l = a(e.cookies.expires.tracking))),
              l && l < new Date() && (l = void 0)
            const x =
              e.target +
              '/webxp/' +
              n +
              '/' +
              r[t].sign +
              '/modifications.min.js?http-referer=' +
              d +
              '&timeout=' +
              _ +
              'ms' +
              (l ? '&cookie-expires=' + Math.floor(l.getTime() / 1e3) : '')
            'sync' === e.new_experiments.mode &&
            r.localStorage.getItem('__exponea__sync_modifications__')
              ? (function (e, n, t, i, o) {
                  ;(t[o][n] = '<' + n + ' src="' + e + '"></' + n + '>'),
                    i.writeln(t[o][n]),
                    i.writeln(
                      '<' +
                        n +
                        '>!' +
                        o +
                        '.init && document.writeln(' +
                        o +
                        '.' +
                        n +
                        '.replace("/' +
                        n +
                        '/", "/' +
                        n +
                        '-async/").replace("><", " async><"))</' +
                        n +
                        '>'
                    )
                })(x, n, r, p, t)
              : (function (e, n, t, i, o, r, a, c) {
                  r.documentElement.classList.add(e)
                  const s = m(t, i, r)
                  function p() {
                    o[c].init ||
                      m(t.replace('/' + i + '/', '/' + i + '-async/'), i, r)
                  }
                  function u() {
                    r.documentElement.classList.remove(e)
                  }
                  ;(s.onload = p),
                    (s.onerror = p),
                    o.setTimeout(u, n),
                    (o[a]._revealPage = u)
                })(f, _, x, n, r, p, o, t)
          }
        })(r, t, i, 0, n, o, e),
        (function (e, n, t) {
          let i
          ;(null === (i = t.experimental) || void 0 === i
            ? void 0
            : i.non_personalized_weblayers) && e[n]._preInitialize(t),
            (e[n].start = function (i) {
              i &&
                Object.keys(i).forEach(function (e) {
                  return (t[e] = i[e])
                }),
                e[n].initialize(t)
            })
        })(o, n, r)
    })(document, 'exponea', 'script', 'webxpClient', window, {
      target: 'https://cdp.talkingparents.com',
      token: import.meta.env.VITE_APP_BLOOMREACH_TOKEN,
      track: {
        google_analytics: false
      },
      push: {
        safari: {
          websitePushID: import.meta.env.VITE_APP_WEBSITE_PUSH_ID
        }
      },
      ping: {
        activity: true
      },
      spa_reloading: {
        on_url_change: true,
        automatic_tracking: true
      },
      debug: false
    })
    exponea.start({
      experiments: true,
      tagManager: true,
      webLayers: true
    })

    app.provide('$trackingStart', function () {
      window.exponea.start()
    })

    app.provide('$trackingIdentify', function (payload: ITrackingIdentity) {
      window.exponea.identify(
        {
          talkingparents_id: payload.talkingparents_id,
          email_id: payload.email
        },
        {},
        function () {
          //success callback
        },
        function () {
          // error callback
        },
        false
      )
    })

    app.provide(
      '$trackingIdentifyWithProperties',
      function (payload: ITrackingIdentity) {
        window.exponea.identify(
          {
            talkingparents_id: payload.talkingparents_id,
            email_id: payload.email
          },
          {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            matched: payload.matched
          },
          function () {
            //success callback
          },
          function () {
            // error callback
          },
          false
        )
      }
    )

    app.provide(
      '$trackingIdentifyWithId',
      function (payload: ITrackingIdentity) {
        window.exponea.identify(
          {
            talkingparents_id: payload.talkingparents_id,
            email_id: payload.email
          },
          {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            matched: payload.matched
          },
          function () {
            //success callback
            window.exponea.track(
              'active_session',
              {},
              function () {
                //success callback
              },
              function () {
                //error callback
              }
            )
          },
          function () {
            // error callback
          },
          false
        )
      }
    )

    app.provide(
      '$trackingIdentifyAndConsent',
      function (payload: ITrackingIdentity) {
        window.exponea.identify(
          {
            talkingparents_id: payload.talkingparents_id,
            email_id: payload.email
          },
          {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            matched: payload.matched
          },
          function () {
            //success callback
            if (payload.consent) {
              window.exponea.track('consent', {
                action: 'accept',
                category: 'Email',
                valid_until: 'unlimited'
              })
              window.exponea.track('consent', {
                action: 'accept',
                category: 'releases_and_updates',
                valid_until: 'unlimited'
              })
              window.exponea.track('consent', {
                action: 'accept',
                category: 'webinar',
                valid_until: 'unlimited'
              })
            }
          },
          function () {
            // error callback
          },
          false
        )
      }
    )

    app.provide(
      '$trackingEmailIdentifyAndConsent',
      function (payload: ITrackingIdentity) {
        window.exponea.identify(
          {
            email_id: payload.email
          },
          {
            email: payload.email
          },
          function () {
            //success callback
            if (payload.consent) {
              window.exponea.track('consent', {
                action: 'accept',
                category: 'Email',
                valid_until: 'unlimited'
              })
              window.exponea.track('consent', {
                action: 'accept',
                category: 'releases_and_updates',
                valid_until: 'unlimited'
              })
              window.exponea.track('consent', {
                action: 'accept',
                category: 'webinar',
                valid_until: 'unlimited'
              })
            }
          },
          function () {
            // error callback
          },
          false
        )
      }
    )

    app.provide(
      '$trackingTrack',
      function (eventType: string, payload: ITrackingEvent) {
        if (
          eventType == br.eventTypes.signup &&
          hasBeenTracked(eventType, payload)
        )
          return
        const _payload = {
          timestamp: moment().unix(),
          source: 'web'
        }
        window.exponea.track(eventType, { ..._payload, ...payload })
      }
    )

    app.provide('$trackingUpdate', function (payload: ITrackingUpdate) {
      window.exponea.update(payload)
    })

    app.provide('$trackingAnonymize', function () {
      window.exponea.anonymize()
    })

    app.provide(
      '$trackingGetConsentUrl',
      async function (payload: ITrackingIdentity): string {
        const cookieName = '__exponea_etc__'
        const cookieValue = document.cookie
          .split('; ')
          .find((row) => row.startsWith(cookieName))
          ?.split('=')[1]

        const url = payload.email
          ? '/web/api/Ctd/GetConsentsUrl'
          : '/web/api/Ctd/GetConsentsUrlFromCookie'

        const params = {
          Email: payload.email,
          Cookie: cookieValue
        }

        const response = await httpClient.get(url, { params: params })

        return response.data.value
      }
    )

    app.provide('$trackingLaunchWebLayer', function (webLayerId: string) {
      if (webLayerId && webLayerId.lenght == 0) return
      window.exponea.showWebLayer(webLayerId)
    })

    // private functions
    const _handledEventList: { eventType: string; payload: ITrackingEvent }[] =
      []
    function hasBeenTracked(eventType: string, payload: ITrackingEvent) {
      const _eventType = eventType
      const result = _handledEventList.find((e) => {
        return !!(
          e.eventType == _eventType &&
          e.payload.step == payload.step &&
          e.payload.field == payload.field &&
          e.payload.action == payload.action
        )
      })
      if (result == undefined) {
        _handledEventList.push({ eventType, payload })
      }
      return result != undefined
    }
  }
}

// exposing constants for bloomreach

export const br = Object.freeze({
  eventTypes: {
    signup: 'signup',
    subscription: 'subscription',
    appAction: 'app_action',
    appNavigation: 'app_navigation'
  },
  signupEventStep: {
    verifyEmail: 1,
    accountInformation: 2,
    mfaAndQuickMatch: 3,
    matchInformation: 4,
    editInformation: 5
  },
  signupEventAction: {
    fieldCompleted: 'field_completed',
    deletedAccount: 'deleted_account',
    //STEP 1
    sendVerifyEmail: 'send_verify_email',
    verifyEemail: 'verify_email',

    //STEP 2
    agreeAndContinue: 'agree_and_continue',

    //STEP 3
    skipMfa: 'skip_mfa',
    sendMfa: 'send_mfa',
    verifyMfa: 'verify_mfa',
    skipMatchid: 'skip_matchid',
    useMatchid: 'use_matchid',
    verifyMatchid: 'verify_matchid',
    skipEmail: 'skip_email',
    useEmail: 'use_email',
    quickmatchEmail: 'quickmatch_email',
    stepThreeSignout: 'step_three_signout',

    //STEP 4
    setMatchInfo: 'set_match_info',
    emailInvite: 'email_invite',
    textInvite: 'text_invite',
    copyMatchid: 'copy_matchid',
    stepFourSignout: 'step_four_signout',

    //STEP 5
    updatedInformation: 'updated_information'
  },
  signupEventField: {
    email: 'email',

    //STEP 2
    firstName: 'first_name',
    lastName: 'last_name',
    password: 'password',
    verifyPassword: 'verify_password',
    timezone: 'timezone',
    agreeBox: 'agree_box',

    //STEP 3
    mfaPhonenumber: 'mfa_phonenumber',
    matchid: 'matchid',
    coparentEmail: 'coparent_email',

    //STEP 4
    coparentFirstName: 'coparent_first_name',
    coparentLastName: 'coparent_last_name',
    childFirstName: 'child_first_name',
    childDob: 'child_dob',

    //Step 5
    updateFirstName: 'update_first_name',
    updateLastName: 'update_last_name',
    updateTimezone: 'update_timezone',
    updateCoparentFirstName: 'update_coparent_first_name',
    updateCoparentLastName: 'update_coparent_last_name',
    updateCoparentEmail: 'update_coparent_email',
    updateChildDob: 'update_child_dob',
    updatePhone: 'update_phone',
    updateChildFirstName: 'update_Child_first_name'
  },
  appActionEventNames: {
    navigation: 'navigation',
    CTAUpgrade: 'cta_upgrade',
    videoCompleted: 'video_completed',
    phoneEnabled: 'phone_enabled',
    videoEnabled: 'video_enabled',
    createdConversation: 'created_conversation',
    attachmentUploaded: 'attachment_uploaded',
    pdfPurchaseCompleted: 'pdf_purchase_completed',
    printedPurchaseCompleted: 'printed_purchase_completed',
    createdEntry: 'created_entry',
    bankAdded: 'bank_added',
    successfulTransfer: 'successful_transfer',
    createdCard: 'created_card',
    createdTab: 'created_tab',
    itemUploaded: 'item_uploaded',
    emailVerified: 'email_verified',
    phoneVerified: 'phone_verified',
    biometricsDiabled: 'biometrics_disabled',
    biometricsEnabled: 'biometrics_enabled',
    feedbackButton: 'feedback_button',
    CTAEmailSignup: 'cta_email_signup'
  },
  appActionFeature: {
    calling: 'calling',
    messages: 'messaging',
    records: 'records',
    calendar: 'calendar',
    payments: 'payments',
    infoLibrary: 'info_library',
    vault: 'vault',
    journal: 'journal',
    settings: 'settings'
  },
  appNavigationProperties: {
    from: 'from_location',
    to: 'to_location'
  }
})
