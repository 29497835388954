<template>
  <form @submit.prevent="submit" :class="props.formClass">
    <label v-if="props.label" for="input" :class="props.labelClass">
      {{ props.label }}
    </label>

    <div :class="props.inputWrapperClass">
      <button v-if="props.iconString" :class="props.buttonClass" type="button">
        <FontAwesomeIcon :icon="props.iconString" :class="props.iconClass" />
      </button>

      <input
        id="input"
        v-model="state.term"
        type="search"
        :placeholder="props.placeholder ?? t('search.placeholder')"
        :class="props.inputClass"
      />

      <button
        v-if="state.term.length > 0"
        type="button"
        class="btn circle clear"
        @click="state.term = ''"
      >
        <FontAwesomeIcon icon="fa-light fa-xmark" class="icon-size-1" />

        <span class="sr-only">clear</span>
      </button>
    </div>
  </form>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores/CommonStore'
import { onBeforeMount, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { watchDebounced } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { searchTerm } = storeToRefs(commonStore)
const { setSearchTerm } = commonStore

const props = defineProps<{
  label?: string
  placeholder?: string
  formClass?: string
  labelClass?: string
  inputWrapperClass?: string
  inputClass?: string
  buttonClass?: string
  iconString?: string
  iconClass?: string
}>()

interface ISearchState {
  term: string
}

const state = reactive<ISearchState>({
  term: ''
})

function submit() {
  setSearchTerm(
    state.term
      .trim()
      .split(/[\s,\t,\n]+/)
      .join(' ')
  )
}

watchDebounced(() => state.term, submit, { debounce: 600 })

onBeforeMount(() => (state.term = searchTerm.value))
</script>
<style scoped>
.clear {
  position: absolute;
  inset-block: 0;
  inset-inline: auto var(--size-00);

  padding: var(--size-00);
  color: var(--text-2);
  width: fit-content;
}

:deep(input::-webkit-search-cancel-button) {
  display: none !important;
}
</style>
