<template>
  <modal
    :close-on-outside-click="false"
    :modal-active="state.showAdblockModal"
    :show-close-button="false"
    :blur-background="true"
    title="Disable ad blocker to continue"
    message="Please disable your ad blocker to continue using TalkingParents. Upgrade to a Standard or Premium Plan for access to our mobile app
            and an ad-free experience."
  >
    <template #header>
      <div class="stack align-center mx-auto">
        <svg
          class="logo-svg"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 750 517.58"
          style="height: 2.5rem"
        >
          <path
            class="st2"
            fill="#014847"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M458.48 406.25c-4.31-17.25-33.42-2.16-57.14 9.7-40.97 20.48-85.17 31.27-131.53 32.34-115.36 0-218.86-69-263.06-175.73-2.16-3.23-6.47-5.39-6.47-2.16l1.08 3.23C44.49 398.7 163.08 482.79 295.68 482.79c42.05 0 84.09-8.62 122.9-25.87 8.63-3.24 47.44-21.56 39.9-50.67"
          />
          <path
            class="st2"
            fill="#014847"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M738.79 406.33c-9.7 14.02-30.19-9.7-47.44-26.95-29.11-31.27-64.69-54.98-103.5-71.15-100.26-37.74-213.46-11.86-287.85 65.76-2.16 2.16-6.47 2.16-5.39-1.08l2.16-2.16c79.78-94.87 210.23-129.37 325.59-85.17 36.66 14.02 71.15 35.58 99.19 62.53 6.45 6.47 33.41 35.58 17.24 58.22"
          />
          <path
            class="st3"
            fill="#29b99a"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M329.11 296.37c3.23 0 6.47 1.08 9.7 1.08 7.55-5.39 16.17-10.78 24.8-15.09-6.47-1.08-12.94-2.16-19.41-3.23-69-6.47-134.76 31.27-162.79 93.8l-1.08 2.16c0 1.08 2.16 1.08 3.23 0 28.03-53.93 85.17-84.11 145.55-78.72"
          />
          <path
            class="st2"
            fill="#014847"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M501.6 36.54c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
          />
          <path
            class="st2"
            fill="#014847"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M112.41 113.09c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
          />
          <path
            class="st3"
            fill="#29b99a"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M306.47 134.65c30.19-1.08 54.98 25.87 56.06 59.3s-23.72 61.45-53.91 61.45c-30.19 1.08-54.98-25.87-56.06-59.3-1.08-33.42 23.72-60.37 53.91-61.45"
          />
        </svg>
        <span class="font-weight-2 mt-1"> Disable ad blocker to continue </span>
      </div>
    </template>
    <template #footer>
      <div class="flex-column gap-1">
        <button @click="upgrade()" type="submit" class="btn primary">
          Upgrade my Plan
        </button>
        <button @click="closeAndRefresh()" class="btn brand font-weight-1">
          I've disabled my ad blocker
        </button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal from './ModalV2Component.vue'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { reactive, watch, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCommonStore } from '@/stores/CommonStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { br } from '@/plugins/trackerPlugin'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const track: any = inject('$trackingTrack')

const state = reactive({
  showAdblockModal: false,
  GoogleAdSenseUrl: import.meta.env.VITE_APP_GOOGLE_ADSEMSE_URL
})

const route = useRoute()
const router = useRouter()

const accountSettingsStore = useAccountSettingsStore()
const { setPaywallPreviousPage } = accountSettingsStore
const { subscriptionT0 } = storeToRefs(accountSettingsStore)

const commonStore = useCommonStore()
const { setAdblockModalShown } = commonStore

watch(
  () => route.fullPath,
  async () => {
    doCheckForAdBlocker()
  }
)

async function doCheckForAdBlocker() {
  if (!subscriptionT0.value) return

  const path = route.path
  if (!path.includes('accountSettings')) {
    axios
      .head(state.GoogleAdSenseUrl)
      .then((response) => {
        if (response == undefined) {
          throw 'adblocker present'
        }
        // There is no AdBlocker
        //successful
        state.showAdblockModal = false
        setAdblockModalShown(false)

        return true
      })
      .catch(() => {
        // Failed, Because of an AdBlocker
        state.showAdblockModal = true
        setAdblockModalShown(true)
        return false
      })
  } else {
    state.showAdblockModal = false
    setAdblockModalShown(false)
  }
}

function closeAndRefresh() {
  state.showAdblockModal = false
  setAdblockModalShown(false)
}

function upgrade() {
  track(br.eventTypes.appAction, {
    feature: route.meta.trackingName,
    name: br.appActionEventNames.CTAUpgrade 
  })

  setPaywallPreviousPage({ path: route.path })
  router.push({ name: 'accountTypeStep1' })
  state.showAdblockModal = false
  setAdblockModalShown(false)
}
</script>
<style lang="scss" scoped>
:deep(.dialog-footer) {
  @media (min-width: 48em) {
    padding-top: 0;
  }
}

:deep(.dialog-body) {
  padding-top: var(--size-3);
  backdrop-filter: blur(10px);
}
</style>
