<template>
  <form @submit.prevent="submit" :class="formClass">
    <fieldset :class="fieldsetClass">
      <legend :class="legendClass">
        {{ t('messages.sortAndFilter.filter.legend') }}
      </legend>

      <h2 :class="headerClass">
        {{ t('messages.sortAndFilter.filter.typeHeader') }}
      </h2>

      <div
        v-if="searchTerm.length"
        class="input-wrapper"
        :class="inputWrapperClass"
      >
        <input
          :class="inputClass"
          v-model="state.filter"
          id="all"
          type="radio"
          name="filter"
          :value="MessagesFilterByType.All"
        />

        <label :class="labelClass" for="all">
          {{ allFilterString }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.filter"
          id="filter0"
          type="radio"
          name="filter"
          :value="MessagesFilterByType.Subject"
        />

        <label :class="labelClass" for="filter0">
          {{ subjectFilterString }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.filter"
          id="filter2"
          type="radio"
          name="filter"
          :value="MessagesFilterByType.Message"
        />

        <label :class="labelClass" for="filter2">
          {{ t('messages.sortAndFilter.filter.values.message') }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.filter"
          id="filter1"
          type="radio"
          name="filter"
          :value="MessagesFilterByType.Attachment"
        />

        <label :class="labelClass" for="filter1">
          {{ t('messages.sortAndFilter.filter.values.attachment') }}
        </label>
      </div>
    </fieldset>

    <hr :class="hrClass" />

    <fieldset :class="fieldsetClass">
      <h2 :class="headerClass">
        {{ t('messages.sortAndFilter.filter.statusHeader') }}
      </h2>

      <div
        v-if="!searchTerm.length"
        class="input-wrapper"
        :class="inputWrapperClass"
      >
        <input
          :class="inputClass"
          v-model="state.unread"
          id="unread"
          type="checkbox"
          @click="unreadClicked"
        />

        <label :class="labelClass" for="unread">
          {{ t('messages.sortAndFilter.showUnreadMessagesOnly') }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.sent"
          id="sent1"
          type="radio"
          name="sent"
          :value="OwnerFilter.Mine"
          @click="mineSentClicked"
        />
        <label :class="labelClass" for="sent1">
          {{ t('messages.sortAndFilter.sent.values.you') }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.sent"
          id="sent0"
          type="radio"
          name="sent"
          :value="OwnerFilter.Coparent"
          @click="coparentSentClicked"
        />

        <label :class="labelClass" for="sent0">
          {{ t('messages.sortAndFilter.sent.values.coparent') }}
        </label>
      </div>
    </fieldset>

    <hr v-if="searchTerm.length" :class="hrClass" />

    <fieldset v-if="searchTerm.length" :class="fieldsetClass">
      <legend :class="legendClass">
        {{ t('messages.sortAndFilter.sort.legend') }}
      </legend>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.sort"
          id="sort2"
          type="radio"
          name="sort"
          :value="SortOption.Relevance"
        />
        <label :class="labelClass" for="sort2">
          {{ t('messages.sortAndFilter.sort.values.relevance') }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.sort"
          id="sort0"
          type="radio"
          name="sort"
          :value="SortOption.DateDescending"
        />
        <label :class="labelClass" for="sort0">
          {{ t('messages.sortAndFilter.sort.values.newestToOldest') }}
        </label>
      </div>

      <div class="input-wrapper" :class="inputWrapperClass">
        <input
          :class="inputClass"
          v-model="state.sort"
          id="sort1"
          type="radio"
          name="sort"
          :value="SortOption.DateAscending"
        />
        <label :class="labelClass" for="sort1">
          {{ t('messages.sortAndFilter.sort.values.oldestToNewest') }}
        </label>
      </div>
    </fieldset>

    <!-- <hr :class="hrClass" /> -->

    <!-- <fieldset :class="fieldsetClass">
      <legend :class="legendClass">{{ t('messages.sortAndFilter.date.legend') }}</legend>

      <DropdownMenu
        v-model="state.date"
        name="date"
        :options="dateMap"
        root-class="dropdown-root"
        select-class="dropdown-select"
        select-text-class="dropdown-select-text"
      />

      <div
        class="date-wrapper"
        v-if="state.date == MessagesSortFilterDateOptions.Custom"
      >
        <TPDatePicker
          v-model="state.rangeFrom"
          name="dateFrom"
          :label="t('messages.sortAndFilter.date.values.rangeFrom')"
          :auto-apply="true"
          :text-input="false"
          :utc="true"
          :placeholder="fullUserInfo.shortDateFormat?.toLocaleLowerCase()"
        />

        <TPDatePicker
          v-model="state.rangeTo"
          name="dateTo"
          :label="t('messages.sortAndFilter.date.values.rangeTo')"
          :auto-apply="true"
          :text-input="false"
          :utc="true"
          :placeholder="fullUserInfo.shortDateFormat?.toLocaleLowerCase()"
        />
      </div>
    </fieldset> -->

    <!-- <footer>
      <button type="submit" class="btn primary">
        <strong>{{ t('messages.sortAndFilter.applyFilters') }}</strong>
      </button>

      <span>
        {{ t('messages.sortAndFilter.results', { count: resultCount }) }}
      </span>
    </footer> -->
  </form>
</template>
<script setup lang="ts">
import { computed, reactive, toRef, watch } from 'vue'
import { type Moment } from 'moment'
import { useI18n } from 'vue-i18n'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import {
  MessagesFilterByType,
  MessagesSortFilterDateOptions,
  OwnerFilter,
  SortOption
} from '@/models/enums'
import { useMessagesStore } from '@/stores/MessagesStore'
import { useVModel, watchDebounced } from '@vueuse/core'
const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { searchTerm } = storeToRefs(commonStore)
const { setSearchMode } = commonStore

const messagesStore = useMessagesStore()
const { sortFilterSelectedOptions } = storeToRefs(messagesStore)
const { setSortFilterSelectedOptions } = messagesStore

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'update:doSubmit'): void
  (e: 'update:clearFilters'): void
}>()

const props = withDefaults(
  defineProps<{
    doSubmit?: boolean
    clearFilters?: boolean
    isModal: boolean
    formClass?: string
    fieldsetClass?: string
    legendClass?: string
    inputWrapperClass?: string
    inputClass?: string
    labelClass?: string
    hrClass?: string
    headerClass?: string
  }>(),
  {
    isModal: false
  }
)
const doSubmit = useVModel(props, 'doSubmit', emit)
const clearFilters = useVModel(props, 'clearFilters', emit)
const isModal = toRef(props, 'isModal')
const formClass = toRef(props, 'formClass')
const fieldsetClass = toRef(props, 'fieldsetClass')
const legendClass = toRef(props, 'legendClass')
const inputWrapperClass = toRef(props, 'inputWrapperClass')
const inputClass = toRef(props, 'inputClass')
const labelClass = toRef(props, 'labelClass')
const hrClass = toRef(props, 'hrClass')

const state = reactive<{
  sort: SortOption
  filter: MessagesFilterByType
  sent: OwnerFilter
  date: MessagesSortFilterDateOptions
  rangeFrom?: Moment
  rangeTo?: Moment
  unread: boolean
}>({
  sort: sortFilterSelectedOptions.value.sortBy ?? SortOption.DateDescending,
  filter:
    sortFilterSelectedOptions.value.filterByType ??
    (searchTerm.value.length
      ? MessagesFilterByType.All
      : MessagesFilterByType.Subject),
  sent: sortFilterSelectedOptions.value.ownerFilter ?? OwnerFilter.None,
  date: MessagesSortFilterDateOptions.AllTime,
  unread: sortFilterSelectedOptions.value.unreadOnly ?? false
})

// const rangeFrom = computed(() =>
//   state.date == MessagesSortFilterDateOptions.Custom
//     ? state.rangeFrom
//     : undefined
// )

// const rangeTo = computed(() =>
//   state.date == MessagesSortFilterDateOptions.Custom ? state.rangeTo : undefined
// )

// const sortKeys = [
//   t('messages.sortAndFilter.sort.values.newestToOldest'),
//   t('messages.sortAndFilter.sort.values.oldestToNewest'),
//   t('messages.sortAndFilter.sort.values.relevance')
// ]

// const sortValues = [
//   MessagesSortFilterSortOptions.NewestToOldest,
//   MessagesSortFilterSortOptions.OldestToNewest,
//   MessagesSortFilterSortOptions.Relevance
// ]

// const sortMap = computed(
//   () => new Map(sortKeys.map((k, i) => [k, sortValues[i]]))
// )

// const sentKeys = [
//   t('messages.sortAndFilter.sent.values.both'),
//   t('messages.sortAndFilter.sent.values.coparent'),
//   t('messages.sortAndFilter.sent.values.you')
// ]

// const sentValues = [
//   MessagesSortFilterSentOptions.Both,
//   MessagesSortFilterSentOptions.Coparent,
//   MessagesSortFilterSentOptions.You
// ]

// const sentMap = computed(
//   () => new Map(sentKeys.map((k, i) => [k, sentValues[i]]))
// )

// const dateKeys = [
//   t('messages.sortAndFilter.date.values.allTime'),
//   t('messages.sortAndFilter.date.values.today'),
//   t('messages.sortAndFilter.date.values.thisWeek'),
//   t('messages.sortAndFilter.date.values.thisYear', {
//     year: moment().format('YYYY')
//   }),
//   t('messages.sortAndFilter.date.values.lastYear', {
//     year: `${+moment().format('YYYY') - 1}`
//   }),
//   t('messages.sortAndFilter.date.values.custom')
// ]

// const dateValues = [
//   MessagesSortFilterDateOptions.AllTime,
//   MessagesSortFilterDateOptions.Today,
//   MessagesSortFilterDateOptions.ThisWeek,
//   MessagesSortFilterDateOptions.ThisYear,
//   MessagesSortFilterDateOptions.LastYear,
//   MessagesSortFilterDateOptions.Custom
// ]

// const dateMap = computed(
//   () => new Map(dateKeys.map((k, i) => [k, dateValues[i]]))
// )

const dateFilter = computed(() =>
  !state.rangeFrom || !state.rangeTo
    ? undefined
    : {
        startDate: state.rangeFrom.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: state.rangeTo.format('YYYY-MM-DDTHH:mm:ss')
      }
)

function submit() {
  setSearchMode(true)

  setSortFilterSelectedOptions({
    ...sortFilterSelectedOptions.value,
    ownerFilter: state.sent,
    filterByType: state.filter,
    unreadOnly: state.unread,
    dateFilter: dateFilter.value,
    sortBy: state.sort
  })

  emit('submit')
}

function reset() {
  state.sort = searchTerm.value.length
    ? SortOption.Relevance
    : SortOption.DateDescending

  state.filter = searchTerm.value.length
    ? MessagesFilterByType.All
    : MessagesFilterByType.Subject

  state.sent = OwnerFilter.None

  state.date = MessagesSortFilterDateOptions.AllTime

  state.unread = false
}

watchDebounced(
  [
    () => state.sort,
    () => state.filter,
    () => state.sent,
    () => state.date,
    () => state.unread
  ],
  () => {
    if (isModal.value) {
      return
    }

    submit()
  },
  { debounce: 600 }
)

function unreadClicked() {
  if (!state.unread && state.sent != OwnerFilter.None) {
    state.sent = OwnerFilter.None
  }
}

function mineSentClicked() {
  if (state.sent == OwnerFilter.Mine) {
    state.sent = OwnerFilter.None
  }

  if (state.unread) {
    state.unread = false
  }
}

function coparentSentClicked() {
  if (state.sent == OwnerFilter.Coparent) {
    state.sent = OwnerFilter.None
  }

  if (state.unread) {
    state.unread = false
  }
}

watch(doSubmit, (val) => {
  if (!val) {
    return
  }

  doSubmit.value = false
  submit()
})

watch(clearFilters, (val) => {
  if (!val) {
    return
  }

  clearFilters.value = false
  reset()
})

const subjectFilterString = computed(() =>
  searchTerm.value.length
    ? t('messages.sortAndFilter.filter.values.subject')
    : t('messages.sortAndFilter.filter.values.subjectDefault')
)

const allFilterString = computed(() =>
  searchTerm.value.length
    ? t('messages.sortAndFilter.filter.values.allDefault')
    : t('messages.sortAndFilter.filter.values.all')
)
</script>
<style scoped>
form {
  display: flex;
  flex-direction: column;
}

.form-content {
  display: flex;

  & > div {
    flex: 1;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  transition: background 0.1s ease-in-out;

  label {
    font-size: var(--font-size-0);
    cursor: pointer;
    color: var(--text-0);
    padding-block: var(--size-00);
    flex: 1 0 fit-content;
    padding-inline-start: var(--size-2);
    line-height: 1;
  }

  @media (width >= 48em) {
    &:hover {
      background: var(--surface-2);
    }
  }
}

.date-wrapper {
  display: flex;
  gap: var(--size-0);
  align-items: center;

  & > div {
    margin-block-start: 0 !important;
  }

  :deep(label) {
    font-size: var(--font-size-1);
    color: var(--text-1);
  }
}

fieldset {
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

legend {
  padding: 0;
  padding-inline-start: var(--size-2);
  font-size: var(--font-size-0);
  color: var(--text-2);
}

input[type='checkbox'] {
  --border-hover: transparent;
  border: 0 solid transparent;
  border-radius: var(--radius-sm);
  width: 0.75rem;
  height: 0.75rem;
  min-width: 0;
  margin-inline-start: 0.375rem;
  margin-block: var(--size-00);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  @media (width >= 48em) {
    &:hover:not(:checked)::after {
      opacity: 0.5;
    }
  }

  &::before {
    transition: all 0s;
    background: transparent;
    width: 100%;
    height: 100%;
    --scale: 1;
  }

  &::after {
    transition: all 0s;
    --scale: 1;
    border: 1.5px solid var(--text-0);
    border-top: 0;
    border-left: 0;
    margin-top: -0.2em;
    width: 30%;
    height: 65%;
    opacity: 0;
    transform: rotate(45deg);
    transition: opacity 0.1s ease-in-out;
  }

  &:checked::after {
    opacity: 1;
  }
}

input[type='radio'] {
  --border-hover: transparent;
  border: 0 solid transparent;
  border-radius: var(--radius-sm);
  width: 0.75rem;
  height: 0.75rem;
  min-width: 0;
  margin-inline-start: 0.375rem;
  margin-block: var(--size-00);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  @media (width >= 48em) {
    &:hover:not(:checked)::after {
      opacity: 0.5;
    }
  }

  &::before {
    transition: all 0s;
    background: transparent;
    width: 100%;
    height: 100%;
    --scale: 1;
  }

  &::after {
    transition: all 0s;
    --scale: 1;
    border: 1.5px solid var(--text-0);
    border-top: 0;
    border-left: 0;
    margin-top: -0.2em;
    width: 30%;
    height: 65%;
    opacity: 0;
    transform: rotate(45deg);
    background: transparent;
    border-radius: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &:checked::after {
    opacity: 1;
  }
}

hr {
  margin-block: 0;
  background: var(--surface-4);
  width: 90%;
  margin-block: var(--size-000);
}

strong {
  --base-weight: 500;
}

footer {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  margin-block-start: var(--size-7);
}

:deep(.dropdown-root) {
  width: 100%;
}

:deep(.dropdown-select) {
  border-radius: var(--radius-lg);
}

:deep(.dropdown-select-text) {
  --base-weight: 400;
  font-size: var(--font-size-1);
  color: var(--text-0);
}

h2 {
  display: none;
}
</style>
